import React, { useEffect } from "react";
import { Modal, Input, Select, Button, Form } from "antd";
import "./styles/CombineTable.scss";
import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import StorageService from "../../../../../../common/service/StorageService";
import { showDelete } from "../../../../../../shared/components/showDelete";
import { combineTableStore } from "./CombineTableStore";
import SelectMultipleAnt from "../../../../../../shared/components/form/SelectMultipleAnt";
import { toJS } from "mobx";
import { productInPostStore } from "../../../ProductInPostStore";
import Confirm from "./components/confirm/Confirm";
const { TextArea } = Input;
const CombineTable = () => {
  const { diningTableId, posId } = useParams<{
    diningTableId: string;
    posId: string;
  }>();
  const [form] = Form.useForm();

  const localStorageKey = `cartPos${posId}-${diningTableId}`;
  const resultCartsLocal = StorageService.getObjectStore(localStorageKey);
  
  useEffect(() => {
    combineTableStore.setDiningTableId(diningTableId!);
  }, [diningTableId]);

  const handleCancel = () => {
    combineTableStore.closeModal();
  };

  const handleCombineTable = async () => {
    try {
      // Kiểm tra toàn bộ form
      await form.validateFields();

      // showDelete.showDeleteConfirm(
      //   `Bạn chắc chắn muốn gộp bàn?`,
      //   "Hành động này không thể hoàn tác.",
      //   async () => {
      //     await combineTableStore.combineTables();
      //   }
      // );
      combineTableStore.isOpenConfirm = true
    } catch (errorInfo) {
      console.log("Validate Failed:", errorInfo);
    }
  };

  const name = StorageService.getLocalStore("name") ?? ""; // If name is null, replace with empty string

  useEffect(() => {
    combineTableStore.fetchAllDiningTablesByFilter({
      current_table_id: productInPostStore.dataInfoTable?.id,
      location_id: productInPostStore.dataInfoTable?.location_id,
      pos_id: productInPostStore.dataInfoTable?.dining_table_session?.pos_id || combineTableStore.posId,
      status_id: 2, // trạng thái này ra các bàn có trạng thái hoạt động và chưa được gộp
    });
  }, []);

  const dataDinningTables =
    combineTableStore.listTableFilter.length > 0
      ? combineTableStore.listTableFilter.map((item) => ({
          id: item.id,
          name: item.name,
        }))
      : [];

  const currentNameTables = () => {
    const results = productInPostStore.dataInfoTable.tables_merge
      ? productInPostStore.dataInfoTable.tables_merge.map(
          (item: any) => `${item.name}`
        )
      : productInPostStore.dataInfoTable.name;
      return productInPostStore.dataInfoTable.tables_merge?.length > 1 ? results.join(";  ") : results;
  };
  
  useEffect(() => {
    if (productInPostStore.dataInfoTable) {
      combineTableStore.formCombineTable.dining_table_session =
        productInPostStore.dataInfoTable?.dining_table_session?.dining_tables_session || resultCartsLocal?.dining_table_session ;
      combineTableStore.formCombineTable.dining_table_id =
        productInPostStore.dataInfoTable?.id;
    }
  }, []);

  return (
    <>
      <Modal
        title={
          <div className="header d-flex align-items-center justify-content-between">
            Gộp bàn
          </div>
        }
        open={combineTableStore.isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel} className="cancel-button">
            Huỷ bỏ
          </Button>,
          <Button
            key="transfer"
            type="primary"
            onClick={handleCombineTable}
            className="transfer-button"
          >
            Gộp bàn
          </Button>,
        ]}
        closeIcon={
          <img
            src="/assets/icon/icon_closeWhite.svg"
            width={25}
            height={25}
            alt="Close"
            onClick={handleCancel}
          />
        }
        className="transfer-table-modal"
        wrapClassName="CombineTable"
      >
        <Form form={form}>
          <div className="line"></div>
          <div className="transfer-table-content">
            <div className="transfer-table-content__input">
              <div className="transfer-title">Bàn hiện tại</div>

            <TextArea value={currentNameTables()}    className="custom-input" disabled  autoSize />  
              <div className="transfer-title">Bàn cần gộp chung</div>

              <Form.Item
                className="item-form"
                //  label="Bàn cần gộp chung"
                name="dining_table_merge_ids"
                rules={[
                  {
                    required: true,
                    message: "Bàn cần gộp không được để trống",
                  },
                ]}
              >
                <div>
                  <SelectMultipleAnt
                    list={dataDinningTables}
                    onChange={(value: any) => {
                      form.setFieldsValue({ dining_table_merge_ids: value });
                      combineTableStore.formCombineTable.dining_table_merge_ids =
                        value;
                    }}
                    placeholder={`-- Chọn --`}
                    getLabel={(item: any) => item.name}
                  />
                </div>
              </Form.Item>
            </div>
          </div>
          <div className="line2"></div>
        </Form>
      </Modal>
      <Confirm/>
    </>
  );
};

export default observer(CombineTable);
