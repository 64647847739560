import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Button, Form, Modal} from 'antd';
import { Input } from 'antd';
import {roomStore} from "../../../../room/RoomStore";
import {useNavigate, useParams} from "react-router-dom";
import StorageService from "../../../../../common/service/StorageService";
import {values} from "mobx";
import {handleFile} from "../../../../../common/helpers/UploadFileHelper";
import UploadFile from "../../../../../shared/components/form/UploadFile";
import {separationStore} from "../SeparationStore";
import {numberByDateNow} from "../../../../../common/utils/Utils";
const ModalImageQr = () => {
    const [form] = Form.useForm();
    const { diningTablePaySession } = useParams<{ diningTablePaySession: string }>();
    const navigate = useNavigate();
    useEffect(() => {
        form.setFieldsValue({
            note: separationStore.notePos,
        });
    }, [form,separationStore.notePos]);

    useEffect(() => {
        const savedImage = StorageService.getLocalStore(`uploadedImageBank/dining_table_session${diningTablePaySession}`);
        if (savedImage) {
            separationStore.imageBank = savedImage;
        }
    }, [separationStore.isModalImageQrOpen]);
    return (
        <>
            <Modal className="modalQr" title="Chuyển khoản" closable={false} open={separationStore.isModalImageQrOpen} footer={null}>
                <Form
                    form={form}
                    name="room-form"
                    onFinish={(values) => {
                        separationStore.fetchSaveOrderPosFnb((route) => navigate(route));
                    }}
                >
                    {separationStore.imageQr !== '' ? (
                        <>
                            <div className="form-modal-image">

                                <>
                                    <img src={separationStore.imageQr} alt="Qr"/>
                                </>

                            </div>
                            <UploadFile
                                file={separationStore.imageBank}
                                onIconRemove={separationStore.handleRemoveImageBank}
                                onFileChange={(e) => handleFile(e, separationStore.handleSetFileImageBank)}
                                onChooseImg={(url: string)=>{
                                    separationStore.imageBank = url
                                }}
                                code={numberByDateNow()}
                                onResetState={()=>{ separationStore.imageBank = ""}}
                            />
                            <div className="footer-note">
                                <Button className="btnCloseNote" onClick={() => separationStore.handleCancelImageQr()}>
                                    Hủy bỏ
                                </Button>
                                <Button type="primary" htmlType="submit" className="btnAddNotePay">
                                    Xác nhận
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <p>Phương thức thanh toán chuyển khoản chưa được kích hoạt</p>
                            <div className="footer-note">
                                <Button className="btnCloseNote" onClick={() => separationStore.handleCancelImageQr()}>
                                    Hủy bỏ
                                </Button>
                            </div>
                        </>
                    )}
                </Form>
            </Modal>
        </>
    )
}
export default observer(ModalImageQr)