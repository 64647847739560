import "../styles/editorder.scss";
import { observer } from "mobx-react";
import { orderStore } from "../OrderStore";
import { Col, Row, Select } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "../../../shared/components/loading/Loading";
import { useEffect, useState } from "react";
import { utils } from "../../../common/utils/Utils";

const EditOrder = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    orderStore.staticOrder.id = `${id}`;
    const listPaymentMethod = [
        { id: "0", name: "Hoàn trả", label: "Hoàn trả", value: "Hoàn trả" },
        { id: "1", name: "Hủy bỏ", label: "Hủy bỏ", value: "Hủy bỏ" },
        {
            id: "2",
            name: "Đang xử lý",
            label: "Đang xử lý",
            value: "Đang xử lý",
        },
        {
            id: "3",
            name: "Hoàn thành",
            label: "Hoàn thành",
            value: "Hoàn thành",
        },
        { id: "4", name: "Thất bại", label: "Thất bại", value: "Thất bại" },
    ];
    const listOrderMethod = [
        {
            id: "1",
            name: "Hoàn thành",
            label: "Hoàn thành",
            value: "Hoàn thành",
        },
        { id: "2", name: "Hủy bỏ", label: "Hủy bỏ", value: "Hủy bỏ" },
        {
            id: "3",
            name: "Đang xử lý",
            label: "Đang xử lý",
            value: "Đang xử lý",
        },
        { id: "4", name: "Hoàn trả", label: "Hoàn trả", value: "Hoàn trả" },
    ];
    const totalOrder: any = [];
    const priceOder = orderStore.dataListOderById?.orderitems?.map(
        (item: any, index: number) => {
            if (item.discount === 0 || item.discount === null) {
                return totalOrder.unshift(item.amount * item.qty);
            } else {
                return totalOrder.unshift(
                    item.amount - (item.amount * item.qty * item.discount) / 100
                );
            }
        }
    );

    const handleSaveChange = () => {
        orderStore.fetchUpdateOrder().then((res) => {
            orderStore.fetchDetailOrder()
        });
    };

    useEffect(() => {
        orderStore
            .fetchDetailOrder()
            .then((response) => {
                switch (orderStore.dataListOderById.status_id) {
                    case "COMPLETED":
                        orderStore.staticOrder.order_status = "1";
                        break;
                    case "CANCEL":
                        orderStore.staticOrder.order_status = "2";
                        break;
                    case "PENDING":
                        orderStore.staticOrder.order_status = "3";
                        break;
                    case "REFUND":
                        orderStore.staticOrder.order_status = "4";
                        break;
                    default:
                        break;
                }
            })
            .catch((err) => console.log(err));
    }, [id]);

    return (
        <>
            <div className="container">
                <div className={`mainContentDetailOrder`}>
                    <Row gutter={[30, 0]}>
                        {orderStore.isLoadingDetail ? (
                            <Col span={16}>
                                <Loading />
                            </Col>
                        ) : (
                            <>
                                <Col span={16}>
                                    <div className="labelLeft d-flex flex-column">
                                        <div className="labelLeft-body">
                                            <div className="table table-striped">
                                                <thead>
                                                    <tr
                                                        style={{
                                                            backgroundColor:
                                                                "rgba(0, 0, 0, 0.04)",
                                                        }}
                                                    >
                                                        <th
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                                width: "10%",
                                                            }}
                                                        >
                                                            Ảnh
                                                        </th>
                                                        <th
                                                            style={{
                                                                textAlign:
                                                                    "left",
                                                                width: "50%",
                                                            }}
                                                        >
                                                            Thông tin sản phẩm
                                                        </th>
                                                        <th
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                                width: "20%",
                                                            }}
                                                        >
                                                            Số tiền
                                                        </th>
                                                        <th
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                                width: "20%",
                                                            }}
                                                        >
                                                            Tổng cộng
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orderStore.dataListOderById?.orderitems?.map(
                                                        (
                                                            item: any,
                                                            index: number
                                                        ) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td
                                                                        className="d-flex align-items-center justify-content-center"
                                                                        style={{
                                                                            textAlign:
                                                                                "center",
                                                                            width: "10%",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                JSON.parse(
                                                                                    item.info
                                                                                )
                                                                                    ?.options
                                                                                    ?.variant
                                                                                    ?.thumbnail ||
                                                                                "/assets/images/no_image.webp"
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign:
                                                                                "left",
                                                                            width: "50%",
                                                                        }}
                                                                    >
                                                                        <div className="title">
                                                                            <Link to="">
                                                                                {item.product
                                                                                    ? item
                                                                                          .product
                                                                                          ?.title
                                                                                    : JSON.parse(
                                                                                          item.product_info_pos
                                                                                      )
                                                                                          ?.name}
                                                                            </Link>
                                                                            {JSON.parse(
                                                                                item.product_info_pos
                                                                            ) ===
                                                                            null ? (
                                                                                item
                                                                                    .product
                                                                                    ?.sku_origin ===
                                                                                "" ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <p className="mb-0">
                                                                                        <strong>
                                                                                            Mã
                                                                                            sản
                                                                                            phẩm:{" "}
                                                                                            {
                                                                                                item
                                                                                                    .product
                                                                                                    ?.sku_origin
                                                                                            }
                                                                                        </strong>
                                                                                    </p>
                                                                                )
                                                                            ) : JSON.parse(
                                                                                  item.product_info_pos
                                                                              )
                                                                                  ?.sku ===
                                                                                  "" ||
                                                                              JSON.parse(
                                                                                  item.product_info_pos
                                                                              )
                                                                                  ?.sku ===
                                                                                  null ? (
                                                                                item
                                                                                    .product
                                                                                    ?.sku_origin ===
                                                                                    "" ||
                                                                                item
                                                                                    .product
                                                                                    ?.sku_origin ===
                                                                                    null ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <p className="mb-0">
                                                                                        <strong>
                                                                                            Mã
                                                                                            sản
                                                                                            phẩm:{" "}
                                                                                            {
                                                                                                item
                                                                                                    .product
                                                                                                    ?.sku_origin
                                                                                            }
                                                                                        </strong>
                                                                                    </p>
                                                                                )
                                                                            ) : (
                                                                                <p className="mb-0">
                                                                                    <strong>
                                                                                        Mã
                                                                                        sản
                                                                                        phẩm:{" "}
                                                                                        {
                                                                                            JSON.parse(
                                                                                                item.product_info_pos
                                                                                            )
                                                                                                ?.sku
                                                                                        }
                                                                                    </strong>
                                                                                </p>
                                                                            )}                                                                            {JSON.parse(
                                                                                item.info
                                                                            )
                                                                                ?.options
                                                                                ?.variant
                                                                                ?.attribute_compare ===
                                                                                null ||
                                                                            JSON.parse(
                                                                                item.info
                                                                            )
                                                                                ?.options
                                                                                ?.variant
                                                                                ?.attribute_compare ===
                                                                                "" ? (
                                                                                ""
                                                                            ) : (
                                                                                <p className="mb-0">
                                                                                    <strong>
                                                                                        Biến
                                                                                        thể:
                                                                                    </strong>
                                                                                    {
                                                                                        JSON.parse(
                                                                                            item.info
                                                                                        )
                                                                                            ?.options
                                                                                            ?.attribute_compare
                                                                                    }
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                    <td
                                                                        className="d-flex align-items-center justify-content-center flex-column"
                                                                        style={{
                                                                            textAlign:
                                                                                "center",
                                                                            width: "20%",
                                                                        }}
                                                                    >
                                                                        {orderStore
                                                                            .dataListOderById
                                                                            .source_type ===
                                                                        "website"
                                                                            ? utils.formatCurrency(
                                                                                  JSON.parse(
                                                                                      item.info
                                                                                  )
                                                                                      ?.price
                                                                              )
                                                                            : utils.formatCurrency(
                                                                                  item.amount
                                                                              )}
                                                                        {" x "}
                                                                        {
                                                                            item.qty
                                                                        }
                                                                        {item.discount ===
                                                                            0 ||
                                                                        item.discount ===
                                                                            null ? (
                                                                            ""
                                                                        ) : (
                                                                            <span>
                                                                                Chiết
                                                                                khấu{" "}
                                                                                {
                                                                                    item.discount
                                                                                }{" "}
                                                                                %
                                                                                với
                                                                                một
                                                                                sản
                                                                                phẩm
                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                    <td
                                                                        className="d-flex align-items-center justify-content-center"
                                                                        style={{
                                                                            textAlign:
                                                                                "center",
                                                                            width: "20%",
                                                                        }}
                                                                    >
                                                                        {orderStore
                                                                            .dataListOderById
                                                                            .source_type ===
                                                                        "website"
                                                                            ? item.discount ===
                                                                                  0 ||
                                                                              item.discount ===
                                                                                  null
                                                                                ? utils.formatCurrency(
                                                                                      JSON.parse(
                                                                                          item.info
                                                                                      )
                                                                                          ?.price *
                                                                                          item.qty
                                                                                  )
                                                                                : utils.formatCurrency(
                                                                                      (item
                                                                                          .product
                                                                                          ?.price_origin *
                                                                                          item.qty *
                                                                                          item.discount) /
                                                                                          100
                                                                                  )
                                                                            : item.discount ===
                                                                                  0 ||
                                                                              item.discount ===
                                                                                  null
                                                                            ? utils.formatCurrency(
                                                                                  item.amount *
                                                                                      item.qty
                                                                              )
                                                                            : utils.formatCurrency(
                                                                                  item.amount *
                                                                                      item.qty -
                                                                                      (item.amount *
                                                                                          item.qty *
                                                                                          item.discount) /
                                                                                          100
                                                                              )}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </div>
                                            <ul className="list d-flex flex-column">
                                                <li className="row list-item list-coupon d-flex align-items-end justify-content-end">
                                                    <div className="col-9 text-left">
                                                        Giảm giá
                                                    </div>
                                                    <div className="col-3 text-right">
                                                        -{" "}
                                                        {utils.formatCurrency(
                                                            orderStore
                                                                .dataListOderById
                                                                ?.discount
                                                        )}
                                                    </div>
                                                </li>
                                                <li className="row list-item list-delivery d-flex align-items-end justify-content-end">
                                                    <div className="col-9 text-left">
                                                        Phí ship
                                                    </div>
                                                    <div className="col-3 text-right">
                                                        {utils.formatCurrency(
                                                            orderStore
                                                                .dataListOderById
                                                                ?.is_shipped
                                                        )}
                                                    </div>
                                                </li>
                                                <li className="row list-item list-total d-flex align-items-end justify-content-end">
                                                    <div
                                                        className="col-9 text-left"
                                                        style={{
                                                            color: "#6c657d",
                                                            fontSize: "22px",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        Tổng cộng
                                                    </div>
                                                    <div
                                                        className="col-3 text-right"
                                                        style={{
                                                            fontSize: "22px",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        {utils.formatCurrency(
                                                            orderStore
                                                                .dataListOderById
                                                                ?.total
                                                        )}
                                                        {/* {orderStore
                                                            .dataListOderById
                                                            .source_type ===f
                                                        "pos"
                                                            ? orderStore
                                                                  .dataListOderById
                                                                  ?.discount !==
                                                                  0 ||
                                                              orderStore
                                                                  .dataListOderById
                                                                  .discount !==
                                                                  null
                                                                ? totalOrder.length <
                                                                  2
                                                                    ? utils.formatCurrency(
                                                                          totalOrder.map(
                                                                              (
                                                                                  item: any
                                                                              ) =>
                                                                                  item -
                                                                                  orderStore
                                                                                      .dataListOderById
                                                                                      ?.discount
                                                                          )
                                                                      )
                                                                    : utils.formatCurrency(
                                                                          totalOrder?.reduce(
                                                                              (
                                                                                  acc: any,
                                                                                  curr: any
                                                                              ) =>
                                                                                  acc +
                                                                                  curr
                                                                          ) -
                                                                              orderStore
                                                                                  .dataListOderById
                                                                                  ?.discount
                                                                      )
                                                                : totalOrder.length <
                                                                  2
                                                                ? utils.formatCurrency(
                                                                      totalOrder.map(
                                                                          (
                                                                              item: any
                                                                          ) =>
                                                                              item
                                                                      )
                                                                  )
                                                                : utils.formatCurrency(
                                                                      totalOrder.reduce(
                                                                          (
                                                                              acc: any,
                                                                              curr: any
                                                                          ) =>
                                                                              acc +
                                                                              curr
                                                                      )
                                                                  )
                                                            : utils.formatCurrency(
                                                                  orderStore
                                                                      .dataListOderById
                                                                      .total
                                                              )} */}
                                                    </div>
                                                </li>
                                                {(() => {
                                                    let meta_pos:any,totalPrice:number = 0;
                                                    if (orderStore.dataListOderById?.source_type === 'pos') {
                                                        meta_pos = JSON.parse(orderStore.dataListOderById.meta_pos);
                                                        if(meta_pos){
                                                            totalPrice = meta_pos.bank_amount + meta_pos.cash_amount
                                                        }
                                                        if(totalPrice > orderStore.dataListOderById?.total){
                                                            totalPrice = orderStore.dataListOderById?.total
                                                        }
                                                        return (
                                                            <>
                                                                <li className="row list-item list-total d-flex align-items-end justify-content-end">
                                                                    <div
                                                                        className="col-9 text-left"
                                                                        style={{
                                                                            color: "#6c657d",
                                                                            fontSize:
                                                                                "22px",
                                                                            fontWeight:
                                                                                "600",
                                                                        }}
                                                                    >
                                                                        TT Thanh Toán
                                                                    </div>
                                                                    <div
                                                                        className="col-3 text-right"
                                                                        style={{
                                                                            fontSize:
                                                                                "22px",
                                                                            fontWeight:
                                                                                "600",
                                                                        }}
                                                                    >
                                                                        {utils.formatCurrency(totalPrice)}
                                                                    </div>
                                                                </li>
                                                            </>
                                                        );
                                                    } else {
                                                        return (
                                                            <></>
                                                        );
                                                    }
                                                })()}
                                            </ul>
                                        </div>
                                        <div className="labelLeft-footer d-flex flex-column">
                                            <div className="optionsStatus d-flex">
                                                <div className="paymentStatus d-flex flex-column">
                                                    <label htmlFor="">
                                                        Chọn trạng thái thanh
                                                        toán
                                                    </label>
                                                    <Select
                                                        defaultValue={
                                                            orderStore
                                                                .dataListOderById
                                                                .payment_status ===
                                                            "REFUND"
                                                                ? "Hoàn trả"
                                                                : orderStore
                                                                      .dataListOderById
                                                                      .payment_status ===
                                                                  "CANCEL"
                                                                ? "Hủy bỏ"
                                                                : orderStore
                                                                      .dataListOderById
                                                                      .payment_status ===
                                                                  "PENDING"
                                                                ? "Đang xử lý"
                                                                : orderStore
                                                                      .dataListOderById
                                                                      .payment_status ===
                                                                  "COMPLETE"
                                                                ? "Hoàn thành"
                                                                : orderStore
                                                                      .dataListOderById
                                                                      .payment_status ===
                                                                  "FAILED"
                                                                ? "Thất bại"
                                                                : ""
                                                        }
                                                        placeholder="Chọn trạng thái thanh toán"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        onChange={(
                                                            value: string
                                                        ) => {
                                                            const selectedIndex =
                                                                listPaymentMethod.findIndex(
                                                                    (option) =>
                                                                        option.value ===
                                                                        value
                                                                );
                                                            orderStore.staticOrder.payment_status = `${selectedIndex}`;
                                                        }}
                                                        options={
                                                            listPaymentMethod
                                                        }
                                                    />
                                                </div>
                                                <div className="orderStatus">
                                                    <label htmlFor="">
                                                        Chọn trạng thái đơn hàng
                                                    </label>
                                                    <Select
                                                        placeholder="Chọn trạng thái đơn hàng"
                                                        defaultValue={
                                                            orderStore
                                                                .dataListOderById
                                                                .status_id ===
                                                            "COMPLETED"
                                                                ? "Hoàn thành"
                                                                : orderStore
                                                                      .dataListOderById
                                                                      .status_id ===
                                                                  "CANCEL"
                                                                ? "Hủy bỏ"
                                                                : orderStore
                                                                      .dataListOderById
                                                                      .status_id ===
                                                                  "PENDING"
                                                                ? "Đang xử lý"
                                                                : orderStore
                                                                      .dataListOderById
                                                                      .status_id ===
                                                                  "REFUND"
                                                                ? "Hoàn trả"
                                                                : ""
                                                        }
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        onChange={(
                                                            value: string
                                                        ) => {
                                                            const selectedIndex =
                                                                listOrderMethod.findIndex(
                                                                    (option) =>
                                                                        option.value ===
                                                                        value
                                                                );
                                                            orderStore.staticOrder.order_status = `${
                                                                selectedIndex +
                                                                1
                                                            }`;
                                                        }}
                                                        options={
                                                            listOrderMethod
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="optionsBtn">
                                                <button
                                                    className="btnBack"
                                                    onClick={() =>
                                                        navigate("/don-hang")
                                                    }
                                                >
                                                    Quay lại đơn
                                                </button>
                                                <button
                                                    className="btnPrint"
                                                    onClick={() =>
                                                        navigate(
                                                            `/in-hoa-don/${orderStore.staticOrder.id}`
                                                        )
                                                    }
                                                >
                                                    In hóa đơn
                                                </button>
                                                <button
                                                    className="btnSave"
                                                    onClick={handleSaveChange}
                                                    disabled={
                                                        orderStore.isSavingOrder
                                                    }
                                                >
                                                    {orderStore.isSavingOrder
                                                        ? "Đang cập nhật"
                                                        : "Lưu thay đổi"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="labelRight d-flex flex-column">
                                        <div className="labelStatus">
                                            <div className="labelStatus-title">
                                                <p
                                                    className="mb-0"
                                                    style={{
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Trạng thái
                                                </p>
                                            </div>

                                            <div className="labelStatus-info d-flex flex-column">
                                                <div className="orderStatus d-flex justify-content-between">
                                                    <span>
                                                        Trạng thái thanh toán
                                                    </span>
                                                    {orderStore.isOrderStatusStatic ===
                                                    "" ? (
                                                        orderStore
                                                            .dataListOderById
                                                            .payment_status ===
                                                        "PENDING" ? (
                                                            <span className="badge badge-pending">
                                                                Đang xử lý
                                                            </span>
                                                        ) : orderStore
                                                              .dataListOderById
                                                              .payment_status ===
                                                          "COMPLETE" ? (
                                                            <span className="badge badge-success">
                                                                Hoàn thành
                                                            </span>
                                                        ) : orderStore
                                                              .dataListOderById
                                                              .payment_status ===
                                                          "FAILED" ? (
                                                            <span className="badge badge-failed">
                                                                Thất bại
                                                            </span>
                                                        ) : orderStore
                                                              .dataListOderById
                                                              .payment_status ===
                                                          "CANCEL" ? (
                                                            <span className="badge badge-cancel">
                                                                Hủy bỏ
                                                            </span>
                                                        ) : orderStore
                                                              .dataListOderById
                                                              .payment_status ===
                                                          "REFUND" ? (
                                                            <span className="badge badge-refund">
                                                                Hoàn trả
                                                            </span>
                                                        ) : (
                                                            "Chưa có dữ liệu"
                                                        )
                                                    ) : orderStore.isOrderStatusStatic ===
                                                      "0" ? (
                                                        <span className="badge badge-refund">
                                                            Hoàn trả
                                                        </span>
                                                    ) : orderStore.isOrderStatusStatic ===
                                                      "1" ? (
                                                        <span className="badge badge-cancel">
                                                            Hủy bỏ
                                                        </span>
                                                    ) : orderStore.isOrderStatusStatic ===
                                                      "2" ? (
                                                        <span className="badge badge-pending">
                                                            Đang xử lý
                                                        </span>
                                                    ) : orderStore.isOrderStatusStatic ===
                                                      "3" ? (
                                                        <span className="badge badge-success">
                                                            Hoàn thành
                                                        </span>
                                                    ) : orderStore.isOrderStatusStatic ===
                                                      "4" ? (
                                                        <span className="badge badge-failed">
                                                            Thất bại
                                                        </span>
                                                    ) : (
                                                        "Chưa có dữ liệu"
                                                    )}
                                                </div>
                                                <div className="paymentStatus d-flex justify-content-between">
                                                    <span>
                                                        Phương thức thanh toán
                                                    </span>
                                                    <span className="badge badge-success">
                                                        {
                                                            orderStore
                                                                .dataListOderById
                                                                .order_method
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="labelInfoUser d-flex flex-column">
                                            <div className="labelInfoUser-title">
                                                <p
                                                    className="mb-0"
                                                    style={{
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Thông tin người đặt
                                                </p>
                                            </div>

                                            <div className="labelInfoUser-list">
                                                {orderStore.dataListOderById
                                                    ?.customer_pos ? (
                                                    <>
                                                        <p>
                                                            Tên:{" "}
                                                            <b>
                                                                {orderStore
                                                                    .dataListOderById
                                                                    ?.customer_pos
                                                                    ?.name ||
                                                                    "Chưa có tên khách hàng"}
                                                            </b>
                                                        </p>
                                                        <p>
                                                            Email:{" "}
                                                            <b>
                                                                {orderStore
                                                                    .dataListOderById
                                                                    .customer_pos
                                                                    ?.email ||
                                                                    "Chưa có email"}
                                                            </b>
                                                        </p>
                                                        <p>
                                                            Số điện thoại:{" "}
                                                            <b>
                                                                {orderStore
                                                                    .dataListOderById
                                                                    ?.customer_pos
                                                                    ?.phone ||
                                                                    "Chưa có số điện thoại"}
                                                            </b>
                                                        </p>
                                                        <p>
                                                            Địa chỉ:{" "}
                                                            <b>
                                                                {orderStore
                                                                    .dataListOderById
                                                                    ?.customer_pos
                                                                    ?.address ||
                                                                    ""}
                                                                {" - "}
                                                                {orderStore
                                                                    .dataListOderById
                                                                    ?.customer_pos
                                                                    ?.ward_name ||
                                                                    ""}
                                                                {" - "}
                                                                {orderStore
                                                                    .dataListOderById
                                                                    ?.customer_pos
                                                                    ?.district_name ||
                                                                    ""}
                                                                {" - "}
                                                                {orderStore
                                                                    .dataListOderById
                                                                    ?.customer_pos
                                                                    ?.city_name ||
                                                                    ""}
                                                            </b>
                                                        </p>
                                                        <p>
                                                            Thời gian đặt hàng:{" "}
                                                            <b>
                                                                {new Date(
                                                                    orderStore.dataListOderById.created_at
                                                                ).toLocaleDateString(
                                                                    "en-GB"
                                                                )}
                                                                {" - "}
                                                                {new Date(
                                                                    orderStore.dataListOderById.created_at
                                                                ).toLocaleTimeString(
                                                                    "en-GB"
                                                                )}
                                                            </b>
                                                        </p>
                                                        <p>
                                                            Lời nhắn:{" "}
                                                            <b>
                                                                {orderStore
                                                                    ?.dataListOderById
                                                                    ?.note ||
                                                                    "Chưa có ghi chú"}
                                                            </b>
                                                        </p>
                                                        <p>
                                                            Kiểu giao hàng:{" "}
                                                            <span
                                                                style={{
                                                                    color: "#fc544b",
                                                                }}
                                                            >
                                                                {orderStore
                                                                    .dataListOderById
                                                                    ?.customer
                                                                    ?.shippingFee ===
                                                                "HT"
                                                                    ? "Hỏa tốc"
                                                                    : "Giao hàng thường"}
                                                            </span>
                                                        </p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <p>
                                                            Tên:{" "}
                                                            <b>Khách hàng lẻ</b>
                                                        </p>
                                                        <p>
                                                            Thời gian đặt hàng:{" "}
                                                            <b>
                                                                {new Date(
                                                                    orderStore.dataListOderById.created_at
                                                                ).toLocaleDateString(
                                                                    "en-GB"
                                                                )}
                                                                {" - "}
                                                                {new Date(
                                                                    orderStore.dataListOderById.created_at
                                                                ).toLocaleTimeString(
                                                                    "en-GB"
                                                                )}
                                                            </b>
                                                        </p>
                                                        <p>
                                                            Lời nhắn:{" "}
                                                            <b>
                                                                {orderStore
                                                                    ?.dataListOderById
                                                                    ?.note ||
                                                                    "Chưa có ghi chú"}
                                                            </b>
                                                        </p>
                                                        <p>
                                                            Kiểu giao hàng:{" "}
                                                            <span
                                                                style={{
                                                                    color: "#fc544b",
                                                                }}
                                                            >
                                                                {orderStore
                                                                    .dataListOderById
                                                                    ?.customer
                                                                    ?.shippingFee ===
                                                                "HT"
                                                                    ? "Hỏa tốc"
                                                                    : "Giao hàng thường"}
                                                            </span>
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {orderStore.dataListOderById
                                            ?.source_type === "pos" ? (
                                            <div
                                                className="labelInfoStaff d-flex flex-column"
                                                style={{
                                                    padding: "20px 25px",
                                                    background: "#ffffff",
                                                    borderRadius: "4px",
                                                    borderTop:
                                                        "2px solid #0e72ed",
                                                    gap: "35px",
                                                }}
                                            >
                                                <div className="labelInfoStaff-title">
                                                    <p
                                                        className="mb-0"
                                                        style={{
                                                            fontSize: "16px",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Thông tin nhân viên
                                                    </p>
                                                </div>

                                                <div className="labelInfoStaff-list">
                                                    <p
                                                        style={{
                                                            color: "#6c657d",
                                                        }}
                                                    >
                                                        Tên:{" "}
                                                        <b>
                                                            {orderStore
                                                                ?.dataListOderById
                                                                .user_name ||
                                                                "Chưa có nhân viên"}
                                                        </b>
                                                    </p>

                                                    <p
                                                        style={{
                                                            color: "#6c657d",
                                                        }}
                                                    >
                                                        Email:{" "}
                                                        <b>
                                                            {orderStore
                                                                .dataListOderById
                                                                ?.email ||
                                                                "Chưa có email"}
                                                        </b>
                                                    </p>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </Col>
                            </>
                        )}
                    </Row>
                </div>
            </div>
        </>
    );
};

export default observer(EditOrder);
