import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import LayoutSettings from "../components/layout/LayoutSettings";
import {filesStore} from "./FilesStore";
import './styles/index.css';
import {handleMediaFileMultiple} from "../../../common/helpers/UploadFileHelper";
import {Breadcrumb, Dropdown, Button, Modal, Input, MenuProps, message} from "antd";
import Loading from "../../../shared/components/loading/Loading";
import ReactPaginate from "react-paginate";
import FileTableList from "../components/tableFiles/FilesTableList";
import {useLocation, useNavigate} from "react-router-dom";
import { showDelete } from "../../../shared/components/showDelete";
import NoContent from "../../../shared/components/NoContent";


const FilesPage = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalUrl, setModalUrl] = useState('');
    const [renameFileName, setRenameFileName] = useState('');
    const [isRenameModalVisible, setIsRenameModalVisible] = useState(false);
    const [newFolderName, setNewFolderName] = useState('');
    const [isAddFolderModalVisible, setIsAddFolderModalVisible] = useState(false);
    const [isTableView, setIsTableView] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState<string>('');


    const createMenu = (isDir: boolean, item: any): MenuProps => ({
        items: isDir ? [
            {
                key: "delete",
                label: "Xóa"
            },
            {
                key: "path",
                label: "Đường dẫn"
            }
        ] : [
            {
                key: "rename",
                label: "Đổi tên"
            },
            {
                key: "delete",
                label: "Xóa"
            },
            {
                key: "path",
                label: "Đường dẫn"
            }
        ],
        onClick: (e) => handleMenuClick(e, item)
    });


    const truncateText = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };

    const handleUpdateURL = () => {
        updateUrlParams();
    };

    const updateUrlParams = () => {
        const searchParams = new URLSearchParams();

        const params = {
            page: (filesStore.staticDataFiles.page + 1).toString(),
            path: filesStore.staticDataFiles.path
        };

        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                searchParams.set(key, value);
            }
        });

        navigate({search: searchParams.toString()}, {replace: true});
    };

    const openFolder = (fullPath: string) => {
        setCurrentPath(fullPath);
        window.history.pushState(null, '', `?path=${encodeURIComponent(fullPath)}`);
        filesStore.staticDataFiles.path = fullPath;
        handleUpdateURL();
    };

    const openFile = (link: string) => {
        window.open(link, '_blank');
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsRenameModalVisible(false);
        setIsAddFolderModalVisible(false);
        setErrorMessage('');
    };

    const handleRenameSubmit = () => {
        const oldName = filesStore.staticDataFiles.oldName;
        const newName = renameFileName;
        const directory = filesStore.staticDataFiles.directory;
        filesStore.staticDataFiles.oldName = oldName;
        filesStore.staticDataFiles.newName = newName;
        filesStore.staticDataFiles.directory = directory;
        filesStore.changeNameImage()
        setIsRenameModalVisible(false);
    };

    const handleAddFolder = () => {
        if (!newFolderName.trim()) {
            setErrorMessage("Vui lòng nhập tên thư mục");
            return;
        }
        filesStore.staticDataFiles.newFolderName = newFolderName;
        filesStore.addFolder();
        setIsAddFolderModalVisible(false);
        setNewFolderName('');
        setErrorMessage('');
    };



    const handleMenuClick = (e: any, item: any) => {
        const directory = item.isDir
            ? item.name
            : item.name.substring(0, item.name.lastIndexOf('/'));

        if (e.key === "path") {
            setModalUrl(item.url);
            setIsModalVisible(true);
        } else if (e.key === "rename") {
            setRenameFileName(item.name.split('/').pop() || '');
            filesStore.staticDataFiles.oldName = item.name.split('/').pop() || '';
            filesStore.staticDataFiles.directory = directory;
            setIsRenameModalVisible(true);
        } else if (e.key === "delete") {
            showDelete.showDeleteConfirm(
                `Bạn chắc chắn muốn xoá file ${truncateText(item.name.split('/').pop() || '', 15)}?`,
                "Hành động này không thể hoàn tác.",
                () => {
                    if (item.irDue) {
                        filesStore.staticDataFiles.directory = directory;
                        filesStore.deleteFile();
                    } else {
                        if (!filesStore.selectedFiles.includes(item.name)) {
                            filesStore.selectedFiles.push(item.name);
                        }
                        filesStore.deleteFileImage();
                    }
                }
            );
        }
    };


    const handleChangePage = (e: any) => {
        filesStore.staticDataFiles.page = e.selected;
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(
            "page",
            (filesStore.staticDataFiles.page + 1).toString()
        );
        navigate({
            search: searchParams.toString(),
        });
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const generateBreadcrumb = () => {
        const paths = currentPath.split('/').filter(Boolean);
        return paths.map((path, index) => {
            const fullPath = paths.slice(0, index + 1).join('/');
            return (
                <Breadcrumb.Item key={index} onClick={() => openFolder(fullPath)}>
                    {path}
                </Breadcrumb.Item>
            );
        });
    };

    const handleSearchSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const searchParams = new URLSearchParams(location.search);
        searchParams.set("path", currentPath);

        navigate({
            search: searchParams.toString(),
        });

        filesStore.staticDataFiles.path = currentPath;
        filesStore.fetchMediaList().then();
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const pageParam = searchParams.get("page");
        const page = pageParam
            ? parseInt(pageParam) > 1
                ? parseInt(pageParam) - 1
                : 0
            : 0;
        filesStore.staticDataFiles.path = searchParams.get("path") || '';
        filesStore.staticDataFiles.page = page || 0;
        filesStore.fetchMediaList().then();
    }, [location.search]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const pathFromQuery = params.get('path');

        if (pathFromQuery) {
            setCurrentPath(decodeURIComponent(pathFromQuery));
        } else {
            setCurrentPath(filesStore.metadata.rootPath);
        }

        const handlePathChange = () => {
            const updatedParams = new URLSearchParams(window.location.search);
            const newPath = updatedParams.get('path');
            setCurrentPath(newPath ? decodeURIComponent(newPath) : filesStore.metadata.rootPath);
        };
        window.addEventListener('popstate', handlePathChange);
        return () => {
            window.removeEventListener('popstate', handlePathChange);
        };
    }, [location.search, filesStore.metadata.rootPath]);

    return (
        <LayoutSettings keyActive={"tep-tin"}>
            <div className="AdminPage">
                <div className="AdminPage-header">
                    <h1 className="title">Danh sách tệp tin</h1>
                </div>
                <div className="AdminPage-body">
                    <div className="table-container page-files">
                        <div className="mailbox-controls">
                            <button className="reload" onClick={() => {
                                filesStore.isLoading = true;
                                filesStore.fetchMediaList().finally(() => {
                                    filesStore.isLoading = false;
                                });
                            }}>
                                <i className="fas fa-sync"></i>
                            </button>
                            <button className="remove-file" onClick={() => filesStore.confirmAndDeleteSelectedFiles()}>
                                <i className="fas fa-trash" style={{color: '#fff'}}></i>
                            </button>
                            <button className="upload">
                                <i className="fa fa-upload" style={{color: '#fff'}}></i>
                                <span>Tải lên</span>
                                <input accept="image/jpeg, image/jpg, image/png, image/svg+xml, image/webp"
                                       type="file"
                                       multiple
                                       onChange={(e) => {
                                           filesStore.isLoading = true
                                           handleMediaFileMultiple(e, filesStore.changeFile, filesStore.staticDataFiles.path)
                                       }}
                                />
                            </button>
                            <button className="create-folders" onClick={() => {
                                setIsAddFolderModalVisible(true);
                            }}>
                                <i className="fa fa-folder" style={{color: '#fff'}}></i>
                                <span>Tạo thư mục</span>
                            </button>
                            <div className="structure">
                                <button onClick={() => setIsTableView(false)}>
                                    <i className="fa fa-th"></i>
                                </button>
                                <button onClick={() => setIsTableView(true)}>
                                    <i className="fa fa-list"></i>
                                </button>
                            </div>
                        </div>
                        {/*<div className="path">*/}
                        {/*    <div className="root-path">{filesStore.metadata.rootPath}</div>*/}
                        <div className="search">
                            <form onSubmit={handleSearchSubmit}>
                                <input
                                    type="text"
                                    name="keyword"
                                    id="keyword"
                                    placeholder="Đường dẫn"
                                    value={currentPath}
                                    onChange={(e) => setCurrentPath(e.target.value)}
                                />
                                <button type="submit">
                                    <img
                                        src="/assets/icon/search.svg"
                                        alt="search"
                                    />
                                </button>
                            </form>
                        </div>
                        {/*</div>*/}
                    </div>
                    <div className="breadcrumb">
                        <Breadcrumb separator="/">
                            <Breadcrumb.Item>
                                <i className="fa fa-th-large" aria-hidden="true" style={{
                                    color: "#6777ef",
                                    marginLeft: '10px',
                                    display: "flex",
                                    alignItems: "center"
                                }}></i>
                            </Breadcrumb.Item>
                            {generateBreadcrumb()}
                        </Breadcrumb>
                    </div>
                    <div className="body-information">
                        <div className="list-item">
                            {filesStore.isLoading ? (
                                <div className="loading_data">
                                    <Loading />
                                </div>
                            ) : filesStore.listMedia.length === 0 ? (
                                <div className="no-content">
                                    <NoContent />
                                </div>
                            ) : isTableView ? (
                                <FileTableList />
                            ) : (
                                <div className="card-container">
                                    {filesStore.listMedia.map((item: any, index: number) => (
                                        <div className="file-card" key={index}>
                                            <div className="file-image"
                                                 onClick={() => item.isDir && openFolder(item.name)}>
                                                {item.isDir ? (
                                                    <i className="fa fa-folder"
                                                       style={{fontSize: '100px', color: '#ccc'}}></i>
                                                ) : item.name.split('/').pop().endsWith('.xlsx') ? (
                                                    <i className="fa fa-file" style={{ fontSize: '100px', color: '#ccc' }}></i>
                                                ) : (
                                                    <>
                                                        <div className="image-wrapper">
                                                            <img
                                                                className="zoom-image"
                                                                src={item.url || '/path/to/default-image.png'}
                                                                alt={item.url.split('/').pop()}
                                                            />
                                                        </div>
                                                        {!item.isDir && (
                                                            <input
                                                                type="checkbox"
                                                                className="file-checkbox"
                                                                checked={filesStore.selectedFiles.includes(item.name)}
                                                                onChange={(e) => filesStore.handleCheckboxChange(item.name, e.target.checked)}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <div className="file-content">
                                                <div className="file-info">
                                                    <div className="file-name"
                                                         onClick={() => item.isDir ? openFolder(item.name) : openFile(item.link)}>
                                                        {truncateText(item.name.split('/').pop() || '', 15)}
                                                    </div>
                                                    <div className="file-size">{item.size}</div>
                                                    <div className="file-date">{item.time}</div>
                                                </div>
                                                <div className="file-actions">
                                                    <Dropdown menu={createMenu(item.isDir, item)} trigger={['click']}>
                                                        <Button className="action-button">
                                                            Hành động <span style={{fontSize: 'x-small'}}>▼</span>
                                                        </Button>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        {!filesStore.isLoading && filesStore.listMedia.length > 0 && filesStore.metadata && filesStore.metadata.total_page > 1 && (
                            <ReactPaginate
                                previousLabel={
                                    <img
                                        src="/assets/icon/fi-rr-angle-small-left.svg"
                                        alt="right"
                                    />
                                }
                                nextLabel={
                                    <img
                                        src="/assets/icon/fi-rr-angle-small-right.svg"
                                        alt="right"
                                    />
                                }
                                breakLabel={"..."}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                pageCount={Math.ceil(filesStore.metadata.total_page)}
                                onPageChange={handleChangePage}
                                forcePage={filesStore.staticDataFiles.page}
                            />
                        )}
                    </div>
                </div>
            </div>
            <Modal
                title="Đường dẫn"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="close" onClick={handleCancel}>
                        Đóng
                    </Button>
                ]}
            >
                <Input value={modalUrl} readOnly/>
            </Modal>

            <Modal
                title="Đổi tên"
                open={isRenameModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="close" onClick={handleCancel}>
                        Đóng
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleRenameSubmit}>
                        Gửi
                    </Button>
                ]}
            >
                <div>
                    <label style={{display: 'block', marginBottom: '8px'}}>Path:</label>
                    <Input
                        value={renameFileName}
                        onChange={(e) => setRenameFileName(e.target.value)}
                    />
                </div>
            </Modal>
            <Modal
                title="Tạo thư mục"
                open={isAddFolderModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="close" onClick={handleCancel}>
                        Đóng
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleAddFolder}>
                        Tạo
                    </Button>
                ]}
            >
                <div>
                    <label style={{ display: 'block', marginBottom: '8px' }}>Tên thư mục:</label>
                    <Input
                        value={newFolderName}
                        onChange={(e) => {
                            setNewFolderName(e.target.value);
                            setErrorMessage('');
                        }}
                        placeholder="Nhập tên thư mục"
                    />
                    {errorMessage && (
                        <div style={{ color: 'red', marginTop: '4px' }}>{errorMessage}</div>
                    )}
                </div>
            </Modal>

        </LayoutSettings>
    );
};

export default observer(FilesPage);
