import React, { useEffect } from "react";
import { Modal, Input, Select, Button } from "antd";
import "./styles/TransferTable.css";
import { observer } from "mobx-react";
import { transferTableStore } from "./TransferTableStore";
import { useParams } from "react-router-dom";
import { productInPostStore } from "../../../ProductInPostStore";
import { toastUtils } from "../../../../../../common/utils/Toastutils";
import {confirmStore} from "./confirm/ConfirmStore";
import Confirm from "./confirm/Confirm";
import { toJS } from "mobx";
import SelectCustom from "../../../../../../shared/components/form/SelectCustom";

const TransferTable = () => {
    const { diningTableId, posId } = useParams<{ diningTableId: string; posId: string }>();

    useEffect(() => {
        if (diningTableId) {
            transferTableStore.setDiningTableId(diningTableId);
        }
        if (posId) {
            transferTableStore.setPosId(posId);
        }
        transferTableStore.fetchAllDiningTablesList(true);
    }, [diningTableId, posId]);

    const handleTransfer = () => {
        if (!transferTableStore.selectedTableId) {
            toastUtils.error("Vui lòng chọn bàn muốn chuyển!", "");
            return;
        }
        confirmStore.isOpenModal = true;
    };

    return (
        <Modal
            title={
                <div className="header d-flex align-items-center justify-content-between">
                    Chuyển bàn
                </div>
            }
            open={transferTableStore.isModalOpen}
            onCancel={() => transferTableStore.closeModal()}
            footer={[
                <Button key="cancel" onClick={() => transferTableStore.closeModal()} className="cancel-button">
                    Huỷ bỏ
                </Button>,
                <Button key="transfer" type="primary" onClick={handleTransfer} className="transfer-button">
                    Chuyển bàn
                </Button>
            ]}
            closeIcon={
                <img
                    src="/assets/icon/icon_closeWhite.svg"
                    width={25}
                    height={25}
                    alt="Close"
                    onClick={() => transferTableStore.closeModal()}
                />
            }
            className="transfer-table-modal"
            wrapClassName="TransferTable"
        >
            {confirmStore.isOpenModal && <Confirm />}
            <div className="line"></div>
            <div className="transfer-table-content">
                <div className="transfer-table-content__input">
                    <div className="transfer-title">Bàn hiện tại</div>
                    <Input
                        value={productInPostStore.dataInfoTable?.name || ""}
                        className="custom-input"
                        disabled
                    />
                    <div className="transfer-title">Chuyển bàn</div>
                    <SelectCustom
                        placeholder="Chọn bàn chuyển"
                        list={transferTableStore.listAllDiningTables.map((item) => ({
                            id: item.id,
                            name: item.name,
                        }))}
                        onChange={(value) => {
                                transferTableStore.setSelectedTableId(value)
                                const item = transferTableStore.listAllDiningTables.filter((item) => item.id === value);
                                transferTableStore.tableSwitch = item;
                            }
                        }
                        selected={() => {}}
                        getLabel={(item: any) => {
                            return item.name;
                        }}
                    />
                </div>
            </div>
            <div className="line2"></div>
        </Modal>
    );
};

export default observer(TransferTable);
