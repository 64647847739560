import axios, { HttpStatusCode } from "axios";
import { makeAutoObservable, toJS } from "mobx";
import { toastUtils } from "../../../common/utils/Toastutils";
import { productInPosService } from "./ProductInPosService";
import StorageService from "../../../common/service/StorageService";
import { productInPostStore } from "./ProductInPostStore";
import { dataStatusTable } from "../../../common/constants/Constants";
import { paymentPosStore } from "../paymentPos/PaymentPosStore";

export interface IdataFormAddCart {
    product_id: number | null;
    productName: string;
    variant: {
        id: number | null;
        name: string;
    };
    idData: number | null;
    quantity: number;
    price: number | null;
    discount: number;
    unit: string; // cây/quả...
    sku?: string;
    note: string;
    tmp_order_status: string;
    row_id: string | null;
    row_id_toAddDel: string | null;
    dining_tables_session: string;
    edited: boolean
    // totalPrice?: number | null;
}

class ProductInPosCartStore {
    isLoading: boolean = false;
    isLoadingBtn: boolean = false;
    isLoadingOrder: boolean = false;
    isLoadingBooking: boolean = false
    isAddToCart: boolean = false;
    flagActiveVariant: boolean = false;
    isSave: boolean = false;
    dataDetailProduct: any = {};
    dataDetailVariant: any = {};
    isOpenModalDetailProduct: boolean = false;
    isOpenModalNoteCartItem: boolean = false;
    isSimplePro: boolean = false;

    dataVariant: any = [];
    dataImagewithTypeImage: any = [];
    dataImagewithTypeVariant: any = [];
    dataImageFull: any = [];

    arrVariantChoose: any[] = [];
    arrVariantChooseName: any[] = [];

    dataListProInCart: any = [];

    activeVariantProduct: any = null;
    activeActionControl: any = null;
    postId: any = null;
    diningTablesId: any = null;
    diningTablesSessionId: any = null;
    searchBarcode: string = "";

    dataCustomer: any; // dùng khi chọn khách hàng

    dataFullVariant: any[] = [];
    dataAddToCart: any = [];
    row_id: string | null = "";
    diningTableSession: string = "";

    dataFormAddCart: IdataFormAddCart = {
        product_id: null,
        productName: "",
        variant: {
            id: null,
            name: "",
        },
        idData: null,
        quantity: 1,
        price: null,
        discount: 0,
        unit: "", // cây/quả...
        sku: "",
        note: "",
        tmp_order_status: "2",
        row_id: "",
        row_id_toAddDel: "",
        dining_tables_session: "",
        edited: false
    };

    dataCheckOut = {
        checkOutPrice: 0,
        remaingPrice: 0,
    };

    productControl: any = {
        type: "sl",
        keycap: null,
        idVariant: null,
    };

    dataNote: {
        rowId: string | undefined,
        note: string | undefined,
    } = {
        rowId: "",
        note: "",
    }

    constructor() {
        // this.initializeParams();
        makeAutoObservable(this);
    }

    async getListVariant() {
        this.isLoading = true;
        const result = await productInPosService.fetchListAttributeByProduct(
            this.dataDetailProduct.id
        );
        this.isLoading = false;

        if (result.status === HttpStatusCode.Ok) {
            this.dataVariant = result.body;
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }

    async getProductByType(type: string) {
        this.isLoading = true;

        const result = await productInPosService.fetchProductByType(
            type,
            this.dataDetailProduct.id
        );
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            if (type === "image") {
                this.dataImagewithTypeImage =
                    result.body.gallery?.value?.length > 0
                        ? result.body.gallery?.value?.map((item: any) => ({
                              id: Math.floor(1000 + Math.random() * 9000),
                              thumbnail: item,
                          }))
                        : [];
            }
            if (type === "variant") {
                this.dataImagewithTypeVariant =
                    result.body.data?.length > 0
                        ? result.body.data?.map((item: any) => ({
                              id: item.id,
                              thumbnail: item.thumbnail,
                          }))
                        : [];
                this.dataFullVariant = result.body.data;

                if (!result.body.product_type) {
                    this.isSimplePro = true;
                } else {
                    this.isSimplePro = false;
                }
            }

            this.dataImageFull = [
                ...this.dataImagewithTypeImage,
                ...this.dataImagewithTypeVariant,
            ];
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }

    async getDetailsVariant(params: any) {
        if (this.dataDetailProduct.id) {
            this.isLoadingBtn = true;
            const result =
                await productInPosService.fetchDetailVariantByAttributeId(
                    this.dataDetailProduct.id,
                    params
                );
            this.isLoadingBtn = false;
            if (result.status === HttpStatusCode.Ok) {
                this.dataDetailVariant = result.body;
            } else {
                this.isLoadingBtn = true;
                toastUtils.error("Xin lỗi mã sản phẩm không tồn tại", "2");
            }
        }
    }

    CaculatorTotal(arr: any) {
        const total =
            arr.length > 0
                ? arr.reduce((accumulator: any, currentValue: any) => {
                      const priceWithDiscount =
                          currentValue.discount > 0
                              ? ((((currentValue.price as number) *
                                    currentValue.quantity) as number) *
                                    (100 - currentValue.discount)) /
                                100
                              : (((currentValue.price as number) *
                                    currentValue.quantity) as number);
                      return accumulator + priceWithDiscount;
                  }, 0)
                : 0;

        return total;
    }

    CaculatorTotalNew(arr: any) {
        const total =
            arr.length > 0
                ? arr.reduce((accumulator: any, currentValue: any) => {
                      const priceWithDiscount =
                          currentValue.discount > 0
                              ? ((((currentValue.price as number) *
                                    currentValue.qty) as number) *
                                    (100 - currentValue.discount)) /
                                100
                              : (((currentValue.price as number) *
                                    currentValue.qty) as number);
                      return accumulator + priceWithDiscount;
                  }, 0)
                : 0;

        return total;
    }

    // async getVariantByBarcode(barcode: string) {
    //   if (barcode && barcode.length === 13 && this.isSave === false) {
    //     this.isSave = true
    //     const result = await productInPosService.fetchVariantByBarcode(barcode);
    //     if (result.status === HttpStatusCode.Ok) {
    //       const dataVariant = {
    //         productId: result.body.product.id,
    //         productName: result.body.product.title,
    //         variant: {
    //           id: result.body.id,
    //           name: result.body.attribute_compare,
    //         },
    //         quantity: 1,
    //         price: result.body.price,
    //         discount: 0,
    //         unit: result.body.product.unit, // cây/quả...
    //         sku: result.body.sku,
    //       };

    //       const currentCart =
    //         StorageService.getArrayFromLS(`cartPos${this.postId}/cartTable${this.diningTablesId}`) || [];
    //       const existingItemIndex = currentCart.findIndex(
    //         (item) => item.variant.id === result.body.id
    //       );

    //       if (existingItemIndex > -1) {
    //         // nếu đã tồn tại thì chỉ cộng số lượng
    //         currentCart[existingItemIndex].quantity =
    //           Number(currentCart[existingItemIndex].quantity) + 1;
    //       } else {
    //         // thêm vào mảng mới
    //         currentCart.unshift(dataVariant);
    //       }
    //       this.isSave = false
    //       this.dataListProInCart = currentCart;
    //       this.flagActiveVariant = true;
    //       this.activeVariantProduct = result.body.id;
    //       this.searchBarcode = "";

    //       StorageService.saveArayLS(
    //         `cartPos${this.postId}`,
    //         this.dataListProInCart
    //       );
    //       toastUtils.success("Thêm giỏ hàng thành công",'2');
    //     }
    //   }
    // }

    ChangeValueProductInfo(type: any, keycap: any, idVariant: any) {
        this.productControl.type = type;
        this.productControl.keycap = keycap;
        this.productControl.idVariant = idVariant;

        if (type === "sl" && keycap !== "sl") {
            const productIndex = this.dataListProInCart.items.findIndex(
                (item: any) =>  item.idData === idVariant //item.variant.id === idVariant
            );
            const product = this.dataListProInCart.items[productIndex];

            if (product) {
                if (keycap === "del2") {
                    // Xóa từng số từ ngoài vào trong
                    product.quantity =
                        String(product.quantity).slice(0, -1) || "0"; // Xóa ký tự cuối

                    // Chỉ chuyển về số nếu không có dấu "."
                    if (!product.quantity.includes(".")) {
                        product.quantity = Number(product.quantity);
                    }
                } else if (keycap === "dots") {
                    // Xử lý dấu "." cho số thập phân
                    if (!String(product.quantity).includes(".")) {
                        product.quantity = `${product.quantity}.`; // Thêm dấu "." nếu chưa có
                    }
                } else {
                    if (product.quantity === 0) {
                        // Nếu số hiện tại là 0, thay thế trực tiếp bằng số mới để tránh hiện 05, 07, etc.
                        product.quantity = keycap;
                    } else {
                        // Nếu số hiện tại không phải 0, nối chuỗi như bình thường
                        product.quantity = `${product.quantity}${keycap}`;
                    }
                }
            }
        }

        if (type === "price" && keycap !== "price") {
            // const product = this.dataListProInCart.find(
            //   (item) => item.variant.id === idVariant
            // );
            const productIndex = this.dataListProInCart.items.findIndex(
                (item: any) =>  item.idData === idVariant //item.variant.id === idVariant
            );
            const product = this.dataListProInCart.items[productIndex];

            if (product) {
                if (keycap === "del2") {
                    // Xóa từng số từ ngoài vào trong
                    product.price = String(product.price).slice(0, -1) || "0";
                    product.price = Number(product.price);
                } else if (keycap === "dots") {
                    return;
                } else {
                    product.price = Number(`${product.price}${keycap}`);
                }
            }
        }

        if (type === "ck" && keycap !== "ck") {
            const productIndex = this.dataListProInCart.items.findIndex(
                (item: any) =>  item.idData === idVariant //item.variant.id === idVariant
            );
            const product = this.dataListProInCart.items[productIndex];

            if (product) {
                if (keycap === "del2") {
                    // Xóa từng số từ ngoài vào trong
                    product.discount =
                        String(product.discount).slice(0, -1) || "0";

                    // Chỉ chuyển về số nếu không có dấu "."
                    if (!product.discount.includes(".")) {
                        product.discount = Number(product.discount);
                    }
                } else if (keycap === "dots") {
                    // Xử lý dấu "." cho số thập phân
                    if (!String(product.discount).includes(".")) {
                        product.discount = `${product.discount}.`; // Thêm dấu "." nếu chưa có
                    }
                } else {
                    if (product.discount === 0) {
                        product.discount = keycap;
                    } else {
                        // Nếu số hiện tại không phải 0, nối chuỗi như bình thường
                        product.discount = `${product.discount}${keycap}`;

                        if (product.discount > 100) {
                            product.discount = 100;
                        }
                    }
                }
            }
        }

        if (keycap === "del1") {
            this.dataListProInCart.items = this.dataListProInCart.items.filter(
                (item: any) =>  item.idData !== idVariant //item.variant.id !== idVariant
            );
            if (!idVariant) {
                toastUtils.warning("Vui lòng chọn món để xóa", "");
                return;
            }
            this.removeCartItem();
        }

        if (this.dataListProInCart.items?.length < 1) {
            this.dataListProInCart.dining_table_status = `${dataStatusTable.booked}`;
        }
        StorageService.setObjectStore(
            `cartPos${this.postId}-${this.diningTablesId}`,
            this.dataListProInCart
        );
        StorageService.setObjectStore(
            `tableMergePrimary-${productInPostStore.dataInfoTable?.dining_table_session?.merge_dining_tables_name}`,
            this.dataListProInCart,
        );
        
        const dataCartChange = StorageService.getObjectStore(`cartPos${this.postId}-${this.diningTablesId}`)
        const dataCartOrigin = StorageService.getObjectStore(`cartPosOrigin${this.postId}-${this.diningTablesId}`)
        

        // Hàm so sánh và cập nhật trường `edited`
        const updateEditedStatus = (arrChange: any[], arrOrigin: any[]) => {
            return arrChange.map((changeItem) => {
            const originItem = arrOrigin.find((item) => item.idData === changeItem.idData);
            if (!originItem) return changeItem; // Nếu không tìm thấy item tương ứng, giữ nguyên
        
            // Kiểm tra sự thay đổi các thuộc tính
            const isEdited =
                changeItem.discount !== originItem.discount ||
                changeItem.price !== originItem.price ||
                String(changeItem.quantity) !== String(originItem.quantity);
        
            // Cập nhật trường `edited`
            return { ...changeItem, edited: isEdited };
            });
        };
        
        // Cập nhật arrChange
        const updatedArrChange = updateEditedStatus(dataCartChange?.items, dataCartOrigin?.items);
        
        const dataUpdateFull = {...this.dataListProInCart, items: updatedArrChange}
        
        if(productInPostStore.dataInfoTable?.dining_table_session?.merge_dining_tables_name){
            StorageService.setObjectStore(
                `cartPos${this.postId}-${this.diningTablesId}`,
                dataUpdateFull
            );
            StorageService.setObjectStore(
                `tableMergePrimary-${productInPostStore.dataInfoTable?.dining_table_session?.merge_dining_tables_name}`,
                dataUpdateFull,
            );
            this.dataListProInCart = dataUpdateFull
        }else{
            StorageService.setObjectStore(
                `cartPos${this.postId}-${this.diningTablesId}`,
                dataUpdateFull
            );
            this.dataListProInCart = dataUpdateFull
        }
    }

    async addToCartFNB() {
        this.isAddToCart = true;
        let params = {
            product_id: this.dataAddToCart?.product_id,
            quantity: this.dataAddToCart?.quantity,
            variant_id: this.dataAddToCart?.variant?.id,
            pos_id: this.postId,
            dining_table_id: this.diningTablesId,
        };

        const result = await productInPosService.addToCartFnb(params);
        this.isAddToCart = false;

        if (result.status === HttpStatusCode.Ok) {
            // Call infn_table để cập nhập số khách(mặc định thêm món sẽ là 1 khách) - call duy nhất 1 lần đầu tiên khi mới thêm món đầu tiên
            if (!productInPostStore.dataInfoTable?.dining_table_session) {
                productInPostStore.getDataInfoTable();
            } else if (productInPostStore.dataInfoTable?.dining_table_session && productInPostStore.dataInfoTable?.dining_table_session?.end_time_working) {
                productInPostStore.getDataInfoTable();
            }

            const dataAPI = result.body.data.list;

            // Nếu roomID = 0 (là bàn lẻ không có id bàn cha) gán là ""
            const currentCart = StorageService.getObjectStore(
                `cartPos${this.postId}-${this.diningTablesId}`
            ) || {
                dining_table_status: "",
                dining_table_session: "",
                merge_dining_tables_id: "",
                items: [],
            };

            currentCart.items = currentCart.items.map((item: any, indexLocal:number) => {
                const matchItem = dataAPI.find((apiItem: any, indexApi:number) => {
                    let parsedOptions;
                    try {
                        parsedOptions = JSON.parse(apiItem.options);
                    } catch (error) {
                        console.error("Failed to parse options:", error);
                        return false; // Nếu lỗi xảy ra khi parse, bỏ qua item này
                    }
                    // Kiểm tra điều kiện so sánh id va variantId cua local va store
                    return (
                        apiItem.product_id?.toString() ===
                            item.product_id?.toString() &&
                        parsedOptions?.variant?.id.toString() ===
                            item.variant?.id.toString() && item.tmp_order_status?.toString() === apiItem.tmp_order_status?.toString() && indexLocal === indexApi // Thêm so sánh key index để biết item cũ và mới tránh lỗi trùng idData lúc add_to_cart
                    );
                });

                // Nếu tìm thấy matchItem, gán row_id từ matchItem cho local
                if (matchItem) {
                    return {
                        ...item,
                        row_id: matchItem?.rowId?.toString(),
                        dining_tables_session:
                            matchItem?.dining_tables_session?.toString(),
                        idData: matchItem.id,
                    };
                }
                return item;
            });

            currentCart.dining_table_session =
                result.body.data.dining_tables_session.toString();
            this.dataListProInCart = currentCart;
            StorageService.setObjectStore(
                `cartPos${this.postId}-${this.diningTablesId}`,
                this.dataListProInCart
            );
            StorageService.setObjectStore(
                `cartPosOrigin${this.postId}-${this.diningTablesId}`,
                this.dataListProInCart
            );

            productInPostStore.dataInfoTable?.dining_table_session?.merge_dining_tables_name && StorageService.setObjectStore(
                `tableMergePrimary-${productInPostStore.dataInfoTable?.dining_table_session?.merge_dining_tables_name}`,
                this.dataListProInCart,
              );

            // active món ăn vừa thêm vào giỏ hàng
            productInPosCartStore.activeVariantProduct = dataAPI[0]?.id;
            productInPosCartStore.row_id = dataAPI[0]?.rowId.toString(); 
            // toastUtils.success("Thêm vào giỏ hàng thành công", "");
        }
    }

    async toCheckoutPayment(callback?: () => void) {
        this.isLoadingBtn = true;

        // Lấy ra những items mà đã order(đặt món) rồi
        const getItemLocal = StorageService.getObjectStore(
            `cartPos${this.postId}-${this.diningTablesId}`
        )?.items.filter(
            (item: any) => item.tmp_order_status.toString() === "1"
        );

        const getItemLocal_dining = StorageService.getObjectStore(
            `cartPos${this.postId}-${this.diningTablesId}`
        );

        const cart_items = getItemLocal?.map((item: any) => {
            return {
                row_id: item.row_id,
                qty: item.quantity,
                price: item.price,
                discount: item.discount === "" ? 0 : parseInt(item.discount),
                note: item.note,
            };
        });

        const params = {
            cart_items: cart_items,
            dining_table_id: parseFloat(this.diningTablesId),
            dining_table_session: getItemLocal_dining.dining_table_session,
            pos_id: parseFloat(this.postId),
        };

        const result = await productInPosService.createOrder(params);
        this.isLoadingBtn = false;
        if (result.status === HttpStatusCode.Ok) {
            const checkOutPrice = this.CaculatorTotal(getItemLocal);

            // Lưu giá trị tổng tiền của các sản phẩm order
            paymentPosStore.priceTotalAfterPayment = checkOutPrice;
            paymentPosStore.priceTotal = checkOutPrice;
            paymentPosStore.savePriceToLocal(this.postId, this.diningTablesId) // Lưu gía trị vào local
            
            if (callback) {
                callback();
            }
        }
    }

    async saveOrderCart() {
        // Nếu roomID = 0 (là bàn lẻ không có id bàn cha) gán là ""
        const getItemLocal = StorageService.getObjectStore(
            `cartPos${this.postId}-${this.diningTablesId}`
        );

        // Tạo mảng mới và bỏ các key không cần thiết
        const cart_items = getItemLocal.items?.map((item: any) => {
            return {
                row_id: item.row_id,
                qty: item.quantity,
                price: item.price,
                discount: item.discount === "" ? 0 : parseFloat(item.discount),
                note: item.note,
            };
        });

        const params = {
            cart_items: cart_items,
            dining_table_id: parseFloat(this.diningTablesId),
            dining_table_session: getItemLocal.dining_table_session,
            pos_id: parseFloat(this.postId),
        }; 

        this.isLoadingOrder = true;

        const result = await productInPosService.createOrder(params);

        this.isLoadingOrder = false;
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Order món ăn thành công", "");
            const dataAPI = result.body.cart_list;

            const currentCart = StorageService.getObjectStore(
                `cartPos${this.postId}-${this.diningTablesId}`
            ) || {
                dining_table_status: `${dataStatusTable.order}`,
                dining_table_session: "",
                merge_dining_tables_id: "",
                items: [],
            };

            currentCart.items = currentCart.items.map((itemLocal: any) => {
                const matchItem = dataAPI.find((itemApi: any) => {
                    return (
                        // itemApi.product_id?.toString() ===
                        //     itemLocal.product_id?.toString() &&
                        // itemApi?.options?.variant?.id?.toString() ===
                        //     itemLocal.variant?.id.toString() && 
                        itemApi.id?.toString() === itemLocal.idData?.toString()
                    );
                });

                if (matchItem) {
                    return {
                        ...itemLocal,
                        // row_id: matchItem.rowId.toString(),
                        tmp_order_status: matchItem.tmp_order_status.toString(),
                        edited: false
                    };
                }

                return itemLocal;
            });

            currentCart.dining_table_session =
                result.body.dining_tables_session;
            currentCart.dining_table_status = dataStatusTable.order;
            this.dataListProInCart = currentCart;
            StorageService.setObjectStore(
                `cartPos${this.postId}-${this.diningTablesId}`,
                this.dataListProInCart
            );
            StorageService.setObjectStore(
                `cartPosOrigin${this.postId}-${this.diningTablesId}`,
                this.dataListProInCart
            );
            StorageService.setObjectStore(
                `tableMergePrimary-${productInPostStore.dataInfoTable?.dining_table_session?.merge_dining_tables_name}`,
                this.dataListProInCart,
              );
        }
    }

    async removeCartItem() {
        const params = {
            row_id: this.row_id,
            dining_table_session: this.diningTableSession,
            type: "destroy",
        };
        const result = await productInPosService.removeItem(params);
        // Xóa xong luôn luôn active món đầu tiên
        const currentCart = StorageService.getObjectStore(
            `cartPos${this.postId}-${this.diningTablesId}`
        )
        productInPosCartStore.activeVariantProduct = currentCart?.items[0]?.idData;
        productInPosCartStore.row_id = currentCart?.items[0]?.row_id?.toString();
    }

    async startBookingTable() {
        this.isLoadingBooking = true;

        const params = {
            pos_id: this.postId.toString(),
            dining_table_id: this.diningTablesId.toString(),
        };
        const result = await productInPosService.startBooking(params);

        // this.isLoadingBooking = false;

        if (result.status === HttpStatusCode.Ok) {
            // toastUtils.success("Đặt bàn thành công", "");
            productInPostStore.getDataInfoTable().then(() => {
                toastUtils.success("Đặt bàn thành công", "");
                this.isLoadingBooking = false
            })
            // Đặt bàn thành công gán session và status vào trong store và local
            let currentCart = StorageService.getObjectStore(
                `cartPos${this.postId}-${this.diningTablesId}`
            ) || {
                dining_table_status: "",
                dining_table_session: "",
                merge_dining_tables_id: "",
                items: [],
            };

            currentCart.dining_table_session =
                result.body.dining_tables_session.toString();
            currentCart.dining_table_status = `${dataStatusTable.booked}`;
            currentCart.dining_table_status = "";

            this.dataListProInCart = currentCart;
            StorageService.setObjectStore(
                `cartPos${this.postId}-${this.diningTablesId}`,
                currentCart
            );
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
            this.isLoadingBooking = false;
        }
    }

    async endBookingTable() {
        this.isLoadingBooking = true;
        const params = {
            pos_id: this.postId.toString(),
            dining_table_id: this.diningTablesId.toString(),
        };
        const result = await productInPosService.endBooking(params);
        // this.isLoadingBooking = false;
        if (result.status === HttpStatusCode.Ok) {
            // toastUtils.success("Giải phóng bàn thành công", "");
            productInPostStore.getDataInfoTable().then(() => {
                toastUtils.success("Giải phóng bàn thành công", "");
                this.isLoadingBooking = false
            });

            let currentCart = StorageService.getObjectStore(
                `cartPos${this.postId}-${this.diningTablesId}`
            ) || {
                dining_table_status: "",
                dining_table_session: "",
                merge_dining_tables_id: "",
                items: [],
            };

            currentCart.dining_table_session = "";
            currentCart.dining_table_status = `${dataStatusTable.init}`;

            this.dataListProInCart = currentCart;
            StorageService.setObjectStore(
                `cartPos${this.postId}-${this.diningTablesId}`,
                currentCart
            );
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
            this.isLoadingBooking = false;
        }
    }

    async noteItemCart(callback?: () => void) {
        this.isLoadingBtn = true;
        const params = {
            row_id: this.row_id,
            note: this.dataNote.note
        }
        this.isLoadingBtn = true;

        const result = await productInPosService.noteCartItems(params);
        this.isLoadingBtn = false;
        if (result.status === HttpStatusCode.Ok) {
            let dataAPI = [result.body];
            let currentCart = StorageService.getObjectStore(
                `cartPos${this.postId}-${this.diningTablesId}`
            );

            currentCart.items = currentCart.items.map((itemLocal: any) => {
                const matchItem = dataAPI?.find((itemApi: any) => {
                    return (
                        itemApi.id?.toString() ===
                            itemLocal.idData?.toString()
                    );
                });

                if (matchItem) {
                    return {
                        ...itemLocal,
                        note: matchItem.note,
                    };
                }

                return itemLocal;
            });

            this.dataListProInCart = currentCart;
            StorageService.setObjectStore(
                `cartPos${this.postId}-${this.diningTablesId}`,
                this.dataListProInCart
            );


            // Callback để resetForm
            if(callback) {
                callback()
            }
            this.isOpenModalNoteCartItem = false;
        }
    }
}

export const productInPosCartStore = new ProductInPosCartStore();
