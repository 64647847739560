import {postRequest} from "../../../../../../common/helpers/RequestHelper";

class NumberGuestService {
    apiSaveGuest = "api/v1/pos/fnb/set_actual_number_of_customer"

    public saveGuest(ParamSaveGuest: any): Promise<any> {
        return postRequest(`${this.apiSaveGuest}`, ParamSaveGuest);
    }
}

export const numberGuestService = new NumberGuestService();