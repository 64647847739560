import {observer} from "mobx-react";
import {dashboardStore} from "../../DashboardStore";
import { Line} from "react-chartjs-2";

import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
} from 'chart.js';
import loading from "../../../../shared/components/loading/Loading";
import {Dropdown} from "react-bootstrap";

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    Title,
    Tooltip,
    Legend,
    PointElement
);
interface CustomerChartProps {
    dataCustomer: any[];
    dataListStore: any[];

}
const CustomerChart: React.FC<CustomerChartProps> = ({ dataCustomer,dataListStore }) =>{
    const listCusomerChart = [
        {type:'date',value:'Theo ngày'},
        {type:'dayofweek',value:'Theo thứ'},
        {type:'hour',value:'Theo giờ'},
    ]
    const backgroundColors: { [key: number]: string } = {
        0 : '#0E72ED',
        1 : '#FF538A',
        2 : '#01BFAC',
        3 : '#FE9339',
        4 : '#FE5139',
        5 : '#9039FE',
        6 : '#D739FE',
        7 : '#39C3FE',
        8 : '#FF0D0D',
        9: '#51BD36',
    };
    let datasets:any;
    let labels: any[] = [];

    if(dashboardStore.typeCustomer == 'date'){
        const start = new Date(dashboardStore.staticDataSalesChart.startDate);
        const end = new Date(dashboardStore.staticDataSalesChart.endDate);
        let labelsMap: any[] = [];
        let dataStore: any[] = []
        for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
            labelsMap.push(new Date(d).toISOString().split('T')[0]);
            const day = d.getDate();
            labels.push(day);
        }

        if(labelsMap.length > 31){
            labels = Array.from({ length: 31 }, (_, i) => i + 1);
        }

        datasets = dataListStore.map((item: any, index: number) => {

            if (labelsMap.length > 31) {
                dataStore = new Array(31).fill(0);
  
                dataCustomer.forEach(({ time, customer_count, store_id }) => {
                  if (item.id === store_id) {
                    const dateIndex = new Date(time).getDate() - 1; // Lấy index dựa trên ngày
                    dataStore[dateIndex] += customer_count; // Cộng dồn total_sum vào vị trí tương ứng
                  }
                });
              }
              else{
                  dataStore = labelsMap.map(labelDate => {
                     const itemDataCustomer = dataCustomer.find(data => data.time === labelDate && item.id == data.store_id);
                     return itemDataCustomer ? +itemDataCustomer.customer_count : 0;
                 });
              }
            const backgroundColor: string = backgroundColors[index % 10] ?? '#0000FF';
            return {
                label: item.name,
                data: dataStore,
                backgroundColor: backgroundColor,
                borderColor: backgroundColor,
                tension: 0.4,
            };
        });
    }else if (dashboardStore.typeCustomer == 'dayofweek'){
        datasets = dataListStore.map((item: any, index: number) => {
            var dataStoreT2:number = 0,dataStoreT3:number = 0,dataStoreT4:number = 0,dataStoreT5:number = 0,dataStoreT6:number = 0,dataStoreT7:number = 0,dataStoreCN:number = 0;
            dataCustomer.map((itemData: any) => {
                if(itemData.time == 1 && itemData.store_id == item.id){
                    dataStoreCN = + itemData.customer_count;
                }else if(itemData.time == 2 && itemData.store_id == item.id){
                    dataStoreT2 = + itemData.customer_count;
                }else if(itemData.time == 3 && itemData.store_id == item.id){
                    dataStoreT3 = + itemData.customer_count;
                }else if(itemData.time == 4 && itemData.store_id == item.id){
                    dataStoreT4 = + itemData.customer_count;
                }else if(itemData.time == 5 && itemData.store_id == item.id){
                    dataStoreT5 = + itemData.customer_count;
                }else if(itemData.time == 6 && itemData.store_id == item.id){
                    dataStoreT6 = + itemData.customer_count;
                }else if(itemData.time == 7 && itemData.store_id == item.id){
                    dataStoreT7 = + itemData.customer_count;
                }
            });
            const backgroundColor: string = backgroundColors[index % 10] ?? '#0000FF';
            return {
                label: item.name,
                data: [dataStoreT2,dataStoreT3,dataStoreT4,dataStoreT5,dataStoreT6,dataStoreT7,dataStoreCN],
                backgroundColor: backgroundColor,
                borderColor: backgroundColor,
                tension: 0.4,
            };
        });
        const weekName= ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'];
        labels = weekName;
    }else if(dashboardStore.typeCustomer == 'hour'){
        datasets = dataListStore.map((item: any, index: number) => {
            const dataStores = new Array(24).fill(0);
            dataCustomer.forEach((itemData: any) => {
                if (itemData.store_id == item.id) {
                    const orderTime = itemData.time;
                    if (orderTime >= 0 && orderTime < 24) {
                        dataStores[orderTime] = +itemData.customer_count;
                    }
                }
            });
            const backgroundColor: string = backgroundColors[index % 10] ?? '#0000FF';
            return {
                label: item.name,
                data: dataStores.slice(0, 24),
                backgroundColor: backgroundColor,
                borderColor: backgroundColor,
                tension: 0.4,
            };
        });
        const weekName= ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'];
        labels = weekName;
    }

    const data = {
        labels: labels,
        datasets: datasets
    };

    return (
        <div id="customerChart">
            <div className="saleChartHeader">
                <p>KHÁCH HÀNG TRÊN HỆ THỐNG</p>
                <div className="saleChartHeaderFilter">
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdownFitterSale">
                        <span
                            className="titleTime"
                            id="titleTime"
                        >
                            {listCusomerChart.find((item:any) => item.type === dashboardStore.typeCustomer)?.value}
                        </span>
                            <img
                                src="/assets/icon/fi-sr-caret-down.svg"
                                alt="fi-sr-caret-down"
                            />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {listCusomerChart.map(
                                (item: any) => (
                                    <Dropdown.Item
                                        key={item.type}
                                        onClick={(event) => {
                                            dashboardStore.typeCustomer = item.type
                                            dashboardStore.fetchDataCustomer()
                                        }}
                                    >
                                        {" "}
                                        {item.value}
                                    </Dropdown.Item>
                                )
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <ul>
                        {listCusomerChart.map((item: any, index) => (
                            <li key={index}>
                                <button
                                    className={`item-type-sales' ${dashboardStore.typeCustomer === item.type ? 'active' : ''}`}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        dashboardStore.typeCustomer = item.type;
                                        dashboardStore.fetchDataCustomer()
                                    }}
                                >
                                    {item.value}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="chart">
                <Line data={data} options={{responsive: true}}/>
            </div>
        </div>
    )
}
export default observer(CustomerChart);