import React from "react";
import { Modal, Button } from "antd";
import "./ModalPermission.scss";
import {permissionSiteStore} from "../PermissionSiteStore";
import {observer} from "mobx-react";

const ModalPermission = () => {
    return (
        <Modal
            open={permissionSiteStore.isOpenModal = true}
            onCancel={() => (permissionSiteStore.isOpenModal = false)}
            footer={null}
            className="custom-modal"
        >
            <div className="modal-content">
                <div className="images-container">
                    <img src="/assets/images/imagePermission.png" alt="Permission" />
                </div>
                <div className="modal-text">
                    Bạn chưa sở hữu website để bán và đồng bộ đơn hàng từ POS.
                </div>
                <div className="button-container">
                    <Button onClick={() => (permissionSiteStore.isOpenModal = false)}  className="close-btn">
                        Đóng
                    </Button>
                    <Button type="primary" className="create-btn" >
                        Tạo mới website
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default observer(ModalPermission);