import React, {useEffect} from "react";
import {observer} from "mobx-react";
import LayoutSettings from "../components/layout/LayoutSettings";
import Loading from "../../../shared/components/loading/Loading";
import NoContent from "../../../shared/components/NoContent";
import {Dropdown} from "react-bootstrap";
import {showDelete} from "../../../shared/components/showDelete";
import {customerStore} from "./CustomerStore";
import FilterCustomer from "./components/FilterCustomer";
import ReactPaginate from "react-paginate";
import {createSearchParams, useLocation, useNavigate} from "react-router-dom";
import CustomerModal from "./components/CustomerModal";

const CustomerPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handlePageClick = (data: { selected: number }) => {
        const selectedPage = data.selected;
        const params: any = {};
        if (customerStore.staticParamCustomer.phone !== "")
            params.keyword =
                customerStore.staticParamCustomer.phone;
        params.page = selectedPage + 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        customerStore.staticParamCustomer.page_number = selectedPage;
        // customerStore.getCustomerWithRequirement(true);
    };

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        customerStore.staticParamCustomer.phone = query.get("phone")
            ? String(query.get("phone"))
            : "";
        customerStore.searchTemp = query.get("phone")
            ? String(query.get("phone"))
            : "";
        customerStore.staticParamCustomer.page_number = query.get("page")
            ? Number(query.get("page")) - 1
            : 0;
        customerStore.getCustomerWithRequirement().then()
    }, [location.search])

    return (
        <>
            <LayoutSettings keyActive={'khach-hang'}>
                <div className="AdminPage">
                    <div className="AdminPage-header">
                        <h1 className="title">Danh sách khách hàng</h1>
                        <FilterCustomer/>
                    </div>
                    <div className="AdminPage-body">
                        <div className="table-container">
                            <div className="table-responsive">
                                <table className="table table-striped" id="table-2">
                                    <thead>
                                    <tr>
                                        <th style={{width: "20%"}}>Tên</th>
                                        <th style={{width: "20%"}}>Số điện thoại</th>
                                        <th style={{width: "30%"}}>Email</th>
                                        {/*<th style={{width: "20%", textAlign:"center"}}>Trạng thái</th>*/}
                                        <th style={{width: "10%", textAlign:"center"}}>Hành động</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {/* {customerStore.isLoading === true ? (
                                        <tr>
                                            <td colSpan={5}>
                                                <Loading/>
                                            </td>
                                        </tr>
                                    ) : ( */}
                                        <>
                                            {customerStore.loadingDataTable === true ? (
                                                <tr>
                                                    <td colSpan={5}>
                                                        <Loading/>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <>
                                                    {customerStore.listCustomer.length === 0 ? (
                                                        <tr>
                                                            <td colSpan={5}>
                                                                <NoContent/>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <>
                                                            {customerStore.listCustomer.map(
                                                                (item: any, index: number) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                <span title={item?.name}>
                                                                    {item?.name}
                                                                </span>
                                                                        </td>
                                                                        <td>
                                                                                <span title={item.phone}>
                                                                        {item.phone}
                                                                    </span>
                                                                        </td>

                                                                        <td>
                                                                            <span title={item.email}>
                                                                                {item.email}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <Dropdown
                                                                                align={"start"}
                                                                                className="action-table"
                                                                                style={{textAlign: "center"}}
                                                                            >
                                                                                <Dropdown.Toggle>
                                                                                    Thao tác
                                                                                </Dropdown.Toggle>

                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item
                                                                                        onClick={() => {
                                                                                            customerStore.typeModal = "view";
                                                                                            customerStore.modalOpen = true;
                                                                                            customerStore.dataFormCustomer = {
                                                                                                ...item,
                                                                                                location_id: item.location_store_id,
                                                                                                province_id: item.city_id || null,
                                                                                            };
                                                                                        }}
                                                                                    >
                                                                                        <i className="fa fa-eye"></i>
                                                                                        Xem
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item
                                                                                        onClick={() => {
                                                                                            customerStore.typeModal = "edit";
                                                                                            customerStore.modalOpen = true;
                                                                                            customerStore.idCustomer = item.id;
                                                                                            customerStore.dataFormCustomer = {
                                                                                                ...item,
                                                                                                location_id: item.location_store_id,
                                                                                                province_id: item.city_id || null,
                                                                                            };
                                                                                        }}
                                                                                    >
                                                                                        <i className="fa fa-edit"></i>
                                                                                        Sửa
                                                                                    </Dropdown.Item>
                                                                                    {/* <Dropdown.Item
                                                                                        onClick={() => {
                                                                                            showDelete.showDeleteConfirm(
                                                                                                "Xác nhận xoá thông tin",
                                                                                                `Bạn chắc chắn muốn xoá khách hàng"${item.name}" không?`,
                                                                                                async () => {
                                                                                                    await customerStore.deleteCustomer();
                                                                                                }
                                                                                            );
                                                                                            customerStore.staticDataCustomer.id =
                                                                                                item.id;
                                                                                        }}
                                                                                    >
                                                                                        <i className="fa fa-trash"></i>
                                                                                        Xóa
                                                                                    </Dropdown.Item> */}
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    {/* )} */}
                                    </tbody>
                                </table>
                            </div>
                            {customerStore.loadingDataTable
                                ? ""
                                : Math.ceil(
                                customerStore.metadata.total_page
                            ) > 1 && (
                                <ReactPaginate
                                    previousLabel={
                                        <img
                                            src="/assets/icon/fi-rr-angle-small-left.svg"
                                            alt="right"
                                        />
                                    }
                                    nextLabel={
                                        <img
                                            src="/assets/icon/fi-rr-angle-small-right.svg"
                                            alt="right"
                                        />
                                    }
                                    breakLabel={"..."}
                                    pageCount={Math.ceil(
                                        customerStore.metadata
                                            .total_page
                                    )}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                    forcePage={
                                        customerStore.staticParamCustomer.page_number
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
                <CustomerModal />
            </LayoutSettings>
        </>
    );
}

export default observer(CustomerPage);