import React, { useEffect } from 'react';
import { observer } from "mobx-react";
import { Modal, Button } from "antd";
import './NumberGuest.scss';
import { useParams } from "react-router-dom";
import { numberGuestStore } from "./NumberStore";
import { productInPostStore } from "../../../ProductInPostStore";

const NumberGuest = () => {
    const { posId, diningTableId } = useParams<{ posId: string, diningTableId: string }>();

    useEffect(() => {
        if (posId) {
            numberGuestStore.posId = posId;
        }
        if (diningTableId) {
            numberGuestStore.diningTableId = diningTableId;
        }
        productInPostStore.getDataInfoTable();
    }, [posId, diningTableId]);

    useEffect(() => {
        if (productInPostStore.dataInfoTable?.dining_table_session?.quantity_people_reality) {
            numberGuestStore.guestCount = productInPostStore.dataInfoTable.dining_table_session.quantity_people_reality.toString();
        }
    }, [productInPostStore.dataInfoTable]);

    return (
        <Modal
            title={
                <div className="header d-flex align-items-center justify-content-between">
                    Số khách
                </div>
            }
            open={numberGuestStore.modalOpen}
            onCancel={() => numberGuestStore.modalOpen = false}
            closeIcon={
                <img
                    src="/assets/icon/icon_closeWhite.svg"
                    width={25}
                    height={25}
                    alt="Close"
                    onClick={() => numberGuestStore.modalOpen = false}
                />
            }
            footer={[
                <Button key="cancel" onClick={() => numberGuestStore.modalOpen = false} className="cancel-button">
                    Huỷ bỏ
                </Button>,
                <Button key="transfer" type="primary" className="transfer-button" onClick={() => numberGuestStore.saveNumberGuest()}>
                    OK
                </Button>
            ]}
            className="number-guest-modal"
            wrapClassName="NumberGuest"
        >
            <div className="line"></div>
            <div className="guest-table-content">
                <div className="input-box">
                    {numberGuestStore.guestCount}
                </div>
                <div className="keypad">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(number => (
                        <div key={number} className="key" onClick={() => numberGuestStore.appendGuestNumber(number)}>
                            {number}
                        </div>
                    ))}
                    <div className="key_empty empty"></div>
                    <div className="key zero" onClick={() => numberGuestStore.appendGuestNumber(0)}>0</div>
                    <div className="key backspace" onClick={() => numberGuestStore.removeLastGuestNumber()}>⌫</div>
                </div>
            </div>
            <div className="line2"></div>
        </Modal>
    );
}

export default observer(NumberGuest);
