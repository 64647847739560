import { HttpStatusCode } from "axios";
import { makeAutoObservable, toJS } from "mobx";
import { toastUtils } from "../../../../../../common/utils/Toastutils";
import { combineTableService } from "./CombineTableService";
import StorageService from "../../../../../../common/service/StorageService";
import { productInPostStore } from "../../../ProductInPostStore";
import { productInPosCartStore } from "../../../ProductInPosCartStore";
import { dataStatusTable } from "../../../../../../common/constants/Constants";

type TdataFilterDiningTables = {
  current_table_id?: number | null;
  location_id?: number | null;
  pos_id?: number | null;
  status_id?: number | null;
};

type TformCombineTable = {
  dining_table_session: string | number;
  dining_table_id: string | number;
  dining_table_merge_ids: string[] | number[];
};
class CombineTableStore {
  listAllDiningTables: { id: string; name: string }[] = [];
  diningTableId = "";
  selectedTableId: string | null = null;
  isModalOpen = false;
  isLoading: boolean = false;
  isLoadingBtn: boolean = false;
  listTableFilter: any[] = [];
  posId: string | undefined = "";
  isOpenConfirm: boolean = false

  dataFilterDiningTables: TdataFilterDiningTables = {
    current_table_id: null,
    location_id: null,
    pos_id: null,
    status_id: null,
  };

  formCombineTable: TformCombineTable = {
    dining_table_session: "",
    dining_table_id: "",
    dining_table_merge_ids: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  async fetchAllDiningTablesByFilter(params?: TdataFilterDiningTables) {
    this.isLoading = true;
    const result = await combineTableService.getDinningTablesByFilter(params);
    this.isLoading = false;

    if (result.status === HttpStatusCode.Ok) {
      this.listTableFilter = result.body;
    } else {
      toastUtils.error("Đã có lỗi xảy ra", "");
    }
  }

  async combineTables() {
    const { dining_table_session, dining_table_id, dining_table_merge_ids } =
      this.formCombineTable;

    const data = {
      dining_table_session: dining_table_session,
      dining_table_id: dining_table_id,
      dining_table_merge_ids: dining_table_merge_ids,
    };


    this.isLoadingBtn = true;
    const result = await combineTableService.combineTable(data);
    this.isLoadingBtn = false;

    if (result.status === HttpStatusCode.Ok) {
      // this.listTableFilter = result.body;

      const newCartItem =
        result.body.cart_list.length > 0
          ? result.body.cart_list.map((item: any) => ({
              dining_tables_session: item.dining_tables_session,
              discount: item.discount,
              note: item.note || "",
              price: item.price,
              productName: item.product_name,
              product_id: item.product_id,
              quantity: item.qty,
              row_id: String(item.rowId),
              sku: item.product_sku,
              tmp_order_status: String(item.tmp_order_status),
              unit: item?.options?.unit,
              variant: {
                id: item?.options?.variant?.id,
                name: item?.options?.variant?.attribute_compare
                  ? item?.options?.variant?.attribute_compare
                  : "",
              },
              idData: item?.id,
            }))
          : [];

      const dataFull = {
        dining_table_session: result.body.dining_tables_session,
        // dining_table_status: result.body?.cart_list?.length > 0 ? dataStatusTable.cart : dataStatusTable.booked,
        // dining_table_status: result.body.dining_tables_status,
        merge_dining_tables_id : result.body?.merge_dining_tables_name || "",
        dining_table_status: dataStatusTable.order,
        items: newCartItem,
      };

      StorageService.setObjectStore(
        `cartPos${this.posId}-${
          productInPostStore.idCurrentTable
        }`,
          dataFull,
      );

      // StorageService.setObjectStore(
      //   `tableMergePrimary-${productInPostStore.idCurrentTable}`,
      //     dataFull,
      // );
      StorageService.setObjectStore(
        `tableMergePrimary-${result.body?.merge_dining_tables_name}`,
          dataFull,
      );

      productInPosCartStore.dataListProInCart = dataFull


      // const newCartItem = {
      //   ...productInPosCartStore.dataFormAddCart,
      //   variant: {
      //     ...productInPosCartStore.dataFormAddCart.variant,
      //     id: selectedVariant.id || null,
      //     name: selectedVariant.attribute_compare,
      //   },
      //   product_id: selectedVariant.product_id,
      //   discount: selectedVariant.discount || 0,
      //   productName: productInPosCartStore.dataDetailProduct.title,
      //   price: selectedVariant.price,
      //   unit: selectedVariant.unit,
      //   sku: selectedVariant.sku,
      //   row_id: "",
      //   row_id_toAddDel: "",
      // };

      toastUtils.success("Gộp bàn thành công !");
      productInPostStore.getDataInfoTable()
      this.closeModal();
      productInPostStore.isModalVisible = false;
    } else {
      toastUtils.error("Đã có lỗi xảy ra", "");
    }
  }

  setDiningTableId(id: string) {
    this.diningTableId = id;
  }

  setSelectedTableId(id: string) {
    this.selectedTableId = id;
  }

  closeModal() {
    this.isModalOpen = false;
    this.isOpenConfirm = false
  }

  openModal() {
    this.isModalOpen = true;
  }
}

export const combineTableStore = new CombineTableStore();
