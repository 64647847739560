import React, { useEffect, useState } from "react";
import "./AddEditCustomer.scss";
import { Form, Input, Modal } from "antd";
import { productInPostStore, TFormCustomer } from "../../../ProductInPostStore";
import { observer } from "mobx-react";
import SelectCustom from "../../../../../../shared/components/form/SelectCustom";
import { toJS } from "mobx";
import StorageService from "../../../../../../common/service/StorageService";
import { useParams } from "react-router-dom";


const AddEditCustomer = () => {
  const [form] = Form.useForm();
  const [dataProvince, setDataProvince] = useState<any>([]);
  const dataProviceLC = StorageService.getArrayFromLS("dataProvince")
  const {posId} = useParams()
  useEffect(() => {
    if (dataProviceLC.length <= 0) {
      productInPostStore.getProvinces();
    } else {
      setDataProvince(dataProviceLC.map((item: any) => ({ id: item.id, name: item.name })));
    }
  }, [dataProviceLC.length]);

  useEffect(() => {
    if (productInPostStore.listProvince.length > 0) {
      StorageService.saveArayLS("dataProvince", productInPostStore.listProvince);
      setDataProvince(productInPostStore.listProvince.map((item: any) => ({ id: item.id, name: item.name })));
    }
  }, [productInPostStore.listProvince]);


  useEffect(()=>{
    productInPostStore.getDetailMachinePos(posId as string)
  },[posId])


    const dataDistricts = Object.values(productInPostStore.listDistrict).length > 0 ? Object.values(productInPostStore.listDistrict).map(district => ({
      id: district.id,
      name: district.name
    })): [];

    const dataWards = Object.values(productInPostStore.listWard).length > 0 ? Object.values(productInPostStore.listWard).map(ward => ({
      id: ward.id,
      name: ward.name
    })): [];

    useEffect(()=>{
      if(productInPostStore.typeModal === "edit"){
        productInPostStore.getDistrict(productInPostStore.dataFormCustomer.province_id as string) // chưa có id city
        productInPostStore.getWard(productInPostStore.dataFormCustomer.district_id as string)
      }
    },[productInPostStore.dataFormCustomer.district_id,productInPostStore.dataFormCustomer.province_id])

    useEffect(()=>{
      form.setFieldsValue({
        name: productInPostStore.dataFormCustomer.name,
        phone: productInPostStore.dataFormCustomer.phone,
        email: productInPostStore.dataFormCustomer.email,
        province_id: productInPostStore.dataFormCustomer.province_id,
        district_id: productInPostStore.dataFormCustomer.district_id,
        ward_id: productInPostStore.dataFormCustomer.ward_id,
        address: productInPostStore.dataFormCustomer.address,
        location_id: productInPostStore.dataFormCustomer.location_id,
      })
    },[form,productInPostStore.dataFormCustomer])

  return (
      <Modal
        title={`${
          productInPostStore.typeModal === "add"
            ? "Thêm khách hàng"
            : "Chi tiết khách hàng"
        }`}
        open={productInPostStore.isOpenModalAddEditCustomer}
        onCancel={() => {

          if(productInPostStore.typeModal === "edit" && productInPostStore.isHoldModal){
            return;
          }
          if (productInPostStore.typeModal === "edit") {
            productInPostStore.dataFormCustomer = {} as any;
            productInPostStore.listDistrict = [];
            productInPostStore.listWard = [];
            productInPostStore.listWard = [];
          }
          productInPostStore.typeModal = "add";
          // để giữ modal khi chưa add/update chưa thành công
          if (productInPostStore.isHoldModal) {
            productInPostStore.isOpenModalAddEditCustomer = true;
            productInPostStore.isOpenModalListCustomer = false;
          } else {
            productInPostStore.isOpenModalAddEditCustomer = false;
            productInPostStore.isOpenModalListCustomer = true;
          }
        }}
        footer={[
          <button
            key="ok"
            className={`custom-ok-button ${
              productInPostStore.isLoading ? "disabled-modal" : ""
            }`}
            type="submit"
            onClick={() => {
              form.submit();
            }}
          >
            {productInPostStore.typeModal === "add" &&
            !productInPostStore.isLoading
              ? "Thêm khách hàng"
              : productInPostStore.typeModal === "edit" &&
                !productInPostStore.isLoading
              ? "Lưu"
              : "Vui Lòng Chờ...."}
          </button>,
        ]}
        wrapClassName="ModalAddEditCustomer"
      >
        <Form
          form={form}
          onFinish={(values: any) => {
            productInPostStore.dataFormCustomer.name = values.name;
            productInPostStore.dataFormCustomer.address = values.address;
            productInPostStore.dataFormCustomer.email = values.email;
            productInPostStore.dataFormCustomer.phone = values.phone;
            productInPostStore.dataFormCustomer.location_id =
              productInPostStore.dataPosMachine.location_id;
            productInPostStore.typeModal === "add"
              ? productInPostStore.createCustomer(form)
              : productInPostStore.updateCustomer();
          }}
        >
          <Form.Item<TFormCustomer>
            className="item-form"
            label="Họ và tên"
            name="name"
            rules={[{ required: true, message: "Vui lòng nhập vào trường này" }]}
          >
            <Input placeholder="Vui lòng nhập" />
          </Form.Item>
          <div className="divider-col2">
            <Form.Item<TFormCustomer>
              className="item-form"
              label="Số điện thoại"
              name="phone"
              rules={[
                {
                  pattern: /^[0-9]+$/,
                  message: 'Vui lòng chỉ nhập số!',
                },
                {
                  required: true,
                  message: 'Vui lòng nhập số!',
                },
                {
                  min: 10,
                  message: 'Số điện thoại phải có ít nhất 10 số!',
                },            
                { required: true, message: "Vui lòng nhập vào trường này" },
              ]}
            >
              <Input placeholder="Vui lòng nhập"/>
            </Form.Item>
            <Form.Item<TFormCustomer>
              className="item-form"
              label="Email"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'Định dạng email không hợp lệ!',
                },
              ]}
            >
              <Input placeholder="Vui lòng nhập" />
            </Form.Item>
          </div>

          <div className="divider-col2">
            <Form.Item<TFormCustomer>
              className="item-form"
              label="Tỉnh/Thành phố"
              name="province_id"
            >
              <SelectCustom
                list={dataProvince && dataProvince}
                placeholder="Vui lòng chọn"
                showSearch
                onChange={(value: any) => {
                  productInPostStore.dataFormCustomer.province_id = value;
                  productInPostStore.getDistrict(
                    productInPostStore.dataFormCustomer.province_id as string
                  );
                  productInPostStore.dataFormCustomer.district_id = null;
                  productInPostStore.dataFormCustomer.ward_id = null;
                  productInPostStore.listDistrict = []
                  productInPostStore.listWard = []
                }}
                selected={() => {
                  // return productInPostStore.dataFormCustomer.province_id || null;
                  return dataProvince.find((item: any)=> item.id === productInPostStore.dataFormCustomer.province_id)?.id;
                }}
                getLabel={(item: any) => item.name}
              ></SelectCustom>
            </Form.Item>

            <Form.Item<TFormCustomer>
              className="item-form"
              label="Quận/Huyện"
              name="district_id"
            >
              <SelectCustom
                list={dataDistricts}
                placeholder="Vui lòng chọn"
                labelFirst="Vui lòng chọn"
                showSearch
                onChange={(value: any) => {
                  productInPostStore.dataFormCustomer.district_id = value;
                  productInPostStore.getWard(
                    productInPostStore.dataFormCustomer.district_id as string
                  );
                  productInPostStore.dataFormCustomer.ward_id = null;
                  productInPostStore.listWard = []
                }}
                selected={() => {
                  return  dataDistricts.find((item: any)=> item.id === productInPostStore.dataFormCustomer.district_id)?.id;

                }}
                getLabel={(item: any) => item.name}
              ></SelectCustom>
            </Form.Item>
          </div>
          <div className="divider-col2">
            <Form.Item<TFormCustomer>
              className="item-form"
              label="Phường/Xã"
              name="ward_id"
            >
              <SelectCustom
                list={dataWards}
                placeholder="Vui lòng chọn"
                showSearch
                onChange={(value: any) => {
                  productInPostStore.dataFormCustomer.ward_id = value;
                }}
                selected={() => {
                  return dataWards.find((item: any)=> item.id === productInPostStore.dataFormCustomer.ward_id)?.id
                }}
                getLabel={(item: any) => item.name}
              ></SelectCustom>
            </Form.Item>
            <Form.Item<TFormCustomer>
              className="item-form"
              label="Địa chỉ cụ thể"
              name="address"
            >
              <Input placeholder="Vui lòng nhập" />
            </Form.Item>
          </div>
        </Form>
      </Modal>
  );
};

export default observer(AddEditCustomer);

