import { message } from 'antd';
import {makeAutoObservable, toJS} from "mobx";
import {toastUtils} from "../../../common/utils/Toastutils";
import {productInPosCartStore} from "../productInPos/ProductInPosCartStore";
import {paymentService} from "./PaymentService";
import StorageService from "../../../common/service/StorageService";
import {HttpStatusCode} from "axios";
import {productInPostStore} from "../productInPos/ProductInPostStore";


class PaymentPosStore{
    isSave: boolean = false;
    isLoading: boolean = false;

    posid: string | undefined;
    roomid: string | null = "";
    tableid: string | undefined;
    diningTableSession: string | undefined;

    isModalNoteOpen: boolean = false
    isModalImageQrOpen: boolean = false
    priceTotal: number = 0 // Tổng tiền thanh toán(Số tiền hóa đơn ban đầu)
    priceTotalAfterPayment: number = 0 // Số tiền còn lại sau khi đã trừ đi tất cả các khoản thanh toán
    pricePayment: number = 0 // Tổng số tiền đã thanh toán qua các phương thức(tổng hợp từ listPayType)
    imageQr: string = '' // Ảnh Qr
    idPayTypeActive:number = 0; 
    listPayType: any = []
    notePos: string | null | undefined;
    imageBank: string | null | undefined ;
    constructor() {
        makeAutoObservable(this)
    }
    showModalNote = () => {
        this.isModalNoteOpen = true;
    };
    handleCancelNote = () => {
        this.isModalNoteOpen = false;
    };
    showModalImageQr = () => {
        this.isModalImageQrOpen = true;
    };
    handleCancelImageQr = () => {
        this.isModalImageQrOpen = false;
    };

    handleSetFileImageBank = (pathFile: string) => {
        this.imageBank = pathFile;
    };
    handleRemoveImageBank = () => {
        this.imageBank = '';
    };
    // Lưu từ store vào local
    savePriceToLocal = (posId:string, tableId:string) => {
        StorageService.setObjectStore(`dataCheckOut${posId}-${tableId}`, {
            checkOutPrice: this.priceTotal,
            remainingPrice: this.priceTotalAfterPayment,
        });
    }    

    //F5 laị lưu lại giá trị local vảo store
    loadPriceToStore = () => {
         const data = StorageService.getObjectStore(`dataCheckOut${this.posid}-${this.tableid}`);
        this.priceTotal = data?.checkOutPrice;
        this.priceTotalAfterPayment = data?.remainingPrice; 
    }

    /**
     *
     * @param type // 1: là tiền mặt. 2: là chuyển khoản
     * @description Thêm kiểu thanh toán
     */
    handlePushPayType = (type:number) => { // 1: là tiền mặt. 2: là chuyển khoản
        let idPayType = Date.now();
        const data = {
            id : idPayType,
            price: this.priceTotalAfterPayment,
            type: type,
            close: 1, // 1 là xóa hết 1 lần, 2 là xóa lần lượt(mới lần đầu tạo sẽ là 1 - thao tác phím sẽ xóa hết dữ liệu cũ và gán lại 2)
        }
        // Sẽ active luôn payType vừa bấm để thao tác
        this.idPayTypeActive = idPayType;
        this.listPayType.unshift(data)
        this.handlePricePayment()
    };
    /**
     *
     * @param id
     * @description Xóa kiểu thanh toán
     */
    handleRemovePayType = (id:number) => {
        this.listPayType  = this.listPayType.filter((item:any) => item.id !== id)
        this.handlePricePayment()
    };

    /**
     * @description Tổng tiền thanh toán
     */
    handlePricePayment = () => {
        this.pricePayment = 0
        this.listPayType.forEach((item:any) =>{
            this.pricePayment += item.price
        })
        if(this.priceTotal - this.pricePayment > 0){
 
            this.priceTotalAfterPayment = this.priceTotal - this.pricePayment;
        }else{
            this.priceTotalAfterPayment = 0;
        }
    }
    /**
     *
     * @param idPayType
     * @param numberPay
     * @description Thao tác bàn phím
     */
    handleNumber = (idPayType:number,numberPay:number) => {
        this.listPayType = this.listPayType.map((item:any) => {
            if(item.id === idPayType){
                if(item.close === 1){
                    item.price = numberPay;
                    item.close = 2;
                }else{
                    item.price = parseInt(String(item.price) + String(numberPay));
                }
            }
            return item;
        })
        this.handlePricePayment();
    }
    handleRemove = (idPayType:number) => {
        this.listPayType = this.listPayType.map((item:any) => {
            if(item.id === idPayType){
                item.price = 0;
                item.close = 2;
            }
            return item;
        })
        this.handlePricePayment();
    }

    async generateQr(){
        if(this.isSave === false){
            this.isSave = true
            var bank_amount = 0;
            this.listPayType.filter((item:any) => item.type === 2).map((item:any) => bank_amount += item.price)
            const params = {
                description: 'Thanh toán tiền',
                money: bank_amount
            }
            const result = await paymentService.fetchGenerateQr(params);
            this.isSave = false
            if (result.status === HttpStatusCode.Ok) {
                this.imageQr = result.body?.qr ? result.body.qr : ''
                this.showModalImageQr()
            } else{
                this.showModalImageQr()
            }
        }
    }

    async fetchSaveOrderPosEcommerce(navigate: (route: string) => void){
        if(this.isSave === false){
            this.isSave = true
            var bank_amount = 0;
            var cash_amount = 0;
            var customer_id =  productInPosCartStore?.dataCustomer?.id ? productInPosCartStore.dataCustomer.id : null

            this.listPayType.filter((item:any) => item.type === 1).map((item:any) => cash_amount += item.price)
            this.listPayType.filter((item:any) => item.type === 2).map((item:any) => bank_amount += item.price)

            const params = {
                type: "default_order",
                dining_table_session: this.diningTableSession,
                pos_id: this.posid,
                dining_table_data: this.diningTableSession,
                session_data: {
                    image_bank: this.imageBank,
                    customer_id:customer_id,
                    bank_amount: bank_amount,
                    cash_amount: cash_amount,
                    wallet_amount: 0,
                },
            }
            const result = await paymentService.fetchSaveOrderFnb(params);
            this.isSave = false
            if (result.status === HttpStatusCode.Ok) {
                StorageService.removeLocalStore(`tableMergePrimary-${this.tableid}`)
                StorageService.removeLocalStore(`cartPos${this.posid}-${this.tableid}`)
                StorageService.removeLocalStore(`cartPosOrigin${this.posid}-${this.tableid}`)
                StorageService.removeLocalStore(`dataCheckOut${this.posid}-${this.tableid}`);
                StorageService.removeLocalStore(`customerPos${StorageService.getLocalStore("posID")}-${this.tableid}`)
                StorageService.removeLocalStore(`notePos${this.posid}-${this.tableid}`)
                // xóa các bàn merge với nhau khi thanh toán thành công
                productInPostStore.dataInfoTable?.tables_merge?.length > 0 && productInPostStore.dataInfoTable?.tables_merge.map((item: any)=> {
                    StorageService.removeLocalStore(`cartPos${this.posid}-${item.id}`)
                    StorageService.removeLocalStore(`cartPosOrigin${this.posid}-${item.id}`)
                })
                this.listPayType = []
                this.handleCancelImageQr()
                productInPosCartStore.dataCustomer = null
                this.notePos = ''
                productInPosCartStore.dataListProInCart = []
                productInPostStore.dataProduct = []
                navigate(`/pos/${this.posid}/thanh-cong/${result.body.id}`);
            }
            else if(result.body?.message?.exception === "cart_item_not_found"){
                toastUtils.error('Thanh toán món ăn bị lỗi!', '');
            } else if(result.body?.message?.exception === "cannot_sell_debt_without_customers") {
                toastUtils.error("Vui lòng  chọn khách hàng!", "");
            } else if (result. body?.message[0] === "the_session_dataimage_bank_is_required_when_session_databank_amount_is_greater_than_0") {
                toastUtils.error("Vui lòng chụp ảnh chuyển khoản", "")
            } else {
                toastUtils.error("Đã có lỗi xảy ra", "")
            }
        }

        // this.openModal = false;
    }
}

export const paymentPosStore = new PaymentPosStore()