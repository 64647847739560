import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Modal } from "antd";
import { productInPostStore } from "../../../ProductInPostStore";
import "./styles/modalOption.scss";
import {transferTableStore} from "../transferTable/TransferTableStore";
import {combineTableStore} from "../combineTable/CombineTableStore";
import CombineTable from "../combineTable/CombineTable";
import StorageService from "../../../../../../common/service/StorageService";
import {numberGuestStore} from "../numberGuests/NumberStore";
import {useNavigate, useParams} from "react-router-dom";
import {toastUtils} from "../../../../../../common/utils/Toastutils";
import {separationStore} from "../../../../separationOrder/separation/SeparationStore";
import { productInPosCartStore } from "../../../ProductInPosCartStore";
import ModalNoteCartItems from "../modalNoteCartItems/ModalNoteCartItems";

const ModalOption = () => {
    const navigate = useNavigate();
    const { posId } = useParams<{ posId: string }>();
    const { diningTableId } = useParams<{ diningTableId: string }>();
    const { floorId } = useParams<{ floorId: string }>();
    const { roomId } = useParams<{ roomId: string }>();

    useEffect(() => {
        if (diningTableId) {
            separationStore.dining_table_id = diningTableId;
        }
    }, [diningTableId]);
    const results = StorageService.getObjectStore(`cartPos${posId}-${diningTableId}`)

    const handleSplitOrderClick = () => {
        const diningTableSession = results?.dining_table_session;
        const items = results?.items;
        separationStore.diningTableSession = diningTableSession;
        if (!diningTableSession || !Array.isArray(items) || items.length === 0) {
            toastUtils.warning("Vui lòng thêm món!", "");
            return;
        }
        if (diningTableId) {
            localStorage.setItem("diningTableId", diningTableId);
        }
        productInPostStore.isModalVisible = false;
        navigate(`/pos/${posId}/tach-don/${floorId}/${roomId}/${diningTableId}/${diningTableSession}`);
    };

    const isEdited = results?.items?.some((item: any) => item.edited)


    return (
        <Modal
            open={productInPostStore.isModalVisible}
            footer={null}
            className="custom-modal"
            closeIcon={
                <img
                    src="/assets/icon/icon_closeWhite.svg"
                    width={25}
                    height={25}
                    alt="Close"
                    onClick={() => {
                        productInPostStore.isModalVisible = false;
                    }}
                />
            }
            onCancel={() => productInPostStore.isModalVisible = false}
            wrapClassName="ModalOption"
        >
            <div className="button-container">
                <div className="modal-button"
                     onClick={() => {
                         productInPostStore.isOpenModalListCustomer = true;
                     }}
                >
                    <img
                        src="/assets/icon/khachhang.svg"
                        alt="Khách hàng"
                    />
                    Khách hàng
                </div>
                <div className="modal-button"
                    onClick={() => {
                        productInPosCartStore.isOpenModalNoteCartItem =
                            true;
                    }}
                >
                    <img
                        src="/assets/icon/notes.svg"
                        alt="Ghi chú món"
                    />
                    Ghi chú món
                </div>
                <div className="modal-button"
                     onClick={() => {results?.dining_table_session ? numberGuestStore.modalOpen = true : toastUtils.warning("Vui lòng thêm món hoặc đặt bàn !")}}
                >
                    {!productInPostStore.dataInfoTable.dining_table_session ? "" : (
                        <div className="quantity-customer">{productInPostStore.dataInfoTable?.dining_table_session?.quantity_people_reality}</div>
                    )}
                    Khách
                </div>
                <div className="modal-button"
                     onClick={handleSplitOrderClick}
                >
                    <img
                        src="/assets/icon/tach_don.svg"
                        alt="Tách đơn"
                    />
                    Tách đơn
                </div>
                {productInPosCartStore.isOpenModalNoteCartItem && <ModalNoteCartItems/>}
                <div className="modal-button"
                     onClick={() => {
                         if (results?.dining_table_session) {
                             if (results?.merge_dining_tables_id === "") {
                                 transferTableStore.isModalOpen = true;
                             } else {
                                 toastUtils.warning("Bàn gộp không thể chuyển bàn !");
                             }
                         } else {
                             toastUtils.warning("Vui lòng thêm món hoặc đặt bàn !");
                         }
                     }}                >
                    <img
                        src="/assets/icon/switch-table.svg"
                        alt="Chuyển bàn"
                    />
                    Chuyển bàn
                </div>
                <div
                    className="modal-button"
                    onClick={() => {results?.dining_table_session && !isEdited ? combineTableStore.openModal() : results?.dining_table_session && isEdited ? toastUtils.warning("Vui lòng Order trước khi gộp bàn") : toastUtils.warning("Vui lòng thêm món hoặc đặt bàn !")}}
                >
                    <img
                        src="/assets/icon/gop_ban.svg"
                        alt="Gộp bàn"
                    />
                    Gộp bàn
                </div>
                {combineTableStore.isModalOpen && <CombineTable/>}
                <div className="modal-button" onClick={()=>{navigate(`/pos/${posId}/hoan-tien/${floorId}/${roomId}/${diningTableId}`)}}>
                    <img
                        src="/assets/icon/hoantien.svg"
                        alt="Hoàn tiền"
                    />
                    Hoàn tiền
                </div>
            </div>
        </Modal>
    );
};

export default observer(ModalOption);
