import React from "react";
import "../../styles/Sidebar.scss";
import { useNavigate } from "react-router-dom";
import { Menu, MenuProps } from "antd";
import { infoStore } from "../../../user/info/InfoStore";
import { observer } from "mobx-react";

type MenuItem = Required<MenuProps>["items"][number];

const Sidebar = ({ keyActive }: { keyActive: string }) => {
  const navigate = useNavigate();

  const versionPosWeb = "pos" as string;
  const versionPOS = infoStore.typePos as string;

  const items: MenuItem[] = [
    {
      key: "quan-tri-vien",
      label: "Quản trị viên",
      icon: (
        <img
          src={"/assets/icon/settings/admin.svg"}
          width={24}
          height={24}
          alt="img"
        />
      ),
    },
    {
      key: "khach-hang",
      label: "Khách hàng",
      icon: (
          <img
              src={"/assets/icon/settings/icon-Customer.svg"}
              width={24}
              height={24}
              alt="img"
          />
      ),
    },
    {
      type: "divider",
    },
    {
      key: "quyen",
      label: "Quyền hạn",
      icon: (
        <img
          src={"/assets/icon/settings/crown.svg"}
          width={24}
          height={24}
          alt="img"
        />
      ),
    },
    {
      type: "divider",
    },
    {
      key: "thanh-toan",
      label: "Thanh toán",
      icon: (
        <img
          src={"/assets/icon/settings/cardpayment.svg"}
          width={24}
          height={24}
          alt="img"
        />
      ),
    },
    {
      type: "divider",
    },
    {
      key: "chi-nhanh-cua-hang",
      label: "Địa điểm",
      icon: (
        <img
          src={"/assets/icon/settings/map.svg"}
          width={24}
          height={24}
          alt="img"
        />
      ),
    },
    {
      type: "divider",
    },
    // ...(versionPOS === "fnb" && versionPosWeb === "pos"
    //   ? 
      // [
          {
            key: "ban-hang",
            label: "Bán hàng",
            icon: (
              <img
                src={"/assets/icon/settings/bag.svg"}
                width={24}
                height={24}
                alt="img"
              />
            ),
            children: [
              { key: "tang-phong", label: "Tầng / Phòng" },
              { key: "ban-an", label: "Bàn Ăn" },
              { key: "may-pos", label: "Máy POS" },
            ],
          },
        // ]
      // : versionPOS === "ecommerce" && versionPosWeb === "pos"
      // ? [
      //     {
      //       key: "may-pos",
      //       label: "Máy POS",
      //       icon: (
      //         <img
      //           src={"/assets/icon/settings/bag.svg"}
      //           width={24}
      //           height={24}
      //           alt="img"
      //         />
      //       ),
      //     },
      //   ]
      // : []),
    {
      type: "divider",
    },
    {
      key: "cau-hinh-chung",
      label: "Cấu hình chung",
      icon: (
        <img
          src={"/assets/icon/settings/configCommon.svg"}
          width={24}
          height={24}
          alt="img"
        />
      ),
      children:
        versionPosWeb === "pos"
          ? [
              { key: "cau-hinh-mail", label: "Cấu hình mail" },
              // { key: "chinh-sua-mail", label: "Chỉnh sửa mail " },
            ]
          : [
              { key: "cau-hinh-mail", label: "Cấu hình mail" },
              { key: "bo-cong-thuong", label: "Bộ Công Thương" },
              { key: "thuong-mai-dien-tu", label: "Cấu hỉnh TMDT" },
            ],
    },
    ...(versionPosWeb === "pos"
      ? []
      : [
          {
            key: "chinh-sua-mail",
            label: "Chỉnh sửa mail",
            icon: (
              <img
                src={"/assets/icon/settings/bag.svg"}
                width={24}
                height={24}
                alt="img"
              />
            ),
          },
        ]),
    {
      type: "divider",
    },
    // {
    //   key: "tich-hop-crm",
    //   label: "Tích hợp Ommani CRM",
    //   icon: (
    //     <img
    //       src={"/assets/icon/settings/crmLogo.svg"}
    //       width={24}
    //       height={24}
    //       alt="img"
    //     />
    //   ),
    // },
  ];

  const onClick: MenuProps["onClick"] = (e) => {
    const { key } = e;

    const arrNav = [
      { key: "quan-tri-vien", url: "/cai-dat/quan-tri-vien" },
      { key: "khach-hang", url: "/cai-dat/khach-hang" },
      { key: "quyen", url: "/cai-dat/quyen" },
      { key: "thanh-toan", url: "/cai-dat/thanh-toan" },
      { key: "chi-nhanh-cua-hang", url: "/cai-dat/chi-nhanh-cua-hang" },
      { key: "chi-nhanh-cua-hang", url: "/cai-dat/chi-nhanh-cua-hang" },
      { key: "may-pos", url: "/pos/may-pos" },
      { key: "ban-an", url: "/pos/ban-an" },
      { key: "tang-phong", url: "/pos/tang-phong" },
      { key: "cau-hinh-mail", url: "/cai-dat/cau-hinh-mail" },
      { key: "tich-hop-crm", url: "/cai-dat/tich-hop-crm" },
    ];

    const findItem = arrNav.find((item) => item.key === key);

    if (findItem) {
      navigate(findItem.url);
      return;
    }
  };

  return (
    <div className="SidebarSettings">
      <Menu
        onClick={onClick}
        style={{ width: 256 }}
        // defaultSelectedKeys={["1"]}
        defaultOpenKeys={
          keyActive === "ban-an" ||
          keyActive === "tang-phong" ||
          keyActive === "may-pos"
            ? ["ban-hang"]
            : keyActive === "cau-hinh-mail"
            ? ["cau-hinh-chung"]
            : []
        }
        selectedKeys={[keyActive]}
        mode="inline"
        items={items}
      />
    </div>
  );
};

export default observer(Sidebar);
