
import {makeAutoObservable} from "mobx";

class ConfirmStore {
    isOpenModal: boolean = false;
    isLoadingBtn: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

}

export const confirmStore = new ConfirmStore();