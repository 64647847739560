import { loginStore } from './../../../../../login/LoginStore';
import { HttpStatusCode } from "axios";
import { makeAutoObservable, toJS } from "mobx";
import { toastUtils } from "../../../../../../common/utils/Toastutils";
import { transferTableService } from "./TransferTableService";
import {productInPostStore} from "../../../ProductInPostStore";
import StorageService from "../../../../../../common/service/StorageService";
import {confirmStore} from "./confirm/ConfirmStore";


class TransferTableStore {
    listAllDiningTables: { id: string; name: string }[] = [];
    diningTableId = "";
    posId = "";
    isModalOpen: boolean = false;
    selectedTableId: string | null = null;
    isLoadingBtn: boolean = false;
    tableSwitch: any[] = [];
    itemSelectedSwitch: any[] = []; 

    staticDatatable: {
        locationId: string;
        posId: string
        dining_table_session: string;
    } = {
        locationId:"",
        posId: "",
        dining_table_session: ""

    };

    constructor() {
        makeAutoObservable(this);
    }

    async fetchAllDiningTablesList(forceUpdate = false) {
        if (this.listAllDiningTables.length > 0 && !forceUpdate) return;

        const location_id = productInPostStore.dataInfoTable?.location_id;
        const pos_id = this.staticDatatable.posId;

        const result = await transferTableService.fetchListTable({
            current_table_id: this.diningTableId,
            location_id: location_id,
            pos_id: pos_id,
            status_id: "1"
        });

        if (result.status === HttpStatusCode.Ok) {
            this.listAllDiningTables = result.body;
        } else {
            toastUtils.error("Đã có lỗi xảy ra!", "");
        }
    }

    async switchTable(navigate: (route: string) => void) {
        if(this.isLoadingBtn === false) {
            this.isLoadingBtn = true;
            if (!this.selectedTableId) return;

            const result = await transferTableService.switchTable({
                dining_table_session: StorageService.getObjectStore(`cartPos${this.posId}-${this.diningTableId}`)?.dining_table_session,
                dining_new: this.selectedTableId
            });

            if (result.status === HttpStatusCode.Ok) {
                toastUtils.success("Chuyển bàn thành công!", "");
                this.isLoadingBtn = false;
                this.closeModal();
                confirmStore.isOpenModal = false;
                productInPostStore.isModalVisible = false;
                // Nếu bàn lẻ thì floor_room_id = tầng,floor_room_parent_id = phòng
                // Nếu không phải bàn lẻ floor_room_id = phòng,floor_room_parent_id = tầng
                navigate(`/pos/${this.posId}/san-pham/${this.tableSwitch[0]?.floor_room_parent_id ? this.tableSwitch[0]?.floor_room_parent_id : this.tableSwitch[0]?.floor_room_id}/${this.tableSwitch[0]?.floor_room_parent_id ? this.tableSwitch[0]?.floor_room_id : 0}/${this.tableSwitch[0]?.id}`);
                StorageService.removeLocalStore(`cartPos${this.posId}-${this.diningTableId}`);
            } else {
                toastUtils.error("Đã có lỗi xảy ra!", "");
            }
        }
    }

    setDiningTableId(id: string) {
        this.diningTableId = id;
    }

    setSelectedTableId(id: string) {
        this.selectedTableId = id;
    }

    setPosId(id: string) {
        this.posId = id;
        this.staticDatatable.posId = id;
    }

    closeModal() {
        this.isModalOpen = false;
        this.selectedTableId = null;
    }


    // openModal() {
    //     const diningTableSession = productInPostStore.dataInfoTable?.dining_table_session?.dining_tables_session;
    //
    //     if (diningTableSession && diningTableSession !== "undefined") {
    //         this.isModalOpen = true;
    //     } else {
    //         toastUtils.error("Vui lòng thêm món hoặc đặt bàn!", "");
    //         this.isModalOpen = false;
    //     }
    // }
}

export const transferTableStore = new TransferTableStore();


