import { Button, Skeleton } from "antd";
import HeaderProductInPos from "../productInPos/components/headerProductInPos/HeaderProductInPos";
import "./styles/SpacesFloorRoomTable.scss";
import { observer } from "mobx-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { spacesFloorRoomTableStore } from "./SpacesFloorRoomTableStore";
import CreateFloorRoomModal from "./CreateFloorRoomModal";
import CreateTableModal from "./CreateTableModal";
import Loading from "../../../shared/components/loading/Loading";
import StorageService from "../../../common/service/StorageService";
import { dataStatusTable } from "../../../common/constants/Constants";
import { toJS } from "mobx";
import { productInPostStore } from "../productInPos/ProductInPostStore";

const SpacesFloorRoomTable = () => {
    const { posId } = useParams<{ posId: string }>();
    const { floorId } = useParams<{ floorId: any }>();
    const { roomId } = useParams<{ roomId: any }>();
    spacesFloorRoomTableStore.posIdUrl = posId as string
    spacesFloorRoomTableStore.roomIdUrl = roomId
    spacesFloorRoomTableStore.floorIdUrl = floorId
    const navigate = useNavigate();
    const location = useLocation();
    spacesFloorRoomTableStore.queryParams.pos_id = `${posId}`;
    const getDataUser = StorageService.getObjectStore("data-user");

    useEffect(()=>{
        productInPostStore.getDetailMachinePos(posId as string)
      },[posId])

    useEffect(()=>{
        // chỉ gọi 1 lần khi mới vào
        spacesFloorRoomTableStore.getListFloors()
        spacesFloorRoomTableStore.loadStoreIDFromLocal();
    },[])


    useEffect(() => {
        if (
          !productInPostStore.isLoadingDetailPos &&
          !productInPostStore.dataPosMachine?.is_working
        ) {
          navigate("/danh-sach-pos");
        }
      }, [
        productInPostStore.dataPosMachine,
        productInPostStore.isLoadingDetailPos,
      ]);

    // console.log("listAllFloors: ", toJS(spacesFloorRoomTableStore.listAllFloors));
    // console.log("floorID: ", toJS(spacesFloorRoomTableStore.floorId));
    // console.log("roomID: ", toJS(spacesFloorRoomTableStore.roomId));
    // console.log("listDetailFloor: ", toJS(spacesFloorRoomTableStore.listDetailFloor));
    // console.log("this.listAllRoomPAGE: ", toJS(spacesFloorRoomTableStore.listAllRoom));
    // console.log("listTableOfFloor: ", toJS(spacesFloorRoomTableStore.listTableOfFloor));
    // console.log("listTableOfRoom: ", toJS(spacesFloorRoomTableStore.listTableOfRoom));
    // console.log("listTableOfFloorRoom2: ", toJS(spacesFloorRoomTableStore.listTableOfFloorOrRoom));
    // console.log("roomId,floorId URL: ", spacesFloorRoomTableStore.roomIdUrl , spacesFloorRoomTableStore.floorIdUrl);
    
    return (
        <>
            <div className="SpacesFloromtab-container">
                <HeaderProductInPos search={false}>
                    {getDataUser.super_admin === 1 || getDataUser.super_admin === "1" ? (
                        <>
                        <Button
                            className="custom-btn btn-floorroom"
                            type="primary"
                            onClick={() =>
                                spacesFloorRoomTableStore.showModalFloorRoom()
                            }
                        >
                            Thêm mới tầng/phòng
                        </Button>
                        {spacesFloorRoomTableStore.listAllFloors.length === 0 ? (
                            ""
                        ) : (
                            <Button
                                className="custom-btn btn-table"
                                type="primary"
                                onClick={() =>
                                    spacesFloorRoomTableStore.showModalTable()
                                }
                            >
                                Thêm mới bàn ăn
                            </Button>
                        )}

                        </>
                    ) : ("")}
                </HeaderProductInPos>
                <div
                    className="SpacesFloromtab-body d-flex align-items-center flex-column"
                    style={{
                        backgroundColor:
                            spacesFloorRoomTableStore.listAllFloors.length === 0
                                ? "#F4F6F9"
                                : "white",
                        justifyContent:
                            spacesFloorRoomTableStore.listAllFloors.length === 0
                                ? "center"
                                : "initial",
                    }}
                >
                    {spacesFloorRoomTableStore.isLoadingFloor ? (
                        <div className="loading">
                            <Loading />
                        </div>
                    ) : spacesFloorRoomTableStore.listAllFloors.length === 0 ? (
                        <>
                            <p className="textNoFloRomTab">
                                Oops!
                                <br /> Không có tầng nào. Không có bàn nào. Thêm
                                một bàn mới để bắt đầu.
                            </p>
                            <img
                                className="noFloRomTab"
                                src="/assets/images/dining_null.webp"
                                alt=""
                            />
                        </>
                    ) : (
                        <>
                            <div className="listFloors-container">
                                {spacesFloorRoomTableStore.listAllFloors?.map(
                                    (item: any, index: any) => {
                                        return (
                                            <>
                                                <div
                                                    key={index}
                                                    className="itemFloors"
                                                    style={{
                                                        flex: "1",
                                                        backgroundColor:
                                                            Number(spacesFloorRoomTableStore.floorId) ===
                                                            item.id
                                                                ? "#0E72ED"
                                                                : "",
                                                    }}
                                                    onClick={() => {
                                                        if (
                                                            spacesFloorRoomTableStore.floorId ===
                                                            item.id
                                                        ) {
                                                            return; // Không thực hiện gì nếu floorID đã bằng với item.id
                                                        }
                                                        navigate(
                                                            `/pos/${posId}/tang-phong/${item.id}`,{replace: true}
                                                        );
                                                    
                                                        // xử lý tương tự khi gọi getListFloors nhưng ở đây là chọn để thay đổi
                                                        spacesFloorRoomTableStore.floorIdChoose = item.id
                                                        const filteredData = spacesFloorRoomTableStore.listAllFloors.find(item => item.id === spacesFloorRoomTableStore.floorIdChoose);
                                                        spacesFloorRoomTableStore.listDetailFloor = filteredData
                                                        spacesFloorRoomTableStore.listAllRoom = filteredData.rooms
                                                        spacesFloorRoomTableStore.floorId = item.id

                                                        if(filteredData?.odd_table?.length > 0 ){ // có bàn lẻ
                                                            spacesFloorRoomTableStore.listTableOfRoom = []
                                                            spacesFloorRoomTableStore.roomId = "0"
                                                            spacesFloorRoomTableStore.getTableOfFloors()
                                                            navigate(
                                                                `/pos/${posId}/tang-phong/${spacesFloorRoomTableStore.floorIdChoose}/0`,
                                                                { replace: true }
                                                            );
                                                        }else{ // không có bàn lẻ
                                                            spacesFloorRoomTableStore.listTableOfFloor = []
                                                            spacesFloorRoomTableStore.roomId = filteredData.rooms?.[0]?.id
                                                            spacesFloorRoomTableStore.getTableOfRoom()
                                                            filteredData.rooms?.[0]?.id && navigate(
                                                                `/pos/${posId}/tang-phong/${spacesFloorRoomTableStore.floorIdChoose}/${filteredData.rooms?.[0]?.id}`,
                                                                { replace: true }
                                                            );
                                                        }
                                                    }}
                                                   
                                                >
                                                    <span
                                                        style={{
                                                            color:
                                                                Number(spacesFloorRoomTableStore.floorId) ===
                                                                item.id
                                                                    ? "white"
                                                                    : "#061020",
                                                        }}
                                                    >
                                                        {item.name}
                                                    </span>
                                                </div>
                                            </>
                                        );
                                    }
                                )}
                            </div>

                            <div
                                className="listRoom-container"
                                style={{
                                    backgroundColor: "#F4F6F9",
                                }}
                            >
                           
                           {spacesFloorRoomTableStore.listDetailFloor?.odd_table?.length > 0 &&
                                                  <div
                                                      className="itemRooms"
                                                    
                                                      onClick={() => {
                                                          if (
                                                          
                                                                spacesFloorRoomTableStore.roomId === "0"
                                                          ) {
                                                              return; // Không thực hiện gì nếu roomID đã bằng với item.id
                                                          }
                                                     
                                                          spacesFloorRoomTableStore.roomId = "0";
                                                        //   spacesFloorRoomTableStore.saveRoomID();
                                                          navigate(
                                                              `/pos/${posId}/tang-phong/${spacesFloorRoomTableStore.floorId}/0`
                                                          );

                                                          spacesFloorRoomTableStore.getTableOfFloors();

                                                          
                                                      }}
                                                  >
                                                      <span
                                                          style={{
                                                              background:
                                                                 spacesFloorRoomTableStore.roomId 
                                                                   === "0"
                                                                      ? "rgb(14, 114, 237)"
                                                                      : "#e6e9e8",
                                                              color:
                                                                spacesFloorRoomTableStore.roomId  === "0"
                                                                      ? "white"
                                                                      : "#061020",
                                                          }}
                                                      >
                                                          Bàn lẻ
                                                      </span>
                                                  </div>
                           }
                                    {/* All Phòng */}
                                {spacesFloorRoomTableStore.listAllRoom?.length > 0 && spacesFloorRoomTableStore.listAllRoom.map(
                                          (item: any, index: number) => {
                                              return (
                                                  <>
                                                      <div
                                                          className="itemRooms"
                                                          key={index}
                                                          onClick={() => {
                                                            //   spacesFloorRoomTableStore.roomID =
                                                            //       item.id;

                                                            if (
                                                          
                                                                spacesFloorRoomTableStore.roomId === item.id
                                                          ) {
                                                              return; // Không thực hiện gì nếu roomID đã bằng với item.id
                                                          }

                                                              navigate(
                                                                  `/pos/${posId}/tang-phong/${spacesFloorRoomTableStore.floorId}/${item.id}`
                                                              );
                                                            //   spacesFloorRoomTableStore.saveRoomID();
                                                            //   spacesFloorRoomTableStore.getTableOfRoom();
                                                            spacesFloorRoomTableStore.roomId =
                                                            item.id;
                                                              spacesFloorRoomTableStore.getTableOfRoom();
                                                          }}
                                                      >
                                                          <span
                                                              style={{
                                                                  background:
                                                                      Number(spacesFloorRoomTableStore.roomId) === 
                                                                    item.id
                                                                          ? "rgb(14, 114, 237)"
                                                                          : "#e6e9e8",
                                                                  color:
                                                                      Number(spacesFloorRoomTableStore.roomId) === 
                                                                    item.id
                                                                          ? "white"
                                                                          : "#061020",
                                                              }}
                                                          >
                                                              {item.name}
                                                          </span>
                                                      </div>
                                                  </>
                                              );
                                          }
                                      )}
                            </div>

                            <div
                                className="listTable-container"
                                style={{
                                    backgroundColor: "#F4F6F9",
                                    justifyContent:
                                        spacesFloorRoomTableStore
                                            .listTableOfFloorOrRoom.length === 0 && !spacesFloorRoomTableStore.isLoadingTable
                                            ? "center"
                                            : "normal",
                                    alignItems:
                                        spacesFloorRoomTableStore
                                            .listTableOfFloorOrRoom.length === 0 && !spacesFloorRoomTableStore.isLoadingTable
                                            ? "center"
                                            : "normal",
                                    pointerEvents: spacesFloorRoomTableStore.isLoadingTable ? "none" : "unset"
                                }}
                            >
                                {
                                    spacesFloorRoomTableStore.isLoadingTable ? 
                                
                                <div className="box-loadingTable ">
                                    <div className="item-loadTable">
                                        <Skeleton active />
                                        {/* <div className="item-loadTable__body">
                                            <Skeleton.Input active  />
                                            <Skeleton.Button active className="d-flex justify-content-center" />
                                        </div> */}
                                    </div>
                                    <div className="item-loadTable">
                                        <Skeleton active />
                                    </div>
                                    <div className="item-loadTable">
                                        <Skeleton active />
                                    </div>
                                </div>
                                :
                                spacesFloorRoomTableStore.listTableOfFloorOrRoom
                                      .length === 0 ? (
                                    <div className="noTable">
                                        <p className="textNoFloRomTab">
                                            Oops!
                                            <br />
                                            Không có bàn nào. Thêm một bàn mới
                                            để bắt đầu.
                                        </p>
                                        <img
                                            className="noFloRomTab"
                                            src="/assets/images/dining_null.webp"
                                            alt=""
                                        />
                                    </div>
                                ) : (
                                    spacesFloorRoomTableStore.listTableOfFloorOrRoom.map(
                                        (item: any, index: number) => {
                                            return (
                                                <>
                                                    <div
                                                        key={index}
                                                        className="itemTable"
                                                        style={{
                                                            backgroundColor:
                                                                !item.dining_tables_session ||
                                                                item
                                                                    .dining_tables_session
                                                                    ?.dining_tables_status ===
                                                                    3
                                                                    ? "#BABABA"
                                                                    : item
                                                                          .dining_tables_session
                                                                          ?.dining_tables_status ===
                                                                      2
                                                                    ? "#F97414"
                                                                    : "#32C564",
                                                        }}
                                                        onClick={() => {
                                                            // Nếu bàn lẻ thì floor_room_id = tầng,floor_room_parent_id = phòng
                                                            // Nếu không phải bàn lẻ floor_room_id = phòng,floor_room_parent_id = tầng
                                                            navigate(
                                                                `/pos/${posId}/san-pham/${item.floor_room_parent_id ? item.floor_room_parent_id : item.floor_room_id}/${item.floor_room_parent_id ? item.floor_room_id : 0}/${item.id}`
                                                            );
                                                            StorageService.setLocalStore(`dining_table_session/posId${posId}`, item.dining_tables_session?.dining_tables_session)
                                                            StorageService.setLocalStore("roomID", item.floor_room_parent_id === 0 ? 0 : item.floor_room_id)
                                                            const dataCartFNB =
                                                            //   StorageService.getObjectStore(
                                                            //     `cartPos${posId}/cartRoom${
                                                            //         idFloorRoom
                                                            //     }/cartTable${
                                                            //       item.id
                                                            //     }`
                                                            //   );
                                                              StorageService.getObjectStore(
                                                                `cartPos${posId}-${
                                                                  item.id
                                                                }`
                                                              );

                                                            // Nếu chưa có trong local thì khởi tạo mặc định
                                                            // Nếu roomID = 0 (là bàn lẻ không có id bàn cha) gán là ""
                                                            if (!dataCartFNB) {
                                                                StorageService.setObjectStore(`cartPos${posId}-${item.id}`,  {
                                                                    dining_table_status: "",//"0",
                                                                    dining_table_session: "",
                                                                    merge_dining_tables_id: "",
                                                                    items: []
                                                                })
                                                            }
                                                        }}
                                                        title={`${item.name}`}
                                                    >
                                                        {!item
                                                            .dining_tables_session
                                                            ?.tmp_order_item
                                                            ?.length ? (
                                                            ""
                                                        ) : (
                                                            <p className="orderTable mb-0">
                                                                {
                                                                    item
                                                                        .dining_tables_session
                                                                        ?.tmp_order_item
                                                                        ?.length
                                                                }
                                                            </p>
                                                        )}
                                                        {!item.dining_tables_session ? (
                                                            ""
                                                        ) : item
                                                              .dining_tables_session
                                                              ?.start_time_working ? (
                                                            <span className="timeOpenTable d-flex align-items-center justify-content-center">
                                                                <img
                                                                    src="/assets/icon/clock_dining.svg"
                                                                    alt=""
                                                                />
                                                                {Math.floor(
                                                                    (Date.now() -
                                                                        new Date(
                                                                            item.dining_tables_session?.start_time_working
                                                                        ).getTime()) /
                                                                        (1000 *
                                                                            60)
                                                                )}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <span className="title">
                                                            {item.name}
                                                        </span>
                                                        {!item.dining_tables_session ? (
                                                            ""
                                                        ) : !item
                                                              .dining_tables_session
                                                              ?.merge_dining_tables_name ? (
                                                            ""
                                                        ) : (
                                                            <span className="mergeTables d-flex align-items-center justify-content-center">
                                                                <img
                                                                    src="/assets/icon/merge_tables_left.svg"
                                                                    alt=""
                                                                />
                                                                {
                                                                    item
                                                                        .dining_tables_session
                                                                        ?.merge_dining_tables_name
                                                                }
                                                                <img
                                                                    src="/assets/icon/merge_tables_right.svg"
                                                                    alt=""
                                                                />
                                                            </span>
                                                        )}
                                                        <div className="quantity_people">
                                                            <span
                                                                style={{
                                                                    color: "white",
                                                                    fontWeight:
                                                                        "600",
                                                                    padding:
                                                                        "4px 12px",
                                                                    borderRadius:
                                                                        "4px",
                                                                    backgroundColor:
                                                                        "rgba(109, 114, 124, 0.2)",
                                                                }}
                                                            >
                                                                {item.dining_tables_session
                                                                    ? item
                                                                          .dining_tables_session
                                                                          ?.quantity_people_reality
                                                                    : "0"}
                                                                /
                                                                {
                                                                    item.quantity_people
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        }
                                    )
                                )}
                            </div>
                        </>
                    )}
                </div>
                <CreateFloorRoomModal />
                <CreateTableModal />
            </div>
        </>
    );
};

export default observer(SpacesFloorRoomTable);
