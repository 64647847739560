import React, { useEffect } from 'react';
import { observer } from "mobx-react";
import HeaderSeparation from "./components/headerSeparation/HeaderSeparation";
import "./styles/separation.css";
import { Button, Skeleton } from "antd";
import { DoubleLeftOutlined, RightOutlined } from "@ant-design/icons";
import { separationStore } from "./SeparationStore";
import { toastUtils } from "../../../../common/utils/Toastutils";
import { useNavigate, useParams } from "react-router-dom";
import { utils } from "../../../../common/utils/Utils";


const SeparationPage = () => {
    const navigate = useNavigate();
    const { diningTableSession, posId, diningTableId, floorId, roomId } = useParams<{ diningTableSession: string, posId: string, diningTableId:string, floorId:string, roomId:string }>();
    separationStore.diningTableSession = diningTableSession?.toString();
    separationStore.posid = posId?.toString();
    separationStore.dining_table_id = diningTableId?.toString();

    useEffect(() => {
        if (diningTableSession) {
            separationStore.diningTableSession = diningTableSession;
            separationStore.fetchCartItems();
        }
        if (posId) {
            separationStore.staticDataTableSession.pos_id = posId;
        }
    }, [diningTableSession]);

    const handleBackClick = () => {
        // const diningTableId =  StorageService.getObjectStore(`diningTableId`);
        if (posId && diningTableId) {
            navigate(`/pos/${posId}/san-pham/${floorId}/${roomId}/${diningTableId}`);
        }
    };

    const handlePayment = () => {
        if (separationStore.selectedItems.length === 0) {
            toastUtils.error('Vui lòng chọn sản phẩm cần thanh toán!', '');
            return;
        }
        separationStore.splitOrder(separationStore.staticDataTableSession)
            .then((splitOrderSession: string) => {
                separationStore.staticDataTableSession.split_order_session = splitOrderSession;
                separationStore.savePaymentPriceToLocalStorage();
                separationStore.localPaymentPrice = 0;
                separationStore.selectedItems = [];
                navigate(`/pos/${posId}/thanh-toan/tach-don/${floorId}/${roomId}/${diningTableId}/${splitOrderSession}`);
            })
            .catch(error => {
                toastUtils.error('Thanh toán thất bại!', error.message || '');
            });
    };

    return (
        <div className="separation-container">
            <HeaderSeparation />
            <div className="separation-body">
                <div className="separation-title">
                    <div className="button-return" onClick={handleBackClick}>
                        <Button type="text">
                            <DoubleLeftOutlined />
                            <p>Quay lại</p>
                        </Button>
                    </div>
                    <div className="title-page">
                        Tách hoá đơn
                    </div>
                </div>
                <div className="separation-payment">
                    <div className="left-content">
                        {separationStore.isLoading ? (
                            Array.from({ length: 3 }).map((_, index) => ( // Render 5 items skeleton
                                <div className="item skeleton-item" key={index}>
                                    <div className="item-details">
                                        <div className="item-name">
                                        <Skeleton.Input style={{ width: '60%',height: '20px', marginBottom: '8px' }} active />
                                        </div>
                                        <div className="item-desc">
                                            <Skeleton.Input style={{ width: '30%',height: '20px', marginBottom: '8px' }} active />
                                        </div>
                                    </div>
                                    <div className="item-price">
                                        <Skeleton.Input style={{ width: '20%' }} active />
                                    </div>
                                </div>
                            ))
                        ) : (
                            separationStore.listCartItems.map((item, index) => (
                                item.tmp_order_status !== 2 && (
                                    <div
                                        className={`item ${separationStore.selectedItems.includes(index) ? 'selected' : ''}`}
                                        key={index}
                                        onClick={() => separationStore.toggleSelectItem(index)}
                                    >
                                        <div className="item-details">
                                            <div className="item-name">
                                                {item.product_name}
                                                {item.options && JSON.parse(item.options).attribute_compare ?
                                                    ` - ${JSON.parse(item.options).attribute_compare}` : ''}
                                            </div>
                                            <div className="item-desc">
                                                {item.qty} {JSON.parse(item.options).unit} x
                                                {item.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} đ/ {JSON.parse(item.options).unit}
                                            </div>
                                            {item.discount > 0 || String(item.discount).includes(".") ? (
                                                <p style={{ fontSize:"14px"}}>
                                                    Chiết khấu {item.discount}% với một sản phẩm
                                                </p>
                                            ) : (
                                                ""
                                            )}

                                            {/* Thêm đoạn mã xử lý note */}
                                            {item.note ? ( // Kiểm tra nếu item.note không null, undefined hoặc rỗng
                                                <p
                                                    style={{ display:"flex", gap:"5px"}}
                                                    className="boxProduct-note"
                                                >
                                                    <img
                                                        src="/assets/icon/noteProduct.svg"
                                                        alt="icon_note"
                                                    />
                                                    {item.note}
                                                </p>
                                            ) : null}

                                        </div>
                                        <div className="item-price">
                                            {((item.qty * item.price * (1 - (item.discount || 0) / 100))
                                                ?.toFixed(0) // Làm tròn
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' đ')}
                                        </div>
                                    </div>
                                )
                            ))
                        )}
                    </div>
                    <div className="right-content">
                        <div className="priceTotal">
                            {utils.formatCurrency(separationStore.localPaymentPrice)}
                        </div>
                        <div className="pay">
                            <Button type="text" onClick={handlePayment}>
                                <RightOutlined />
                                <p>{separationStore.isLoadingBtn
                                    ? "Vui lòng đợi..."
                                    : "Thanh toán"}</p>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(SeparationPage);
