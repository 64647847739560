import React, { useEffect, useState } from "react";
import HeaderProductInPos from "./components/headerProductInPos/HeaderProductInPos";
import "./styles/ProductInPosPage.scss";
import ProductInPosControl from "./components/productInPosControl/ProductInPosControl";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { productInPostStore } from "./ProductInPostStore";
import { utils } from "../../../common/utils/Utils";
import Loading from "../../../shared/components/loading/Loading";
import ModalDetailProduct from "./components/modal/modalDetailProduct/ModalDetailProduct";
import { productInPosCartStore } from "./ProductInPosCartStore";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { categoryProductStore } from "../../products/categoryProduct/CategoryProductStore";
import ReactPaginate from "react-paginate";
import NoContent from "../../../shared/components/NoContent";
import StorageService from "../../../common/service/StorageService";
import { dataStatusTable } from "../../../common/constants/Constants";
import { combineTableStore } from "./components/modal/combineTable/CombineTableStore";
import { Skeleton, Spin } from "antd";

const ProductInPosPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(window.location.search);
    const { diningTableId } = useParams();
    const { posId } = useParams();
    const { floorId } = useParams();
    const { roomId } = useParams();
    productInPostStore.idCurrentTable = diningTableId;
    combineTableStore.posId = posId?.toString();

    useEffect(() => {
        productInPostStore.getDataInfoTable();

        // Lưu lại giá trị khách hàng riêng của mỗi bàn
        const dataCustomerLocal = StorageService.getObjectStore(`customerPos${posId}-${diningTableId}`) || "";
        productInPosCartStore.dataCustomer = dataCustomerLocal;
    }, [diningTableId]);

    useEffect(() => {
        productInPostStore.dataFilterProduct.category_id = query.get(
            "category_id"
        )
            ? Number(query.get("category_id"))
            : "";
        query.get("type") === "parent" &&
            (categoryProductStore.staticParamCategoryProduct.parent_id =
                query.get("category_id"));
        query.get("type") === "child" &&
            (categoryProductStore.dataCategoryPro =
                StorageService.getArrayFromLS("arrProPos")); // chọn danh mục cha có những danh mục con nào thì lưu
    }, []);

    useEffect(() => {
        if (
            !productInPostStore.isLoadingDetailPos &&
            !productInPostStore.dataPosMachine?.is_working
        ) {
            navigate("/danh-sach-pos");
        }
    }, [
        productInPostStore.dataPosMachine,
        productInPostStore.isLoadingDetailPos,
    ]);

    useEffect(() => {
        query.get("type") !== "child" &&
            categoryProductStore.fetchCategoryProductParent();
    }, []);

    useEffect(() => {
        if (
            categoryProductStore.staticParamCategoryProduct.parent_id &&
            query.get("type") !== "child"
        ) {
            categoryProductStore.fetchListCategoryProduct(true);
        }
    }, [categoryProductStore.staticParamCategoryProduct.parent_id]);

    const updateUrlParams = (item: any) => {
        const searchParams = new URLSearchParams(location.search);

        if (productInPostStore.dataFilterProduct.category_id) {
            searchParams.set(
                "category_id",
                productInPostStore.dataFilterProduct.category_id
            );
            item?.category_id
                ? searchParams.set("type", "child")
                : searchParams.set("type", "parent");
        }

        searchParams.set("page", "1");
        productInPostStore.staticParam.page = 0;
        navigate({ search: searchParams.toString() }, { replace: true });
    };

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        productInPostStore.dataFilterProduct.category_id = query.get(
            "category_id"
        )
            ? Number(query.get("category_id"))
            : "";
        productInPostStore.staticParam.page = query.get("page")
            ? Number(query.get("page")) - 1
            : 0;
        productInPostStore.dataFilterProduct.search = query.get("search") || "";
        productInPostStore.getListProduct();
    }, [location.search]);

    const handleChangePage = (e: any) => {
        productInPostStore.staticParam.page = e.selected;
        const searchParams = new URLSearchParams(location.search);

        searchParams.set(
            "page",
            (productInPostStore.staticParam.page + 1).toString()
        );
        navigate({
            search: searchParams.toString(),
        });

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        // Chỉ chạy khi dữ liệu đã sẵn sàng
        if (
            productInPostStore.isLoadingDetailTable ||
            !productInPostStore.dataInfoTable
        )
            return;

        const localStorageKey = `cartPos${posId}-${diningTableId}`;
        const localStorageCartOrigin = `cartPosOrigin${posId}-${diningTableId}`; // để so sánh sự thay đổi trong giỏ hàng
        const resultsLocal = StorageService.getObjectStore(localStorageKey);

        const cartList = productInPostStore.dataInfoTable?.cart_list || [];
        const newCartItem = cartList.map((item: any) => ({
            dining_table_session: item.dining_table_session,
            discount: item.discount,
            note: item.note || "",
            price: item.price,
            productName: item.product_name,
            product_id: item.product_id,
            quantity: item.qty,
            row_id: String(item.rowId),
            sku: item.product_sku,
            tmp_order_status: String(item.tmp_order_status),
            unit: item?.options?.unit,
            variant: {
                id: item?.options?.variant?.id,
                name: item?.options?.variant?.attribute_compare || "",
            },
            idData: item?.id,
            edited: false
        }));
        

        const dataFull = {
            dining_table_session:
                productInPostStore.dataInfoTable?.dining_table_session
                    ?.dining_tables_session || "",
            merge_dining_tables_id: productInPostStore.dataInfoTable?.dining_table_session?.merge_dining_tables_name || "",
            dining_table_status: (() => {
                const session = productInPostStore.dataInfoTable?.dining_table_session;
                const status = productInPostStore.dataInfoTable?.dining_table_session?.dining_tables_status?.toString();
                const cartList = productInPostStore.dataInfoTable?.cart_list;

                if (!session) return ""; // Không có phiên hoặc bàn mới vừa tạo vào sẽ hiện Đặt bàn
                if (cartList?.length > 0 && status === dataStatusTable.order.toString() && !session.end_time_working) return dataStatusTable.order; // Nếu bàn đặt và thêm món và click order rồi
                if (cartList?.length > 0) return dataStatusTable.cart; // Bàn đã có món ăn nhưng chưa click order(đặt món) hoặc đặt bàn(start_booking)
                // if (cartList?.length > 0 && status === dataStatusTable.order.toString()) return dataStatusTable.order;
                if (session.end_time_working) {
                    if (status === dataStatusTable.order.toString() && cartList?.length > 1) return dataStatusTable.booked;
                    if (status === dataStatusTable.booked.toString()) return dataStatusTable.init;
                    if (status === dataStatusTable.order.toString()) return dataStatusTable.init;
                    if (status === dataStatusTable.cart.toString()) return dataStatusTable.init;
                } else {
                    if (status === dataStatusTable.booked.toString()) return dataStatusTable.booked
                }
                return dataStatusTable.booked; // Mặc định là đặt bàn
            })(),
            items: newCartItem,
        };

        const mergeDiningTablesId = resultsLocal?.merge_dining_tables_id;
        const mergeDiningTablesName =
            productInPostStore.dataInfoTable?.dining_table_session
                ?.merge_dining_tables_name;
    
        if (!resultsLocal || !resultsLocal.items?.length || productInPostStore.dataInfoTable?.dining_table_session?.end_time_working) {
            // nếu chưa có trong local và kết thúc phiên của bàn (bàn đã được thanh toán) thì lấy từ database
            StorageService.setObjectStore(localStorageKey, dataFull);
            StorageService.setObjectStore(localStorageCartOrigin, dataFull);
            productInPosCartStore.dataListProInCart = dataFull;
            // mặc định sẽ acitve sản phẩm đầu tiên
            productInPosCartStore.activeVariantProduct = dataFull?.items[0]?.idData;
            productInPosCartStore.row_id = dataFull?.items[0]?.row_id;
        } else if (
            // check mergeId trong local === trong db và có mảng merge tạo ra khi gộp bàn thành công theo id bàn chính thì lấy mảng trong merge trong local
            mergeDiningTablesId &&
            mergeDiningTablesId === mergeDiningTablesName &&
            StorageService.getObjectStore(
                `tableMergePrimary-${mergeDiningTablesId}`
            )
        ) {
            const primaryTableData = StorageService.getObjectStore(
                `tableMergePrimary-${mergeDiningTablesId}`
            );  
            StorageService.setObjectStore(localStorageKey, primaryTableData);
            StorageService.setObjectStore(localStorageCartOrigin, primaryTableData);
            productInPosCartStore.dataListProInCart = primaryTableData
        } else if (
            !StorageService.getObjectStore(
                `tableMergePrimary-${mergeDiningTablesId}`
            ) &&
            mergeDiningTablesId === mergeDiningTablesName
        ) {
            // check mergeId trong local === trong db và không mảng merge tạo ra (dành cho khi đăng xuất bị mất hết trong local) thì lấy data trong database
            StorageService.setObjectStore(localStorageKey, dataFull);
            StorageService.setObjectStore(localStorageCartOrigin, dataFull);
            productInPosCartStore.dataListProInCart = dataFull
        }
    }, [
        productInPostStore.isLoadingDetailTable,
        diningTableId,
        posId,
        productInPostStore.dataInfoTable,
    ]);

    return (
        <div className="ProductInPosPage-container">
            <HeaderProductInPos
                backPage={
                    <button
                        className="backToPage"
                        onClick={() => 
                            // navigate(-1)
                            navigate(`/pos/${posId}/tang-phong/${floorId}/${roomId}`)
                        }
                    >
                        <img src="/assets/icon/prev.svg" alt="back" />
                        Quay lại
                    </button>
                }
                refreshData={<button disabled={productInPostStore.isLoadingRefeshData} className={`refresh-data ${productInPostStore.isLoadingRefeshData ? "hasLoad" : ""} `} onClick={()=>{
                    StorageService.removeLocalStore(`tableMergePrimary-${productInPostStore.dataInfoTable?.dining_table_session?.merge_dining_tables_name}`)
                    StorageService.removeLocalStore(`cartPos${posId}-${diningTableId}`)
                    StorageService.removeLocalStore(`cartPosOrigin${posId}-${diningTableId}`)
                    productInPostStore.dataInfoTable?.tables_merge?.length > 0 && productInPostStore.dataInfoTable?.tables_merge.map((item: any)=> {
                        StorageService.removeLocalStore(`cartPos${posId}-${item.id}`)
                        StorageService.removeLocalStore(`cartPosOrigin${posId}-${item.id}`)
                    })
                     productInPostStore.getDataInfoTable(()=>{}, true);
                }}>{
                    productInPostStore.isLoadingRefeshData ? 
                    <Spin />
                        : <div className="refresh-data__content">
                            <img className="refresh-blue" src="/assets/icon/refresh.svg" alt="back" />
                            <img className="refresh-white" src="/assets/icon/refreshWhite.svg" alt="back" />
                            Làm mới dữ liệu
                        </div>
                }</button>}
            />
            <div className="ProductInPosPage-body">
                <div className="ProductInPosPage-left">
                    <div
                        className={`list-category ${
                            categoryProductStore.isLoading ? "disable" : ""
                        }`}
                    >
                        <div className="category_body">
                            <button
                                className={`${
                                    !productInPostStore.dataFilterProduct
                                        .category_id
                                        ? "active"
                                        : ""
                                }`}
                                onClick={() => {
                                    navigate({ search: "" }, { replace: true });
                                    categoryProductStore.fetchCategoryProductParent();
                                }}
                            >
                                Tất Cả
                            </button>
                            {categoryProductStore.dataCategoryPro.length > 0 &&
                                categoryProductStore.dataCategoryPro.map(
                                    (item) => (
                                        <div
                                            key={item.id}
                                            title={item.name}
                                            className={`category_item ${
                                                productInPostStore
                                                    .dataFilterProduct
                                                    .category_id === item.id
                                                    ? "active"
                                                    : ""
                                            } `}
                                            onClick={() => {
                                                productInPostStore.dataFilterProduct.category_id =
                                                    item.id;
                                                categoryProductStore.staticParamCategoryProduct.parent_id =
                                                    !item.category_id
                                                        ? item.id
                                                        : "";
                                                updateUrlParams(item);
                                            }}
                                        >
                                            {item.name}
                                        </div>
                                    )
                                )}
                        </div>
                    </div>
                    <>
                        <div className="listProduct">
                            {productInPostStore.isLoadingProduct ? (
                                <div className="h-100">
                                    <div className="listProduct-box">
                                        {Array.from({length: 6}).map((_, index) => (
                                            <div key={index} className="product-item skeleton-item">
                                                <div className="product-item__boxImg">
                                                    {/* <Skeleton.Avatar active shape="square" /> */}
                                                    <Skeleton.Image active className="boxImg-item"/>
                                                </div>
                                                <div className="product-item__body">
                                                    <Skeleton.Input
                                                        style={{
                                                            height: "20px",
                                                            marginBottom: "4px",
                                                            width: "100%",
                                                            // padding: "10px"
                                                        }}
                                                        className="custom-skeleton"
                                                        active
                                                    />
                                                    <Skeleton.Input style={{
                                                        height: "20px",
                                                        marginBottom: "8px",
                                                        width: "100%",
                                                        // padding: "10px"
                                                    }} active/>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : productInPostStore.dataProduct.length <= 0 ? (
                                <NoContent/>
                            ) : (
                                <div className="listProduct-box">
                                    {productInPostStore.dataProduct.length >
                                        0 &&
                                        productInPostStore.dataProduct.map(
                                            (item, index) => (
                                                <div
                                                    key={index}
                                                    className="product-item"
                                                    onClick={() => {
                                                        productInPosCartStore.isOpenModalDetailProduct =
                                                            true;
                                                        productInPosCartStore.dataDetailProduct =
                                                            item;
                                                    }}
                                                >
                                                    <div className="product-item__boxImg">
                                                        <img
                                                            src={
                                                                item.preview
                                                                    .value
                                                            }
                                                            alt="img"
                                                        />
                                                    </div>
                                                    <div className="product-item__body">
                                                        <h2
                                                            className="multiline-ellipsis"
                                                            title={item.title}
                                                        >
                                                            {item.title}
                                                        </h2>
                                                        <h3>
                                                            {utils.formatCurrency(
                                                                item.price_origin
                                                            )}
                                                        </h3>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                </div>
                            )}
                        </div>
                    </>
                    <div className="pagination-ProInPos">
                        {productInPostStore.metadata.total_page > 1 && (
                            <ReactPaginate
                                previousLabel={
                                    <img
                                        src="/assets/icon/fi-rr-angle-small-left.svg"
                                        alt="right"
                                    />
                                }
                                nextLabel={
                                    <img
                                        src="/assets/icon/fi-rr-angle-small-right.svg"
                                        alt="right"
                                    />
                                }
                                breakLabel={"..."}
                                pageCount={
                                    productInPostStore.metadata.total_page
                                }
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                onPageChange={handleChangePage}
                                forcePage={productInPostStore.staticParam.page}
                            />
                        )}
                    </div>
                </div>
                <ProductInPosControl />
            </div>
            <ModalDetailProduct></ModalDetailProduct>
        </div>
    );
};

export default observer(ProductInPosPage);
