import {makeAutoObservable, toJS} from "mobx";
import {HttpStatusCode} from "axios";
import {paymentSuccessService} from "./PaymentSuccessService";
import {toastUtils} from "../../../../common/utils/Toastutils";
class PaymentSuccessStore{
    isLoading: boolean = false
    transactionPosId: any
    dataTransaction: any
    diningTableSession: string = "";

    constructor() {
        makeAutoObservable(this)
    }

    async fetchTransactionDetail(navigate: (route: string) => void){
        this.isLoading = true;
        const result = await paymentSuccessService.fetchTransactionDetail(this.transactionPosId);
        const data = toJS(result).body.fnb_meta;
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            this.dataTransaction  = result.body
            this.diningTableSession = JSON.parse(data)?.dining_tables_session
        } else {
            navigate('danh-sach-pos')
            toastUtils.warning('Giao dịch không tồn tại', '');
        }

    }
}

export const paymentSuccessStore = new PaymentSuccessStore()