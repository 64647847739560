import React from "react";
import { Bar } from 'react-chartjs-2';
import {observer} from "mobx-react";
import {dashboardStore} from "../../DashboardStore";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Dropdown} from "react-bootstrap";
import { toJS } from "mobx";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
interface SaleChartProps {
    dataRevenue: any[];
    dataListStore: any[];
}
const SaleChart: React.FC<SaleChartProps> = ({ dataRevenue,dataListStore }) =>{
    const options = {
        plugins: {
            title: {
                display: true,
                text: '',
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },

    };
    const backgroundColors: { [key: number]: string } = {
        0 : '#0E72ED',
        1 : '#FF538A',
        2 : '#01BFAC',
        3 : '#FE9339',
        4 : '#FE5139',
        5 : '#9039FE',
        6 : '#D739FE',
        7 : '#39C3FE',
        8 : '#FF0D0D',
        9: '#51BD36',
    };

    let datasets:any;
    let labels: any[] = [];

    if(dashboardStore.typeSalesChart == 'date'){
        const start = new Date(dashboardStore.staticDataSalesChart.startDate);
        const end = new Date(dashboardStore.staticDataSalesChart.endDate);
        let labelsMap: any[] = [];
        let dataStore: any[] = []
        for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
            labelsMap.push(new Date(d).toISOString().split('T')[0]);
            const day = d.getDate();
            labels.push(day);
        }

        if(labelsMap.length > 31){
            labels = Array.from({ length: 31 }, (_, i) => i + 1);
        }
        
        datasets = dataListStore.map((item: any, index: number) => {

            if (labelsMap.length > 31) {
              dataStore = new Array(31).fill(0);

              dataRevenue.forEach(({ order_time, total_sum, store }) => {
                if (item.id === store) {
                  const dateIndex = new Date(order_time).getDate() - 1; // Lấy index dựa trên ngày
                  dataStore[dateIndex] += total_sum; // Cộng dồn total_sum vào vị trí tương ứng
                }
              });
            } else {
              dataStore = labelsMap.map((labelDate) => {
                const itemData = dataRevenue.find(
                  (data) =>
                    data.order_time === labelDate && item.id == data.store
                );
                return itemData ? +itemData.total_sum : 0;
              });
            }

            const backgroundColor: string = backgroundColors[index % 10] ?? '#0000FF';
            if(labels.length === 1){
                return {
                    label: item.name,
                    data: dataStore,
                    backgroundColor: backgroundColor,
                    barThickness: 100, // Custom width
                };
            }else{
                return {
                    label: item.name,
                    data: dataStore,
                    backgroundColor: backgroundColor,
                    barThickness: undefined,
                };
            }

        });
    }
    
    else if (dashboardStore.typeSalesChart == 'dayofweek'){
         datasets = dataListStore.map((item: any, index: number) => {
             var dataStoreT2:number = 0,dataStoreT3:number = 0,dataStoreT4:number = 0,dataStoreT5:number = 0,dataStoreT6:number = 0,dataStoreT7:number = 0,dataStoreCN:number = 0;
            dataRevenue.map((itemData: any) => {
                if(itemData.order_time == 1 && itemData.store == item.id){
                    dataStoreCN = + itemData.total_sum;
                }else if(itemData.order_time == 2 && itemData.store == item.id){
                    dataStoreT2 = + itemData.total_sum;
                }else if(itemData.order_time == 3 && itemData.store == item.id){
                    dataStoreT3 = + itemData.total_sum;
                }else if(itemData.order_time == 4 && itemData.store == item.id){
                    dataStoreT4 = + itemData.total_sum;
                }else if(itemData.order_time == 5 && itemData.store == item.id){
                    dataStoreT5 = + itemData.total_sum;
                }else if(itemData.order_time == 6 && itemData.store == item.id){
                    dataStoreT6 = + itemData.total_sum;
                }else if(itemData.order_time == 7 && itemData.store == item.id){
                    dataStoreT7 = + itemData.total_sum;
                }
            });
            const backgroundColor: string = backgroundColors[index % 10] ?? '#0000FF';
            return {
                label: item.name,
                data: [dataStoreT2,dataStoreT3,dataStoreT4,dataStoreT5,dataStoreT6,dataStoreT7,dataStoreCN],
                backgroundColor: backgroundColor,
            };
        });
        const weekName= ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'];
        labels = weekName;
    }else if (dashboardStore.typeSalesChart == 'hour'){
        datasets = dataListStore.map((item: any, index: number) => {
            const dataStores = new Array(24).fill(0);
            dataRevenue.forEach((itemData: any) => {
                if (itemData.store == item.id) {
                    const orderTime = itemData.order_time;
                    if (orderTime >= 0 && orderTime < 24) {
                        dataStores[orderTime] = +itemData.total_sum;
                    }
                }
            });
            const backgroundColor: string = backgroundColors[index % 10] ?? '#0000FF';
            return {
                label: item.name,
                data: dataStores.slice(0, 24),
                backgroundColor: backgroundColor,
            };
        });
        const weekName= ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'];
        labels = weekName;
    }
    const data = {
        labels,
        datasets: datasets
    };

    const listSalesChart = [
        {type:'date',value:'Theo ngày'},
        {type:'dayofweek',value:'Theo thứ'},
        {type:'hour',value:'Theo giờ'},
    ]

    return (
        <div id="saleChart">
            <div className="saleChartHeader">
                <p>DOANH THU</p>
                <div className="saleChartHeaderFilter">
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdownFitterSale">
                        <span
                            className="titleTime"
                            id="titleTime"
                        >
                            {listSalesChart.find((item:any) => item.type === dashboardStore.typeSalesChart)?.value}
                        </span>
                            <img
                                src="/assets/icon/fi-sr-caret-down.svg"
                                alt="fi-sr-caret-down"
                            />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {listSalesChart.map(
                                (item: any) => (
                                    <Dropdown.Item
                                        key={item.type}
                                        onClick={(event) => {
                                            dashboardStore.typeSalesChart = item.type
                                            dashboardStore.fetchDataRevenue()
                                        }}
                                    >
                                        {" "}
                                        {item.value}
                                    </Dropdown.Item>
                                )
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <ul>
                        {listSalesChart.map((item:any,index) => (
                            <li key={index}>
                                <button className={`item-type-sales' ${dashboardStore.typeSalesChart === item.type ? 'active' : ''}`}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            dashboardStore.typeSalesChart = item.type
                                            dashboardStore.fetchDataRevenue()
                                        }}
                                >
                                    {item.value}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="chart">
                <Bar data={data} options={options} />
            </div>
        </div>
    )
}
export default observer(SaleChart);