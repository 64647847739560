import { observer } from "mobx-react";
import FilterCategoryProduct from "./components/filterCategoryProduct/FilterCategoryProduct";
import FilterGroupProduct from "./components/filterGroupProduct/FilterGroupProduct";
import { Dropdown } from "react-bootstrap";
// import "./styles/product.css";
import "./styles/product.scss";
import React, { useEffect, useState } from "react";
import {
    createSearchParams,
    Link,
    useLocation,
    useNavigate,
} from "react-router-dom";
import Loading from "../../../shared/components/loading/Loading";
import ReactPaginate from "react-paginate";
import { productStore } from "./ProductStore";
import {showDelete} from "../../../shared/components/showDelete";
import NoContent from "../../../shared/components/NoContent";
import { handleKeyPressSpecialCharacters} from "../../../common/utils/Utils";
import ModalImportFile from "./components/importFileProduct/ModalImportFile";
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const ProductPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [openDropDownFile, setOpenDropDownFile] = useState<boolean>(false);
    const handlePageClick = (data: { selected: number }) => {
        const selectedPage = data.selected;
        const params: any = {};
        if (productStore.staticDataListProduct.search !== "")
            params.keyword = productStore.staticDataListProduct.search;
        params.page = selectedPage + 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        productStore.staticDataListProduct.page_number = selectedPage;
        productStore.fetchListProduct(true);
    };
    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        const searchKeyword = (e.target as HTMLFormElement).keyword.value;
        const params: any = {};
        if (searchKeyword !== "") params.keyword = searchKeyword;
        navigate({
            search: createSearchParams(params).toString(),
        });
        productStore.staticDataListProduct.page_number = 0;
        productStore.fetchListProduct(true);
    };

    const [selected, setSelected] = useState<string[]>([]);
    function handleSelect(value: boolean, name: string) {
        if (value) {
            setSelected([...selected, name]);
        } else {
            setSelected(selected.filter((item) => item !== name));
        }
    }
    function selectAll(value: boolean) {
        if (value) {
            setSelected(productStore.listProduct.map((item: any) => item.id));
        } else {
            setSelected([]);
        }
    }
    const allSelected = selected.length === productStore.listProduct.length;

    const handleEditClick = (id: number) => {
        navigate(`/hang-hoa/san-pham/${id}/thong-tin-chung`);
    };

    useEffect(() => {
        productStore.fetchListCategory().then();
        productStore.fetchListBrand().then();
        productStore.staticFileData.last = "0";
        productStore.fetchGetFileError();
    }, []);
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        productStore.staticDataListProduct.search = query.get("keyword")
            ? String(query.get("keyword"))
            : "";
        productStore.staticDataListProduct.page_number = query.get("page")
            ? Number(query.get("page")) - 1
            : 0;
        productStore.fetchListProduct().then();
    }, [location.search]);
    return (
        <div className="container">
            <div className="mainContentProduct">
                <div className="filter">
                    <h1>Sản phẩm</h1>
                    <div className="filter-list scroll-y">
                        <FilterCategoryProduct />
                        <FilterGroupProduct />
                    </div>
                    {/*<FilterLabelProduct/>*/}
                </div>
                <div className="content-page">
                    <div className="content-page-header">
                        <div className="content-page-header-left">
                            <form
                                className="content-page-header-search"
                                onSubmit={handleSearch}
                            >
                                <input
                                    type="text"
                                    name="keyword"
                                    value={
                                        productStore.staticDataListProduct
                                            .search
                                    }
                                    onKeyPress={handleKeyPressSpecialCharacters}
                                    id="keyword"
                                    onChange={(e: any) =>
                                        (productStore.staticDataListProduct.search =
                                            e.currentTarget.value)
                                    }
                                    placeholder="Nhập tên sản phẩm"
                                />
                                <button type="submit">
                                    <img
                                        src="/assets/icon/search.svg"
                                        alt="search"
                                    />
                                </button>
                            </form>
                        </div>
                        <div className="content-page-header-right">
                            <Link
                                to="/hang-hoa/san-pham/them-moi"
                                className="content-page-header-right-plus"
                            >
                                <span>
                                    Thêm mới
                                    <img
                                        src="/assets/icon/plus.svg"
                                        alt="sorting"
                                    />
                                </span>
                            </Link>
                            {/*<button className="content-page-header-right-import">*/}
                            {/*    <span>*/}
                            {/*        Nhập sản phẩm*/}
                            {/*        <img*/}
                            {/*            src="/assets/icon/import-file.svg"*/}
                            {/*            alt="sorting"*/}
                            {/*        />*/}
                            {/*    </span>*/}
                            {/*</button>*/}
                            {/*<button className="content-page-header-right-export">*/}
                            {/*    <span>*/}
                            {/*        Xuất sản phẩm*/}
                            {/*        <img*/}
                            {/*            src="/assets/icon/export-file.svg"*/}
                            {/*            alt="sorting"*/}
                            {/*        />*/}
                            {/*    </span>*/}
                            {/*</button>*/}
                            <div
                                className="menuFile"
                                onClick={() => {
                                    setOpenDropDownFile(!openDropDownFile);
                                }}
                            >
                                <div className="mb-0 menuFile-header">
                                    Công cụ xử lý hàng loạt
                                    {openDropDownFile ? (
                                        <i className="fas fa-angle-up"></i>
                                    ) : (
                                        <i className="fas fa-angle-down"></i>
                                    )}
                                </div>

                                <ul
                                    className="menuFile-list"
                                    style={{
                                        zIndex: openDropDownFile ? "1000" : "0",
                                        opacity: openDropDownFile ? "1" : "0",
                                        visibility: openDropDownFile
                                            ? "visible"
                                            : "hidden",
                                    }}
                                >
                                    <li
                                        className="item itemImportFile"
                                        onClick={() =>
                                            productStore.showModalImportFile()
                                        }
                                    >
                                        <span>
                                            <img
                                                src="/assets/icon/import-file.svg"
                                                alt="sorting"
                                            />
                                            Nhập sản phẩm
                                        </span>
                                    </li>
                                    <li className="item itemExportFile">
                                        <button disabled={productStore.isLoadingStore} onClick={async (e)=>{
                                            e.stopPropagation()
                                            setOpenDropDownFile(true);
                                            await productStore.exportFilePro(); 
                                            if (productStore.linkFile) {
                                                // Kích hoạt tải file tự động
                                                const link = document.createElement('a');
                                                link.href = productStore.linkFile;
                                                link.click();
                                                link.remove(); // kích hoạt xong xóa tag a làm sạch Dom
                                            }
                                            }}>
                                            <img
                                                src="/assets/icon/export-file.svg"
                                                alt="sorting"
                                            />
                                            Xuất sản phẩm
                                        </button>
                                  
                                    </li>

                                    <li
                                        className="item itemUpLoadSampleFile"
                                        // onClick={handleDownloadFile}
                                    >
                                        <span>
                                            <a
                                                // href="/product_excel_sample.xlsx"
                                                href="https://omweb-prod.s3.ap-southeast-1.amazonaws.com/ommani/example_export/product_excel_sample.xlsx"
                                                download
                                            >
                                                <img
                                                    src="/assets/icon/document-download.svg"
                                                    alt="sorting"
                                                />
                                                Tải file mẫu
                                            </a>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={`content-table`}>
                        <div
                            className="table-responsive"
                            style={{height: productStore.listProduct?.length === 1 ? '250px' : 'auto'}}
                        >
                            <table className="table table-striped" id="table-2">
                                <thead>
                                <tr>
                                    {/*<th*/}
                                    {/*    style={{*/}
                                    {/*        width: "5%",*/}
                                    {/*        textAlign: "center",*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    <input*/}
                                    {/*        type="checkbox"*/}
                                    {/*        className="checkAll"*/}
                                    {/*        checked={allSelected}*/}
                                    {/*        onChange={(e) =>*/}
                                    {/*            selectAll(e.target.checked)*/}
                                    {/*        }*/}
                                    {/*    />*/}
                                    {/*</th>*/}
                                    <th
                                        style={{
                                            width: "45%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Tên sản phẩm
                                    </th>
                                    <th
                                        style={{
                                            width: "18%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Loại
                                    </th>
                                    <th
                                        style={{
                                            width: "10%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Trạng thái
                                    </th>
                                    <th
                                        style={{
                                            width: "10%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Ngày tạo
                                    </th>
                                    <th
                                        style={{
                                            width: "12%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Thao tác
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {productStore.isLoadingListProduct ? (
                                    <tr>
                                        <td
                                            colSpan={6}
                                            style={{textAlign: "center"}}
                                        >
                                            <Loading/>
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {productStore.listProduct.length === 0 ? (
                                            <tr>
                                                <td colSpan={5}>
                                                    <NoContent/>
                                                </td>
                                            </tr>
                                        ) : (
                                            productStore?.listProduct?.map(
                                                (item: any, index: number) => (
                                                    <tr>
                                                        {/*<td*/}
                                                        {/*    style={{*/}
                                                        {/*        textAlign: "center",*/}
                                                        {/*    }}*/}
                                                        {/*>*/}
                                                        {/*    <div*/}
                                                        {/*        className={`checkboxTable`}*/}
                                                        {/*    >*/}
                                                        {/*        <input*/}
                                                        {/*            type="checkbox"*/}
                                                        {/*            name="ids[]"*/}
                                                        {/*            value={item.id}*/}
                                                        {/*            checked={selected.includes(*/}
                                                        {/*                item.id*/}
                                                        {/*            )}*/}
                                                        {/*            onChange={(e) =>*/}
                                                        {/*                handleSelect(*/}
                                                        {/*                    e.target*/}
                                                        {/*                        .checked,*/}
                                                        {/*                    item.id*/}
                                                        {/*                )*/}
                                                        {/*            }*/}
                                                        {/*        />*/}
                                                        {/*    </div>*/}
                                                        {/*</td>*/}
                                                        <td>
                                                            <div className="info">
                                                                <div className="image">
                                                                    <img
                                                                        src={item?.preview?.value ? item.preview.value : '/assets/images/no-image.svg'}
                                                                        alt="no-image"
                                                                    />
                                                                </div>
                                                                <div className="title-code">
                                                                    <div className="title-code-content">
                                                                        <p className="title">
                                                                            {
                                                                                item.title
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                {item.is_variation ===
                                                                1
                                                                    ? "Biến thể"
                                                                    : "Đơn giản"}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            {item.status === 1 ? (
                                                                <span className="success-status">
                                                                Hoạt động
                                                            </span>
                                                            ) : (
                                                                <span className="disable-status">
                                                                Vô hiệu hóa
                                                            </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <p>
                                                                {new Date(
                                                                    item.created_at
                                                                ).toLocaleDateString(
                                                                    "en-GB"
                                                                )}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <Dropdown className="action-table">
                                                                <Dropdown.Toggle
                                                                    variant="success"
                                                                    id={`dropdown-basic${index}`}
                                                                >
                                                                    Thao tác
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item
                                                                        onClick={() =>
                                                                            handleEditClick(
                                                                                item.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="fa fa-edit"></i>
                                                                        Sửa
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item onClick={() =>
                                                                        showDelete.showDeleteConfirm(
                                                                            "Xác nhận xoá",
                                                                            "Bạn chắc chắn muốn xoá? (Hành động này không thể hoàn tác)",
                                                                            () =>
                                                                                productStore.fetchDeleteProduct(item.id)
                                                                        )
                                                                    }>
                                                                        <i className="fa fa-trash"></i>
                                                                        Xóa
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                )
                                            )
                                        )}
                                    </>

                                )}
                                </tbody>
                            </table>
                            {productStore.isLoadingListProduct
                                ? ""
                                : Math.ceil(productStore.metadata.total_page) >
                                1 && (
                                    <ReactPaginate
                                        previousLabel={
                                            <img
                                                src="/assets/icon/fi-rr-angle-small-left.svg"
                                                alt="right"
                                            />
                                        }
                                        nextLabel={
                                            <img
                                                src="/assets/icon/fi-rr-angle-small-right.svg"
                                                alt="right"
                                            />
                                        }
                                        breakLabel={"..."}
                                        pageCount={Math.ceil(
                                            productStore.metadata.total_page
                                        )}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        activeClassName={"active"}
                                        forcePage={
                                            productStore.staticDataListProduct
                                                .page_number
                                        }
                                    />
                                )}
                        </div>
                    </div>
                </div>
                <ModalImportFile />
            </div>
        </div>
    );
};
export default observer(ProductPage);
