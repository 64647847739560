import { getRequest, postRequest } from "../../common/helpers/RequestHelper"

class SalePointService {
    apiLocation = "api/v1/location/list"
    apiSub = "api/v1/machine_pos/by_location"
    apiOpenCash = "api/v1/pos/the_cash/open"
    apiCloseCash = "api/v1/pos/the_cash/close"
    apiTotalCash = "api/v1/pos/the_cash/get_total_amount_session_pos"


    public fetchLocation(): Promise<any> {
        return getRequest(this.apiLocation);
    }

    public fetchTotalMoneyPos(pos_id: any): Promise<any> {
        const apiTotalCashWithPos = `${this.apiTotalCash}?pos_id=${pos_id}`;
        return getRequest(apiTotalCashWithPos);
    }

    public fetchMachinePosByUser(store_id: string): Promise<any> {
        const apiSubWithStore = `${this.apiSub}?store_id=${store_id}`;
        return getRequest(apiSubWithStore);
    }

    public fetchCashAdding(cashData: any): Promise<any> {
        return postRequest(this.apiOpenCash, cashData);
    }

    public fetchCashClosing(closeData: any): Promise<any> {
        return postRequest(this.apiCloseCash, closeData);
    }
}

export const salePointService = new SalePointService();