import Constants from "../../../common/constants/Constants";
import { deleteRequest, getRequest, postRequest, putRequest } from "../../../common/helpers/RequestHelper";
import { productInPostStore, TFormCustomer } from "./ProductInPostStore";

class ProductInPosService {

    public fetchAllCustomer(): Promise<any> {
        return getRequest(`api/v1/customer?page_number=1&per_page=${Constants.maxSize}
            ${productInPostStore.dataFilterCustomer.phone ?`&phone=${productInPostStore.dataFilterCustomer.phone}` : ""}
             `);
    }

    public createCustomer(data: TFormCustomer): Promise<any> {
        return postRequest(`api/v1/customer`,data);
    }

    public updateCustomer(idCustomer: string, data: TFormCustomer): Promise<any> {
        return putRequest(`api/v1/customer/${idCustomer}`,data);
    }

    public fetchProvices(): Promise<any> {
        return getRequest(`api/v1/provinces`);
    }
    public fetchDistricts(provinceId: string): Promise<any> {
        return getRequest(`api/v1/districts?province_id=${provinceId}`);
    }
    public fetchWards(districtId: string): Promise<any> {
        return getRequest(`api/v1/wards?district_id=${districtId}`);
    }

    // product

    public fetchProductByType (type: string, id: any) : Promise<any> {
        return getRequest(`api/v1/products/${id}?request_type=${type}`);
    }
    public fetchDetailVariantByAttributeId (productId: any, params: any) : Promise<any> {
        return getRequest(`api/v1/products/get_detail_variant_by_attribute_ids?product_id=${productId}`,params);
    }
    public fetchListAttributeByProduct(productId: any) : Promise<any> {
        return getRequest(`api/v1/products/list_attribute_by_product?product_id=${productId}`);
    }

    public fetchVariantByBarcode(barcode: string){
        return getRequest(`api/v1/products/find_variant_by_barcode?barcode=${barcode}`);
    }

    // add to cart fnb
    public addToCartFnb(params:any) {
        return postRequest(`api/v1/pos/fnb/add_to_cart`, params)
    }

    public startBooking(params:any) {
        return postRequest(`api/v1/pos/fnb/booking`, params)
    }
    public endBooking(params:any) {
        return postRequest(`api/v1/pos/fnb/end_booking`, params)
    }

    public removeItem(params:any) {
        const queryParams = [
            params.row_id ? `row_id=${params.row_id}` : "",
            params.type ? `type=${params.type}` : "",
            params.dining_table_session ? `dining_table_session=${params.dining_table_session}` : "",
        ].filter(Boolean).join("&");

        return deleteRequest(`api/v1/pos/fnb/remove_from_cart/?${queryParams}`)
    }

    public createOrder(params:any) {
        return postRequest(`api/v1/pos/fnb/order/save`, params)
    }
    
    public getInfoTable(params:any) {
        return getRequest(`api/v1/pos/fnb/get_info_table/${params}`)
    }

    public noteCartItems(params:any) {
        return putRequest(`api/v1/pos/fnb/note_save`, params)
    }
}

export const productInPosService = new ProductInPosService();