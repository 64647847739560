import { orderService } from "./OrderService";
import { makeAutoObservable } from "mobx";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../common/utils/Toastutils";

interface StaticParamOrder {
    endDate: string | null;
    startDate: string | null;
    order_by: string | null;
    order_status: string;
    order_time: string;
    order_type: string;
    source_type: string;
    size: string;
    page: number;
    payment_method: string;
    payment_status: string;
    search: string;
    store_id: string;
    total_type: string;
}

class OrderStore {
    isLoading = false;
    isLoadingBtn = false;
    isLoadingDetail = false;
    isModalDetailOpen = false;
    isSavingOrder = false;
    isOrderBy: boolean | null = null;
    isOrderStatusStatic = "";

    constructor() {
        // this.initializeParams();
        makeAutoObservable(this);
    }

    initializeParams() {
        // const query = new URLSearchParams(window.location.search);
        // this.staticParamOrder.search = query.get("search")
        //     ? String(query.get("search"))
        //     : "";
        // this.staticParamOrder.startDate = query.get("startDate")
        //     ? String(query.get("startDate"))
        //     : "";
        // this.staticParamOrder.endDate = query.get("endDate")
        //     ? String(query.get("endDate"))
        //     : "";
        // this.staticParamOrder.payment_method = query.get("payment_method")
        //     ? String(query.get("payment_method"))
        //     : "";
        // this.staticParamOrder.payment_status = query.get("payment_status")
        //     ? String(query.get("payment_status"))
        //     : "";
        // this.staticParamOrder.order_status = query.get("order_status")
        //     ? String(query.get("order_status"))
        //     : "";
        // this.staticParamOrder.page = query.get("page")
        //     ? Number(query.get("page"))
        //     : 0;
    }

    staticParamOrder: StaticParamOrder = {
        endDate: "",
        startDate: "",
        order_by: "",
        order_status: "",
        order_time: "",
        order_type: "",
        source_type: "",
        page: 0,
        size: "10",
        payment_method: "",
        payment_status: "",
        search: "",
        store_id: "",
        total_type: "",
    };

    metadata: any = {
        size: 0,
        page: "",
        total_page: "",
        total: "",
        total_amount: "",
        total_order: "",
    };

    staticOrder: {
        id: string;
        order_status: string;
        payment_status: string;
    } = {
        id: "",
        order_status: "",
        payment_status: "",
    };

    handeClose = () => {
        orderStore.staticParamOrder.startDate = "";
        orderStore.staticParamOrder.endDate = "";
        orderStore.staticParamOrder.order_type = "";
        orderStore.staticParamOrder.payment_method = "";
        orderStore.staticParamOrder.order_status = "";
        orderStore.staticParamOrder.order_time = "";
        orderStore.staticParamOrder.order_by = "";
        orderStore.staticParamOrder.page = 0;
        orderStore.staticParamOrder.payment_status = "";
        orderStore.staticParamOrder.search = "";
        orderStore.staticParamOrder.source_type = "";
    };

    // Sap xep theo ngay va gio
    handleSortDateTime = () => {
        if (this.isOrderBy === null || this.isOrderBy === false) {
            this.isOrderBy = true;
            this.staticParamOrder.order_by = "created_at:asc";
        } else if (this.isOrderBy === true) {
            this.isOrderBy = false;
            this.staticParamOrder.order_by = "created_at:desc";
        }
    };

    //Reset lai trang thai order_by = null
    resetHandleSortDateTiem = () => {
        this.isOrderBy = null;
        this.staticParamOrder.order_by = "";
    };

    dataListOrder: any[] = [];
    dataListOrderItems: any[] = [];
    dataListOderById: any = [];

    async fetctAllListOder(run?: boolean) {
        let {
            endDate,
            startDate,
            order_by,
            order_status,
            order_time,
            order_type,
            source_type,
            page,
            size,
            payment_method,
            payment_status,
            search,
            store_id,
            total_type,
        } = this.staticParamOrder;

        const paramsOrder = {
            endDate: endDate,
            startDate: startDate,
            order_by: order_by,
            order_status: order_status,
            order_time: order_time,
            order_type: order_type,
            source_type: source_type,
            page: page + 1,
            size: size,
            payment_method: payment_method,
            payment_status: payment_status,
            search: search,
            store_id: store_id,
            total_type: total_type,
        };

        if (this.dataListOrder.length < 1 || run) {
            this.isLoading = true;
        }

        const result = await orderService.fetchAllOrder(paramsOrder);
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            if (result.status === HttpStatusCode.Ok) {
                // if (
                //     result.body.data.length !== this.dataListOrder.length ||
                //     JSON.stringify(result.body.data) !==
                //         JSON.stringify(this.dataListOrder)
                // ) {
                //     this.dataListOrder = result.body.data;
                //     this.metadata = result.body.metadata;
                // }
    
                this.dataListOrder = result.body.data;
                this.metadata = result.body.metadata;
            }
        }
    }

    async fetchDetailOrder(): Promise<void> {
        this.isLoadingDetail = true;
        let { id } = this.staticOrder;
        const result = await orderService.fetchGetDetail(id);
        this.isLoadingDetail = false;
        if (result.status === HttpStatusCode.Ok) {
            this.dataListOderById = result.body;
            this.dataListOrderItems = result.body.orderitems;
        }
    }

    async fetchUpdateOrder():Promise<void> {
        this.isSavingOrder = true;
        this.isLoadingDetail = true;
        let { id, order_status, payment_status } = this.staticOrder;
        const params = {
            id: id,
            order_status: order_status,
            payment_status: payment_status,
        };
        const resutl = await orderService.fetchPutOrder(params);
        this.isSavingOrder = false;
        this.isLoadingDetail = false;
        if (resutl.status === HttpStatusCode.Ok) {
            toastUtils.success("Sửa thành công", "");
        }
    }
}

export const orderStore = new OrderStore();
