import { makeAutoObservable, toJS } from "mobx";
import { loginService } from "./LoginService";
import StorageService from "../../common/service/StorageService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../common/utils/Toastutils";
import Constants from "../../common/constants/Constants";
const storageService = new StorageService();

class LoginStore {
    username: string = "";
    password: string = "";
    domain_name: string = "";
    isOpenLogin: boolean = true;
    isLoading: boolean = false;
    isLoadingBtn: boolean = false;
    isDomain: boolean = false;
    isGoBack: boolean = false;
    isrefreshToken: boolean = false;
    isForgetPassWord: boolean = false;
    isWillExpire: string = "";
    mailResetPass: string = "";
    isOpenEyeForgotPass: boolean = false;
    isOpenEyePassForgotConfirmPass: boolean = false;
    isOpenEyeLogin: boolean = false;
    valitedateEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    dataFormResetPass: any = {
        email: "",
        verification_code: "",
        password: "",
        password_confirmation: ""
    }

    constructor() {
        //* Tự động theo dõi các thay đổi
        makeAutoObservable(this);
    }
    setUserName(value: string) {
        this.username = value;
    }
    setPassWord(value: string) {
        this.password = value;
    }
    setApiUrl(value: string) {
        this.domain_name = value;
    }
    setisOpenLogin(value: boolean) {
        this.isOpenLogin = value;
    }
    setisForgetPassWord(value: boolean) {
        this.isForgetPassWord = value;
    }

    async checkDomain() {
        this.isLoading = true;
        const result = await loginService.verifyDomain(this.domain_name, "fnb");
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            this.isDomain = true;
            this.isWillExpire = result.body.will_expire;
            storageService.setIdTenant(result.body.enpoint);
            storageService.setIdDomain(this.domain_name);
        } else if (result.status === HttpStatusCode.UnprocessableEntity) {
            toastUtils.warning("Vui lòng không để trống domain", "");
        } else if (result.status === HttpStatusCode.InternalServerError) {
            toastUtils.error("Không tồn tại domain này", "");
        } else if (result.status === HttpStatusCode.Forbidden) {
            toastUtils.warning("Domain của bạn đã hết hạn", "");
        } else if(result.status === HttpStatusCode.ServiceUnavailable){
            toastUtils.warning("Domain không hỗ trợ trong kiểu FNB", "");
        } else toastUtils.warning("Vui lòng kiểm tra lại", "");
    }

    async login(endpoint: string) {
        this.isLoading = true;
        const result = await loginService.createToken(
            this.username,
            this.password,
            endpoint
        );
        // const valitedateEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Đăng nhập thành công", "");
            StorageService.setToken(result.body.access_token);
            StorageService.setRefreshToken(result.body.refresh_token);
            storageService.setNameUser(result.body.name);
            StorageService.saveArayLS(
                Constants.ROLE,
                result.body.roles.length > 0
                    ? result.body.roles[0].permissions
                    : []
            ); // lưu quyền
            window.location.href = "/";
        }
        // if (this.username.length < 1) {
        //     toastUtils.warning("Vui lòng nhập email", "");
        // } else if (!valitedateEmail.test(this.username)) {
        //     toastUtils.warning("Tên đăng nhập phải là email", "");
        // } else {
        //     toastUtils.warning("Vui lòng kiểm tra lại", "");
        // }
        // if (this.password.length < 1) {
        //     toastUtils.warning("Vui lòng nhập password", "");
        // }

        if (result.status === HttpStatusCode.BadRequest) {
            toastUtils.warning("Không tìm thấy thông tin tài khoản", "");
            // if (result.body.message.user === "not_found_user!") {
            //     toastUtils.warning("Không tìm thấy thông tin tài khoản", "");
            // } else if (
            //     result.body.message.authentication ===
            //     "please_check_your_login_information"
            // ) {
            //     toastUtils.warning(
            //         "Vui lòng kiểm tra lại thông tin đăng nhập",
            //         ""
            //     );
            // }
        } else if (result.status === HttpStatusCode.UnprocessableEntity) {
            toastUtils.warning("Vui lòng nhập mật khẩu", "");
        }

        if (result.status === HttpStatusCode.InternalServerError) {
            toastUtils.warning("Vui lòng kiểm tra lại mật khẩu", "");
        } else {
            toastUtils.warning("Vui lòng kiểm tra lại", "");
        }
    }

    async refreshToken() {
        if (this.isrefreshToken === false) {
            this.isrefreshToken = true;
            const param = {
                refresh_token: StorageService.getRefreshToken(),
            };
            const result = await loginService.refreshToken(param);
            this.isrefreshToken = false;
            if (result.status === HttpStatusCode.Ok) {
                StorageService.saveArayLS(
                    Constants.ROLE,
                    result.body.data.user.roles.length > 0
                        ? result.body.data.user.roles[0].permissions
                        : []
                );
                window.location.reload();
            } else {
                toastUtils.error("Đã có lỗi xảy ra!", "");
            }
        }
    }

    async sendMailResetPass(callback?: any) {

        if(this.mailResetPass === ""){
            toastUtils.warning("Vui lòng nhập email")
            return;
        }
        if(!this.valitedateEmail.test(this.mailResetPass)){
            toastUtils.warning("Email không hợp lệ")
            return;
        }
        this.isLoadingBtn = true;
        const result = await loginService.sendMailresetPass(this.mailResetPass);
        this.isLoadingBtn = false
        if (result.status === HttpStatusCode.Ok) {
            this.dataFormResetPass.email = this.mailResetPass
            callback && callback?.(this.mailResetPass)
            toastUtils.success("Gửi yêu cầu thay đổi mật khẩu thành công!");
        }else{

            if(result.body.message?.email){
                toastUtils.warning("Email không tồn tại!");
                return;
            }

            if(result.body.message?.exception_message === "thông_tin_cài_đặt_cấu_hình_mail_chưa_được_cập_nhật"){
                toastUtils.warning("Thông tin cài đặt cấu hình mail chưa được cập nhật")
                    return
            }
            toastUtils.error("Đã có lỗi xảy ra!");
        }
    }

    async resetPassword(callback?: any): Promise<void> {
        if(this.dataFormResetPass.email === ""){
            toastUtils.warning("Vui lòng nhập email")
            return;
        }
        if(!this.valitedateEmail.test(this.dataFormResetPass.email)){
            toastUtils.warning("Email không hợp lệ")
            return;
        }
        if(this.dataFormResetPass.verification_code === "" ){
            toastUtils.warning("Vui lòng nhập mã xác nhận")
            return;
        }
        if(this.dataFormResetPass.verification_code.length !== 6 ){
            toastUtils.warning("Mã xác nhận phải có 6 số")
            return;
        }
        if(this.dataFormResetPass.password === ""){
            toastUtils.warning("Vui lòng nhập mật khẩu")
            return;
        }
        if(this.dataFormResetPass.password.length < 8){
            toastUtils.warning("Mật khẩu phải có ít nhất 8 số")
            return;
        }
        if(this.dataFormResetPass.password_confirmation.length < 8){
            toastUtils.warning("Mật khẩu xác nhận phải có ít nhất 8 số")
            return;
        }
        if(this.dataFormResetPass.password_confirmation === ""){
            toastUtils.warning("Vui lòng nhập xác nhận mật khẩu")
            return;
        }
        if(this.dataFormResetPass.password_confirmation !== this.dataFormResetPass.password){
            toastUtils.warning("Mật khẩu xác nhận phải khớp với mật khẩu")
            return;
        }
        
        this.isLoadingBtn = true;
        const result = await loginService.resetPass(this.dataFormResetPass);
        this.isLoadingBtn = false
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Đặt lại mật khẩu thành công!");
            this.mailResetPass = ""
            callback && callback?.()
        }else{
            if(result.body.message?.exception_message === "verification_code_does_not_exist"){
            toastUtils.warning("Mã xác nhận không hợp lệ")
                return
            }
            toastUtils.error(result.body.error);
        }
    }
}

export const loginStore = new LoginStore();
