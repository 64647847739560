import { makeAutoObservable, reaction, toJS } from "mobx";
import { spacesFloorRoomTableService } from "./SpacesFloorRoomTableService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";
import StorageService from "../../../common/service/StorageService";
import Constants from "../../../common/constants/Constants";

class SpacesFloorRoomTableStore {
    isLoadingFloor = false;
    isLoading = false;
    isLoadingRoom = false;
    isLoadingTable = false;
    isLoadingBtn = false;
    isOddTable = false
    indexFloors: number = 0;
    indexRoom: number = 0;
    floorID: string = "";
    roomID: string = "";
    parent_Id: number = 0;
    storeID: string = "";
    isModalFloorRoomCreate = false;
    isModalTableCreate: boolean = false;
    listAllLocation: any[] = [];
    listLocation: any = [];
    posID: string = "";

    listAllFloors: any[] = [];
    listAllRoom: any[] = [];
    listTableOfFloor: any[] = [];
    listTableOfRoom: any[] = []
    listDetailFloor: any  = {}
    floorIdChoose: string = ""
    roomId: string = ""
    floorId: string = ""

    posIdUrl: string = ""
    roomIdUrl: string = ""
    floorIdUrl: string = ""

    listTableOfFloorOrRoom: any[] = []


    queryParams: {
        floor_id: string;
        pos_id: string;
        store_id: string;
        room_id: string;
        page: string;
        per_page: string;
    } = {
        floor_id: "",
        pos_id: "",
        store_id: "",
        room_id: "",
        page: "",
        per_page: "",
    };
    staticParamsFloorRoomTable: {
        name: string;
        store_id: string;
        parent_id: number | null;
        status: number;
        ordering: number;
        quantity_people: string;
        floor_room_id: number;
    } = {
        name: "",
        store_id: "",
        parent_id: 0,
        status: 1,
        ordering: 1,
        quantity_people: "",
        floor_room_id: 0,
    };

    resetFormStaticParamsFloorRoomTable() {
        this.staticParamsFloorRoomTable.name = ""
        this.staticParamsFloorRoomTable.store_id = ""
        this.staticParamsFloorRoomTable.parent_id = 0
        this.staticParamsFloorRoomTable.status = 1
        this.staticParamsFloorRoomTable.ordering = 1
        this.staticParamsFloorRoomTable.quantity_people = ""
        this.staticParamsFloorRoomTable.floor_room_id = 0
    }
    constructor() {
        makeAutoObservable(this);
        reaction(
            () => [this.floorID],
            () => {
                this.saveFloorID();
            }
        );
    }

    saveFloorID() {
        if (this.floorID != null) {
            localStorage.setItem("floorID", this.floorID.toString());
        } else {
            console.warn("floorID is undefined. Cannot save to localStorage.");
        }
    }
    saveRoomID() {
        if (!this.roomID) {
            console.log("rooomID is underfined");
        } else {
            localStorage.setItem("roomID", this.roomID.toString());
        }
    }
    savePosID() {
        localStorage.setItem("posID", this.posID.toString());
    }
    saveStoreID() {
        localStorage.setItem("storeID", this.storeID.toString());
    }
    loadStoreIDFromLocal() {
        const data = StorageService.getLocalStore("storeID");
        if (data) {
            this.storeID = JSON.parse(data);
        }
    }
    showModalFloorRoom = () => {
        this.isModalFloorRoomCreate = true;
    };
    showModalTable = () => {
        this.isModalTableCreate = true;
    };
    handleOK = () => {
        this.isModalFloorRoomCreate = false;
        this.isModalTableCreate = false;
    };
    handleCancel = () => {
        this.isModalFloorRoomCreate = false;
        this.isModalTableCreate = false;
    };
    handleClose = () => {
        this.isModalFloorRoomCreate = false;
        this.isModalTableCreate = false;
        this.isOddTable = false
    };

    async getLocation(run?: boolean) {
        const result = await spacesFloorRoomTableService.fetchListLocation();
        if (result.status === HttpStatusCode.Ok) {
            // if (this.listAllLocation.length !== result.body.data.length && this.listAllFloors.length > 0) {
                this.listAllLocation = result.body.data;
                // this.listLocation = this.listAllLocation.filter(
                //     (item: any) =>
                //         item.id.toString() ===
                //         this.listAllFloors[0]?.location_id.toString()
                // );
            // }
        }
    }

    async createFloorRoom(callback?: (id: any) => void): Promise<void> {
        this.isLoadingBtn = true;
        const { name, store_id, parent_id, status, ordering } =
            this.staticParamsFloorRoomTable;
        const params = {
            name: name,
            store_id: store_id,
            parent_id: parent_id,
            status: status,
            ordering: ordering,
        };

        const result = await spacesFloorRoomTableService.fetchCreateFloorRoom(
            params
        );
        this.isLoadingBtn = false;

        if (result.status === HttpStatusCode.Ok) {
            if (
                result.body.data.parent_id === 0 ||
                result.body.data.parent_id === null
            ) {
                if (this.listAllFloors.length < 1) {
                    this.floorID = result.body.data.id;
                    // this.saveFloorID();
                }
                // this.listAllFloors?.push(result.body.data);
                this.listAllFloors = [...this.listAllFloors, {...result.body.data, rooms: [], odd_table: []},]

                // && this.listAllFloors.length === 0
                if (callback && this.listAllFloors.length === 1) {
                    callback(result.body.data.id);
                    this.floorId = result.body.data.id
                }
            }
            else{
            
                // listAllFloors
                // Number(result.body.data.parent_id) === Number(this.floorIdUrl) ? this.listAllRoom.push(result.body.data): this.getListFloors();
                if( Number(result.body.data.parent_id) === Number(this.floorIdUrl)){  //nếu thêm phòng ở cùng tầng thì không cần call api

                    this.listAllRoom.push(result.body.data)
                    
                    // chỉ chèn data phòng vào trong tầng trong lần đầu
                    const existingItemIndex = this.listAllFloors.findIndex(
                        (item) => item.id === Number(this.floorIdUrl)
                      );
                    existingItemIndex > -1 && this.listAllFloors[existingItemIndex].rooms.length === 0  &&  (this.listAllFloors[existingItemIndex].rooms = [...this.listAllFloors[existingItemIndex].rooms, result.body.data ]);
                }else{
                    this.getListFloors()
                }
            }
           
            this.resetFormStaticParamsFloorRoomTable()
            toastUtils.success("Thêm thành công", "");
            this.handleClose();
        }
    }

    async createTable(run?: boolean, callback?: any): Promise<void> {
        const { name, store_id, quantity_people, floor_room_id, ordering } =
            this.staticParamsFloorRoomTable;
        const params = {
            name: name,
            store_id: store_id,
            quantity_people: quantity_people,
            floor_room_id: floor_room_id,
            ordering: ordering,
        };

        this.isLoadingBtn = true;

        const result = await spacesFloorRoomTableService.fetchCreateTable(
            params
        );

        this.isLoadingBtn = false;

        if (result.status === HttpStatusCode.Ok) {
            
            if ((result.body.data.floor_room_parent_id === 0 || !result.body.data.floor_room_parent_id) && this.roomIdUrl !== "0") {
                this.getListFloors()
                callback && callback?.()
            }else{
                this.listTableOfFloorOrRoom.unshift(result.body.data);
            }
            toastUtils.success("Thêm thành công", "");
            this.resetFormStaticParamsFloorRoomTable()
            this.handleClose();
        }
    }

    async getListFloors(run?: boolean): Promise<void> {
        const { pos_id, page, per_page } = this.queryParams;
        const params = {
            pos_id: pos_id,
            page: page,
            per_page: per_page || Constants.maxSize,
        };
        if (this.listAllFloors.length < 1 || run) {
            this.isLoadingFloor = true;
        }
        const result = await spacesFloorRoomTableService.fetchListFloors(
            params
        );
        
        if (result.status === HttpStatusCode.Ok) {
            this.listAllFloors = result.body.data

            this.floorId = this.floorIdUrl ?  this.floorIdUrl : result.body.data?.[0]?.id // nếu có trên url thì lấy không thì mặc định lấy phần tử đầu tiên trong data
            this.roomId =  this.roomIdUrl ? this.roomIdUrl : (result.body.data?.[0]?.odd_table?.length > 0 ?  "0" : result.body.data?.[0]?.rooms?.[0]?.id)

            const filteredData = this.listAllFloors.find(item => item.id === Number(this.floorId));

            this.listDetailFloor = filteredData // lấy ra chi tiết tầng xem có bàn lẻ trong tầng đó hay không
            this.listAllRoom = filteredData?.rooms || []

            
            if(filteredData?.odd_table?.length > 0 && (!this.roomIdUrl || this.roomIdUrl === "0") ){ // có bàn lẻ
                this.listTableOfRoom = []
                this.getTableOfFloors() // lấy các bàn của bàn lẻ
            }else{
                this.listTableOfFloor = []
                this.getTableOfRoom() // lấy các bàn của phòng
            }
        }
        this.isLoadingFloor = false;
    }


    async getTableOfFloors() { // dành cho bàn lẻ
        const { pos_id } = this.queryParams;
        const params = {
            pos_id: pos_id,
            floor_id: this.floorId,
        };
        this.isLoadingTable = true;
        const result = await spacesFloorRoomTableService.fetchGetTableOfFloors(
            params
        );
        this.isLoadingTable = false;
        if (result.status === HttpStatusCode.Ok) {
           this.listTableOfFloor = result.body.odd_table
           this.listTableOfFloorOrRoom = result.body.odd_table
        }else{
            this.listTableOfFloorOrRoom = []
        }
    }

    async getTableOfRoom() { // dành cho các phòng
        const { pos_id } = this.queryParams;
        const params = {
            floor_id: this.floorId,
            room_id: this.roomId,
            pos_id: pos_id,
        };
        this.isLoadingTable = true;
        const result = await spacesFloorRoomTableService.fetchGetTableOfRooms(
            params
        );
        this.isLoadingTable = false;
        if (result.status === HttpStatusCode.Ok) {
            this.listTableOfRoom = result.body;
            this.listTableOfFloorOrRoom = result.body
        }
        else{
            this.listTableOfFloorOrRoom = []
        }
    }
}

export const spacesFloorRoomTableStore = new SpacesFloorRoomTableStore();
