import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Modal, Button, Input} from "antd";
import {filesStore} from "../../files/FilesStore";
import './tableFiles.css';
import {showDelete} from "../../../../shared/components/showDelete";
import {useLocation, useNavigate} from "react-router-dom";


function selectAllTargetInTable() {
    const allSelected = filesStore.listMedia
        .filter((item: any) => !item.isDir)
        .every((item: any) => filesStore.selectedFiles.includes(item.name));

    filesStore.listMedia.forEach((item: any) => {
        if (!item.isDir) {
            filesStore.handleCheckboxChange(item.name, !allSelected);
        }
    });
}


const FileTableList = () => {
    const [currentPath, setCurrentPath] = useState('');
    const [isRenameModalVisible, setIsRenameModalVisible] = useState(false);
    const [renameFileName, setRenameFileName] = useState('');
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [originalFileName, setOriginalFileName] = useState('');
    const [modalUrl, setModalUrl] = useState('');
    const location = useLocation();
    const navigate = useNavigate();



    const truncateText = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };

    const handleUpdateURL = () => {
        updateUrlParams();
    };

    const updateUrlParams = () => {
        const searchParams = new URLSearchParams();

        const params = {
            page: (filesStore.staticDataFiles.page + 1).toString(),
            path: filesStore.staticDataFiles.path
        };

        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                searchParams.set(key, value);
            }
        });

        navigate({search: searchParams.toString()}, {replace: true});
    };

    const handleCheckboxChange = (name: string, checked: boolean) => {
        filesStore.handleCheckboxChange(name, checked);
    };


    const openFolder = (item: any) => {
        const path = item.name;
        setCurrentPath(path);
        filesStore.staticDataFiles.path = path;
        filesStore.fetchMediaList(true);
        handleUpdateURL();
    };


    const openFile = (link: string) => {
        window.open(link, '_blank');
    };

    const handleCancel = () => {
        setIsRenameModalVisible(false);
        setIsModalVisible(false);
    };

    const handleRenameSubmit = () => {
        const oldName = originalFileName;
        const newName = renameFileName;
        const directory = selectedFile.name.split('/').slice(0, -1).join('/');

        filesStore.staticDataFiles.oldName = oldName;
        filesStore.staticDataFiles.newName = newName;
        filesStore.staticDataFiles.directory = directory;

        filesStore.changeNameImage();
        setIsRenameModalVisible(false);
    };

    const handleRemoveFile = (file: any) => {
        showDelete.showDeleteConfirm(
            `Bạn chắc chắn muốn xoá file đã chọn?`,
            "Hành động này không thể hoàn tác.",
            () => {
                filesStore.staticDataFiles.directory = file.name;
                filesStore.deleteFile();
            }
        );
    };

    const handleRemoveImage = (item: any) => {
        showDelete.showDeleteConfirm(
            `Bạn chắc chắn muốn xoá file ${truncateText(item.name.split('/').pop() || '', 15)}?`,
            "Hành động này không thể hoàn tác.",
            () => {
                if (!filesStore.selectedFiles.includes(item.name)) {
                    filesStore.selectedFiles.push(item.name);
                }
                filesStore.deleteFileImage();
            }
        );
    };



    const showRenameModal = (file: any) => {
        setSelectedFile(file);
        const fileName = file.name.split('/').pop() || '';
        setRenameFileName(fileName);
        setOriginalFileName(fileName);
        setIsRenameModalVisible(true);
    };

    const showUrlModal = (link: string) => {
        setModalUrl(link);
        setIsModalVisible(true);
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const pathFromQuery = params.get('path');

        if (pathFromQuery) {
            setCurrentPath(decodeURIComponent(pathFromQuery));
        } else {
            setCurrentPath(filesStore.metadata.rootPath);
        }

        const handlePathChange = () => {
            const updatedParams = new URLSearchParams(window.location.search);
            const newPath = updatedParams.get('path');
            setCurrentPath(newPath ? decodeURIComponent(newPath) : filesStore.metadata.rootPath);
        };
        window.addEventListener('popstate', handlePathChange);
        return () => {
            window.removeEventListener('popstate', handlePathChange);
        };
    }, [location.search, filesStore.metadata.rootPath]);

    return (
        <>
            <table className="file-table">
                <thead>
                <tr className="file-table-header">
                    <th style={{width: '5%', textAlign: 'center'}}>
                        <input
                            type="checkbox"
                            onChange={() => selectAllTargetInTable()}
                            checked={
                                filesStore.listMedia
                                    .filter((item: any) => !item.isDir)
                                    .every((item: any) => filesStore.selectedFiles.includes(item.name))
                            }
                        />
                    </th>
                    <th style={{width: "35%"}}>Tên tệp</th>
                    <th style={{width: "25%"}}>Chức năng</th>
                    <th style={{width: "25%"}}>Thời gian</th>
                    <th style={{width: "10%"}}>Kích cỡ</th>
                </tr>
                </thead>
                <tbody>
                {filesStore.listMedia.map((item: any, index: number) => (
                    <tr
                        className="file-table-row"
                        key={index}
                    >
                        <td className="check-box">
                            <div style={{textAlign: 'center'}}>
                                {!item.isDir && (
                                    <input
                                        type="checkbox"
                                        checked={filesStore.selectedFiles.includes(item.name)}
                                        onChange={(e) => handleCheckboxChange(item.name, e.target.checked)}
                                    />
                                )}
                            </div>
                        </td>
                        <td
                            className="file-name"
                            onClick={() => item.isDir ? openFolder(item) : openFile(item.link)}
                        >
                            {item.isDir ? (
                                <i className="fa fa-folder" style={{ fontSize: '20px', color: '#ccc' }}></i>
                            ) : item.name.split('/').pop().endsWith('.xlsx') ? (
                                <i className="fa fa-file" style={{ fontSize: '20px', color: '#ccc' }}></i>
                            ) : (
                                <div className="image-wrapper">
                                    <img
                                        className="zoom-image"
                                        src={item.url || '/path/to/default-image.png'}
                                        alt={item.url.split('/').pop()}
                                    />
                                </div>
                            )}
                            <span style={{color: '#666', fontWeight: '400'}}>
                                    {item.name ? truncateText(item.name.split('/').pop() || '', 30) : ''}
                            </span>

                        </td>
                        <td className="function">
                            {item.isDir ? (
                                <button className="remove-file"
                                        onClick={() => handleRemoveFile(item)}
                                >
                                    <i className="fas fa-trash"></i>
                                </button>
                            ) : (
                                <>
                                    <button className="note-file"
                                            onClick={() => showRenameModal(item)}>
                                        <i className="fas fa-edit"></i>
                                    </button>
                                    <button className="link-file"
                                            onClick={() => showUrlModal(item.link)}>
                                        <i className="fas fa-globe"></i>
                                    </button>
                                    <button className="remove-file"
                                            onClick={() => handleRemoveImage(item)}>
                                        <i className="fas fa-trash" ></i>
                                    </button>
                                </>
                            )}
                        </td>
                        <td style={{color: '#999', fontSize: '16px'}}>{item.time}</td>
                        <td style={{color: '#999', fontSize: '16px'}}>{item.size}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <Modal
                title="Đổi tên"
                open={isRenameModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="close" onClick={handleCancel}>
                        Đóng
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleRenameSubmit}>
                        Gửi
                    </Button>
                ]}
            >
                <div>
                    <label style={{display: 'block', marginBottom: '8px'}}>Path:</label>
                    <Input
                        value={renameFileName}
                        onChange={(e) => setRenameFileName(e.target.value)}
                    />
                </div>
            </Modal>
            <Modal
                title="Đường dẫn"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="close" onClick={handleCancel}>
                        Đóng
                    </Button>
                ]}
            >
                <Input value={modalUrl} readOnly/>
            </Modal>
        </>
    );
};

export default observer(FileTableList);
