import {observer} from 'mobx-react'
import React from 'react';
import {Button, Modal} from "antd";
import {confirmStore} from "./ConfirmStore";
import "./Confirm.scss";
import {transferTableStore} from "../TransferTableStore";
import {useNavigate} from "react-router-dom";

const Confirm = () => {
    const navigate = useNavigate();

    return (
        <Modal
            title={
                <div className="header d-flex align-items-center justify-content-between">
                    Xác nhận chuyển bàn
                </div>
            }
            open={confirmStore.isOpenModal = true}
            onCancel={() => (confirmStore.isOpenModal = false)}
            footer={[
                <Button onClick={() => (confirmStore.isOpenModal = false)} className="close-btn">
                    Quay lại
                </Button>,
                <Button type="primary" className="create-btn" onClick={()=> transferTableStore.switchTable(navigate)} disabled={transferTableStore.isLoadingBtn}>
                    {transferTableStore.isLoadingBtn ? "Vui lòng đợi..." : "Đồng ý"}
                </Button>
            ]}
            closeIcon={
                <img
                    src="/assets/icon/icon_closeWhite.svg"
                    width={25}
                    height={25}
                    alt="Close"
                    onClick={() => confirmStore.isOpenModal = false}
                />
            }
            className="confirm-modal"
            wrapClassName="ConfirmModal"
        >
            <div className="line"></div>
            <div className="images-container">
                <img src="/assets/images/notice.svg" alt="Permission"/>
            </div>
            <div className="modal-text">
                Không thể hoàn tác chuyển bàn. Bạn vẫn muốn chuyển bàn?
            </div>
        </Modal>

    );
}

export default observer(Confirm);