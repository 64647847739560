import { Select } from "antd";
import { observer } from "mobx-react";
import React from "react";
import {sliceText} from "../../../common/utils/Utils";

interface SelectMultipleProps {
    list: any[];
    placeholder?: string;
    labelFirst?: string;
    onChange?: (value: any) => void;
    defaultValue?: any;
    getLabel: (item: any) => string;
}

const SelectMultipleAnt: React.FC<SelectMultipleProps> = ({
    list,
    placeholder,
    labelFirst,
    onChange,
    defaultValue,
    getLabel,
}) => {
    const options = [
        ...(labelFirst ? [{ value: '', label: labelFirst }] : []),
        ...list.map((item: any) => ({
            value: item.id,
            label:  sliceText(getLabel(item),20), // Quá 20 ký tự thì ...
        })),
    ];
    return (
        <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={defaultValue}
            onChange={onChange}
            options={options}
            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
        />
    );
};

export default observer(SelectMultipleAnt);