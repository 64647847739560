    import React from "react";
import {observer} from "mobx-react";
// import {ErrorMessage, Field} from "formik";
import TextareaEditor from "../../../../../shared/components/form/TextareaEditor";
import SelectStatus from "../../../../../shared/components/form/SelectStatus";
import {productStore} from "../../ProductStore";
import {Form, Input} from "antd";
const { TextArea } = Input;
const InfoProduct = () => {
    return(
        <>
            <div className="session-create">
                <div className="session-create-left">
                    <div className="session-create-left-content">
                        <p className="title">Thông tin</p>
                        <p className="description">Thêm chi tiết sản phẩm và thông tin cần thiết từ
                            đây</p>
                    </div>
                </div>
                <div className="session-create-right">
                    <div className="session-create-content">
                            <div className="item-form">
                                <Form.Item name="name" label="Tên sản phẩm"
                                           rules={[
                                               {required: true, message: 'Không được để trống!'},
                                               {max: 100, message: 'Tên sản phẩm không được quá 100 ký tự'}
                                           ]}>
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="item-form item-form-textarea">
                                <Form.Item name="short_description" label="Mô tả ngắn" rules={[
                                    {required: true, message: 'Không được để trống!'},
                                    {max: 500, message: 'Mô tả không được quá 500 ký tự'}
                                ]}>
                                    <TextArea rows={4} />
                                </Form.Item>
                            </div>
                            <Form.Item name="long_description">
                                <TextareaEditor onChange={(value: any) => productStore.staticDataProduct.long_description = value} textLabel={`Mô tả chi tiết`}/>
                            </Form.Item>
                            <div className="item-flex grid-2 gap-20 md-10">
                                <div className="item-form">
                                    <Form.Item name="status" label="Trạng thái">
                                        <SelectStatus selected={() => productStore.staticDataProduct.status}
                                                      onChange={(value: any) => productStore.staticDataProduct.status = value}/>
                                    </Form.Item>
                                </div>
                                <div className="item-form">
                                    <label htmlFor="show_price">Hiện giá: (Tắt hiện giá sản phẩm sẽ hiện
                                        là: <span>Liên hệ</span>)</label>
                                    <Form.Item name="show_price">
                                        <SelectStatus selected={() => productStore.staticDataProduct.show_price}
                                                  onChange={(value: any) => productStore.staticDataProduct.show_price = value}
                                                      textOn={`Hiện`} textOff={`Ẩn`}/>
                                    </Form.Item>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default observer(InfoProduct);