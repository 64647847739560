import { makeAutoObservable } from "mobx";
import { refundOrderService } from "./RefundOrderService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";
import StorageService from "../../../common/service/StorageService";
import { productInPosCartStore } from "../productInPos/ProductInPosCartStore";

interface StaticParam {
  page: number;
  per_page: number;
}

class RefundOrderStore {
  idOrder: number | string | null  = null;
  isLoading: boolean = false;
  isLoadingDetails: boolean = false;

  posIdLC : any = "";
  floorId: any = "";
  roomId: any = "";
  diningTableId: any = "";
  activeVariantProduct: any = null;
  getTransactionType: any = null;

  dataRefundOrders: any[] = [];
  dataDetailsRefundOrder: any[] = [];
  dataListProRefund: any[] = []
  nameCustomer : string | null = ""
  totalMoneyOrigin : any

  productControl: any = {
    type: "sl",
    keycap: null,
    idVariant: null,
  };

  dataFilterRefundOrder: any = {
    keyword: "",
    posId: "",
  };

  staticParamRefundOrder: StaticParam = {
    page: 1,
    per_page: 10,
  };

  metadata: any = {
    total: null,
    page: 1,
    total_page: null,
    size: null,
  };

  constructor() {
    this.initializeParams();
    makeAutoObservable(this);
  }

  initializeParams() {
    const query = new URLSearchParams(window.location.search);
    this.dataFilterRefundOrder.keyword = query.get("keyword")
      ? String(query.get("keyword"))
      : "";
    this.staticParamRefundOrder.page = query.get("page")
      ? Number(query.get("page")) - 1
      : 0;
  }

  async fetchAllRefundOrder(run?: boolean) {
    let { page, per_page } = this.staticParamRefundOrder;
    let { keyword, posId } = this.dataFilterRefundOrder;
    const paramsRefund = {
      page: page + 1,
      per_page: per_page,
      keyword: keyword,
      pos_id: posId,
    };
    if (this.dataRefundOrders.length < 1 || run) {
      this.isLoading = true;
    }
    const result = await refundOrderService.fetchListRefundOrder(paramsRefund);
    this.isLoading = false;
    if (result.status === HttpStatusCode.Ok) {
      this.dataRefundOrders = result.body.data;
      this.metadata = result.body.metadata;
    } else {
      toastUtils.error("Đã có lỗi xảy ra", "");
    }
  }

  async fetchDetailsRefundOrder(run?: boolean) {
    this.isLoadingDetails = true;
    const result = await refundOrderService.fetchDetailsRefundOrder(
      this.idOrder as number
    );
    this.isLoadingDetails = false;
    if (result.status === HttpStatusCode.Ok) {
      this.dataDetailsRefundOrder = result.body.transaction_pos_item;
      this.getTransactionType = result.body.transaction_type
      this.nameCustomer = result.body.customer_info?.name || ""

      const total =
      result.body.transaction_pos_item.length > 0
        ? result.body.transaction_pos_item.reduce((accumulator: any, currentValue: any) => {
            const priceWithDiscount =
              currentValue.discount > 0
                ? ((((currentValue.amount as number) *
                    currentValue.qty) as number) *
                    (100 - currentValue.discount)) /
                  100
                : (((currentValue.amount as number) *
                    currentValue.qty) as number);
            return accumulator + priceWithDiscount;
          }, 0)
        : 0;

      this.totalMoneyOrigin = total
      const refreshData =
      result.body.transaction_pos_item.length > 0
        ? result.body.transaction_pos_item.map((item: any) => ({
            discount: item.discount,
            price: item.amount_edit > 0 ? item.amount_edit : item.amount,
            productId: item.product_id,
            idData: item.product_info?.id,
            productName: item.product_info.name,
            quantity: Number(item.qty),
            sku: item.product_info.sku,
            unit: item.product_info.unit,
            variant: { id: item.product_info.id_sku, name: item.product_info.attribute_compare },
          }))
        : [];
        StorageService.saveArayLS(`refundPos${this.posIdLC}-${this.diningTableId}`, refreshData);

     const savaLC = StorageService.getArrayFromLS(`refundPos${this.posIdLC}-${this.diningTableId}`)
     const saveCustomer = StorageService.setObjectStore(`refundCustomer${this.posIdLC}`, result.body.customer_id ? {id: result.body.customer_id,...result.body.customer_info} : {})
     const refundOrderId = StorageService.setLocalStore(`refundOrderId${this.posIdLC}`,this.idOrder)
     if(savaLC){
        this.dataListProRefund = refreshData
     }
    }
  }

  ChangeValueProductInfoRefund(type: any, keycap: any, idVariant: any) {
    this.productControl.type = type;
    this.productControl.keycap = keycap;
    this.productControl.idVariant = idVariant;

    const productIndex = this.dataListProRefund.findIndex(
        (item) => item.variant.id === idVariant
    );
    const product = this.dataListProRefund[productIndex];

    // Lưu giá trị ban đầu của quantity, price và discount
    const originalQuantity = product?.quantity;
    const originalPrice = product?.price;
    const originalDiscount = product?.discount;

    if (type === "sl" && keycap !== "sl") {
        if (product) {
            if (keycap === "del2") {
                product.quantity = String(product.quantity).slice(0, -1) || "0";
                if (!product.quantity.includes(".")) {
                    product.quantity = Number(product.quantity);
                }
            } else if (keycap === "dots") {
                if (!String(product.quantity).includes(".")) {
                    product.quantity = `${product.quantity}.`;
                }
            } else {
                if (product.quantity === 0) {
                    product.quantity = keycap;
                } else {
                    product.quantity = `${product.quantity}${keycap}`;
                }
            }
        }
    }

    if (type === "price" && keycap !== "price") {
        if (product) {
            if (keycap === "del2") {
                product.price = String(product.price).slice(0, -1) || "0";
                product.price = Number(product.price);
            } else if (keycap === "dots") {
                return;
            } else {
                product.price = Number(`${product.price}${keycap}`);
            }
        }
    }

    if (type === "ck" && keycap !== "ck") {
        if (product) {
            if (keycap === "del2") {
                product.discount = String(product.discount).slice(0, -1) || "0";
                if (!product.discount.includes(".")) {
                    product.discount = Number(product.discount);
                }
            } else if (keycap === "dots") {
                if (!String(product.discount).includes(".")) {
                    product.discount = `${product.discount}.`;
                }
            } else {
                if (product.discount === 0) {
                    product.discount = keycap;
                } else {
                    product.discount = `${product.discount}${keycap}`;
                    if (product.discount > 100) {
                        product.discount = 100;
                    }
                }
            }
        }
    }

    if (keycap === "del1") {
        this.dataListProRefund = this.dataListProRefund.filter(
            (item: any) => item.idData  !== idVariant
        );
    }

    const totalEdit = productInPosCartStore.CaculatorTotal(this.dataListProRefund);

    // Kiểm tra điều kiện tổng tiền
    if (totalEdit > this.totalMoneyOrigin) {
        // Khôi phục giá trị ban đầu nếu điều kiện không thỏa mãn
        if (product) {
            product.quantity = originalQuantity; // Khôi phục số lượng
            product.price = originalPrice; // Khôi phục giá
            product.discount = originalDiscount; // Khôi phục giảm giá
        }
        toastUtils.error("Số lượng không được vượt quá ban đầu"); // Hiển thị thông báo lỗi
    }

    StorageService.saveArayLS(`refundPos${this.posIdLC}-${this.diningTableId }`, this.dataListProRefund);
}

}

export const refundOrderStore = new RefundOrderStore();
