import { observer } from "mobx-react";
import { Col, Form, Input, Row, Select, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { storebranchstore } from "./StoreBranchStore";
import { Link } from "react-router-dom";
import SelectStatus from "../../../shared/components/form/SelectStatus";
import UploadFile from "../../../shared/components/form/UploadFile";
import { handleFile } from "../../../common/helpers/UploadFileHelper";
import AddNewStaffModal from "./components/AddNewStaffModal";
import SelectCustom from "../../../shared/components/form/SelectCustom";
import SelectMultipleAnt from "../../../shared/components/form/SelectMultipleAnt";
import StorageService from "../../../common/service/StorageService";
import { numberByDateNow } from "../../../common/utils/Utils";
import { toJS } from "mobx";

const StoreBranchModal = () => {
    const [form] = Form.useForm();
    const checkisModal = StorageService.getLocalStore("isModal");
    if (checkisModal !== null) {
        var isModal = JSON.parse(checkisModal);
    }

    useEffect(() => {
        storebranchstore.fetchStaff();
        storebranchstore.fetchStaffRoles();
        storebranchstore.fetchLocation();
        storebranchstore.fetchProvince();

        return () => {
            StorageService.saveArayLS("locationdetail", []);
        };
    }, []);

    useEffect(() => {
        if (!isModal) {
            storebranchstore.loadInitialItemFromLocal();

            storebranchstore.fetchDistrict(
                storebranchstore.initialItem[0].provinceId
            );
            storebranchstore.fetchWard(
                storebranchstore.initialItem[0].districtId
            );

            storebranchstore.initialItem[0].members.map((item: any) => item.id);
        }
    }, []);

    return (
        <>
            <div className="container">
                <div
                    className="mainContentCreateStoreBranch"
                    style={{
                        padding: "8px 12px",
                        background: "white",
                    }}
                >
                    <AddNewStaffModal />

                    <Link
                        to="/cai-dat/chi-nhanh-cua-hang"
                        style={{
                            textDecoration: "none",
                        }}
                        onClick={() =>
                            {
                                StorageService.removeLocalStore("initialItem")
                                storebranchstore.handleClearStaticLocation();
                                storebranchstore.handleClearInittialValue();
                            }
                        }
                    >
                        <span
                            style={{
                                fontSize: "20px",
                                textTransform: "uppercase",
                                fontWeight: "600",
                            }}
                            className="d-flex align-items-center"
                        >
                            <img
                                src="/assets/icon/arrow-left.svg"
                                alt="fi-sr-caret-down"
                            />
                            {localStorage.getItem("modalName")}
                        </span>
                    </Link>

                    <Form
                        form={form}
                        className="form-create-edit"
                        onFinish={(value) => {
                            isModal
                                ? storebranchstore
                                      .fetchCreateLocation()
                                      .then((resole) => {
                                          form.resetFields();
                                          storebranchstore.staticLocation.members =
                                              [];
                                          window.scrollTo({
                                              top: 0,
                                              behavior: "smooth",
                                          });
                                      })

                                      .catch((err) => console.log(err))
                                : storebranchstore
                                      .fetchUpdateLocationInModalPage()
                                      .then((resole) => {
                                          window.scrollTo({
                                              top: 0,
                                              behavior: "smooth",
                                          });
                                      })

                                      .catch((err) => console.log(err));
                        }}
                        initialValues={
                            isModal
                                ? storebranchstore.staticLocation
                                : StorageService.getArrayFromLS(
                                      "initialItem"
                                  )[0]
                        }
                    >
                        <Row>
                            <Col span={12}>
                                <div
                                    className="title"
                                    style={{
                                        position: "sticky",
                                        top: "0",
                                        left: "0",
                                    }}
                                >
                                    <p
                                        className="mb-0"
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Mô tả
                                    </p>
                                    <span>
                                        Thêm chi tiết vị trí và thông tin cần
                                        thiết từ đây
                                    </span>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div
                                    className="storebranchForm"
                                    style={{
                                        padding: "0",
                                    }}
                                >
                                    <div className="storebranchForm--body">
                                        <div className="item-form">
                                            <label className="label-form">
                                                Tên cửa hàng <span>*</span>
                                            </label>

                                            <Form.Item
                                                name="name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        max: 100,
                                                        message:
                                                            "Không được để trống tên cửa hàng",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder="Nhập tên cửa hàng"
                                                    type="text"
                                                    value={
                                                        isModal
                                                            ? storebranchstore
                                                                  ?.staticLocation
                                                                  ?.name
                                                            : storebranchstore
                                                                  .initialItem[0]
                                                                  ?.name
                                                    }
                                                    onChange={(e) => {
                                                        isModal
                                                            ? storebranchstore.staticLocation &&
                                                              (storebranchstore.staticLocation.name =
                                                                  e.target.value)
                                                            : (storebranchstore.initialItem[0].name =
                                                                  e.target.value);
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className="item-form item-selected">
                                            <label className="label-form">
                                                Trạng thái
                                            </label>

                                            <Form.Item name="status">
                                                <SelectStatus
                                                    onChange={(value: any) => {
                                                        isModal
                                                            ? (storebranchstore.staticLocation.status =
                                                                  value)
                                                            : (storebranchstore.initialItem[0].status =
                                                                  parseInt(
                                                                      value
                                                                  ));
                                                    }}
                                                    selected={() =>
                                                        isModal === false
                                                            ? storebranchstore
                                                                  .initialItem[0]
                                                                  ?.status === 1
                                                                ? "Hoạt động"
                                                                : "Vô hiệu hóa"
                                                            : storebranchstore
                                                                  .staticLocation
                                                                  ?.status ===
                                                              "1"
                                                            ? "Hoạt động"
                                                            : "Vô hiệu hóa"
                                                    }
                                                    textOn="Hoạt động"
                                                    textOff="Vô hiệu hóa"
                                                />
                                            </Form.Item>
                                        </div>

                                        <div
                                            className="item d-flex align-items-center justify-content-between"
                                            style={{
                                                gap: "18px",
                                            }}
                                        >
                                            <div
                                                className="itemLocation"
                                                style={{
                                                    width: "50%",
                                                }}
                                            >
                                                <div className="item-form">
                                                    <label className="label-form">
                                                        Địa chỉ email cửa hàng{" "}
                                                        <span>*</span>
                                                    </label>

                                                    <Form.Item
                                                        name="email"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                max: 100,
                                                                message:
                                                                    "Không được để trống địa chỉ email",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Điền email quản lý chi nhánh"
                                                            type="email"
                                                            value={
                                                                isModal
                                                                    ? storebranchstore
                                                                          ?.staticLocation
                                                                          ?.email
                                                                    : storebranchstore
                                                                          .initialItem[0]
                                                                          ?.email
                                                            }
                                                            onChange={(e) => {
                                                                isModal
                                                                    ? (storebranchstore.staticLocation.email =
                                                                          e.target.value)
                                                                    : (storebranchstore.initialItem[0].email =
                                                                          e.target.value);
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div
                                                className="itemPhone"
                                                style={{
                                                    width: "50%",
                                                }}
                                            >
                                                <div className="item-form">
                                                    <label className="label-form">
                                                        Số điện thoại cửa hàng{" "}
                                                        <span>*</span>
                                                    </label>

                                                    <Form.Item
                                                        name="phone_number"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                max: 100,
                                                                message:
                                                                    "Không được để trống số điện thoại",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Nhập số điện thoại cửa hàng"
                                                            type="number"
                                                            value={
                                                                isModal
                                                                    ? storebranchstore
                                                                          ?.staticLocation
                                                                          ?.phone_number
                                                                    : storebranchstore
                                                                          .initialItem[0]
                                                                          ?.phone_number
                                                            }
                                                            onChange={(e) => {
                                                                isModal
                                                                    ? (storebranchstore.staticLocation.phone_number =
                                                                          e.target.value)
                                                                    : (storebranchstore.inittialValue.phone_number =
                                                                          e.target.value);
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item-form item-selected">
                                            <label className="label-form">
                                                Chọn người quản lý{" "}
                                                <span>*</span>
                                            </label>

                                            <Form.Item
                                                name="manager"
                                                rules={[
                                                    {
                                                        validator: (
                                                            _,
                                                            value
                                                        ) => {
                                                            if (
                                                                !value ||
                                                                !storebranchstore.listManager.find(
                                                                    (item) =>
                                                                        item.id ===
                                                                        parseInt(
                                                                            value
                                                                        )
                                                                )
                                                            ) {
                                                                return Promise.reject(
                                                                    "Vui lòng chọn một người quản lý"
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                {/* <Select
                                                    showSearch
                                                    placeholder="Chọn người quản lí"
                                                    onChange={(
                                                        value: string
                                                    ) => {
                                                        isModal
                                                            ? (storebranchstore.staticLocation.manager =
                                                                  value)
                                                            : storebranchstore.initialItem[0].manager.push(
                                                                  [value]
                                                              );
                                                    }}
                                                    options={storebranchstore.listManager.map(
                                                        (item) => ({
                                                            value: item.id,
                                                            label: (
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            item.avatar ===
                                                                            null
                                                                                ? "/assets/icon/icon_user.svg"
                                                                                : item.avatar
                                                                        }
                                                                        alt={
                                                                            item.label
                                                                        }
                                                                        style={{
                                                                            width: "30px",
                                                                            height: "30px",
                                                                            borderRadius:
                                                                                "50%",
                                                                            marginRight:
                                                                                "10px",
                                                                        }}
                                                                    />
                                                                    {item.label}{" "}
                                                                    {item.name}
                                                                </div>
                                                            ),
                                                        })
                                                    )}
                                                    notFoundContent="Chọn người quản lý"
                                                    style={{
                                                        height: "45px",
                                                    }}
                                                    value={
                                                        isModal === false
                                                            ? storebranchstore
                                                                  .listManager
                                                                  .length === 0
                                                                ? null
                                                                : storebranchstore.listManager.find(
                                                                      (item) =>
                                                                          item.id ===
                                                                          storebranchstore
                                                                              .initialItem[0]
                                                                              ?.manager
                                                                  )?.id
                                                            : ""
                                                    }
                                                /> */}
                                                <SelectCustom
                                                    list={
                                                        storebranchstore.listManager
                                                    }
                                                    placeholder="Chọn người quản lý"
                                                    labelFirst="Chọn người quản lý"
                                                    onChange={(
                                                        value: string
                                                    ) => {
                                                        if (isModal) {
                                                            storebranchstore.staticLocation.manager = value; // Gán giá trị cho manager trong modal
                                                        } else {
                                                            const item = storebranchstore.initialItem[0];
                                                            if (!item) {
                                                                return;
                                                            }
                                                            // Gán trực tiếp giá trị cho manager
                                                            item.manager = value;
                                                        }
                                                        // isModal
                                                        //     ? (storebranchstore.staticLocation.manager =
                                                        //           value)
                                                        //     : storebranchstore.initialItem[0]?.manager?.push(
                                                        //            [value]
                                                        //     );
                                                    }}
                                                    selected={() =>
                                                        isModal === false
                                                            ? storebranchstore
                                                                  .listManager
                                                                  .length === 0
                                                                ? null
                                                                : storebranchstore.listManager.find(
                                                                      (item) =>
                                                                          item.id ===
                                                                          storebranchstore
                                                                              .initialItem[0]
                                                                              ?.manager
                                                                  )?.id
                                                            : storebranchstore.listManager.find(
                                                                (item) =>
                                                                    item.id ===
                                                                    storebranchstore
                                                                        .staticLocation
                                                                        ?.manager
                                                            )?.id
                                                    }
                                                    getLabel={(item: any) => {
                                                        return item.name;
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>

                                        <div
                                            className="item d-flex align-items-center justify-content-between"
                                            style={{
                                                gap: "18px",
                                            }}
                                        >
                                            <div
                                                className="itemLocation"
                                                style={{
                                                    width: "50%",
                                                }}
                                            >
                                                <div className="item-form">
                                                    <label className="label-form">
                                                        Tỉnh/Thành Phố{" "}
                                                        <span>*</span>
                                                    </label>

                                                    <Form.Item
                                                        name="provinceId"
                                                        rules={[
                                                            {
                                                                validator: (
                                                                    _,
                                                                    value
                                                                ) => {
                                                                    if (
                                                                        !value ||
                                                                        !storebranchstore?.listProvince?.find(
                                                                            (
                                                                                item
                                                                            ) =>
                                                                                item.id ===
                                                                                parseInt(
                                                                                    value
                                                                                )
                                                                        )
                                                                    ) {
                                                                        return Promise.reject(
                                                                            "Vui lòng chọn một tỉnh/thành phố hợp lệ"
                                                                        );
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <SelectCustom
                                                            list={
                                                                storebranchstore.listProvince
                                                            }
                                                            placeholder="Chọn Tỉnh/Thành Phố"
                                                            labelFirst="Chọn Tỉnh/Thành"
                                                            onChange={(e) => {
                                                                isModal
                                                                    ? (storebranchstore.staticLocation.provinceId =
                                                                          e.toString())
                                                                    : (storebranchstore.initialItem[0].provinceId =
                                                                          e.toString());
                                                                storebranchstore.fetchDistrict(
                                                                    e.toString()
                                                                );
                                                            }}
                                                            selected={() =>
                                                                isModal
                                                                    ? storebranchstore?.listProvince?.find(
                                                                          (
                                                                              item: any
                                                                          ) =>
                                                                              item.id ===
                                                                              parseInt(
                                                                                  storebranchstore
                                                                                      .staticLocation
                                                                                      ?.provinceId
                                                                              )
                                                                      )?.id
                                                                    : storebranchstore.listProvince.find(
                                                                          (
                                                                              item: any
                                                                          ) =>
                                                                              item.id ===
                                                                              parseInt(
                                                                                  storebranchstore
                                                                                      .initialItem[0]
                                                                                      ?.provinceId
                                                                              )
                                                                      )?.id
                                                            }
                                                            getLabel={(
                                                                item: any
                                                            ) => {
                                                                return item.name;
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div
                                                className="item-form"
                                                style={{
                                                    width: "50%",
                                                }}
                                            >
                                                <div className="item">
                                                    <label className="label-form">
                                                        Quận/Huyện{" "}
                                                        <span>*</span>
                                                    </label>

                                                    <Form.Item
                                                        name="districtId"
                                                        rules={[
                                                            {
                                                                validator: (
                                                                    _,
                                                                    value
                                                                ) => {
                                                                    if (
                                                                        !value ||
                                                                        !storebranchstore.listDistrict.find(
                                                                            (
                                                                                item
                                                                            ) =>
                                                                                item.id ===
                                                                                parseInt(
                                                                                    value
                                                                                )
                                                                        )
                                                                    ) {
                                                                        return Promise.reject(
                                                                            "Vui lòng chọn một quận/huyện hợp lệ"
                                                                        );
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <SelectCustom
                                                            list={
                                                                storebranchstore.listDistrict
                                                            }
                                                            placeholder="Chọn Quận/Huyện"
                                                            labelFirst="Chọn Quận/Huyện"
                                                            onChange={(e) => {
                                                                isModal
                                                                    ? (storebranchstore.staticLocation.districtId =
                                                                          e.toString())
                                                                    : (storebranchstore.initialItem[0].districtId =
                                                                          e.toString());
                                                                storebranchstore.fetchWard(
                                                                    e.toString()
                                                                );
                                                            }}
                                                            selected={() =>
                                                                isModal
                                                                    ? storebranchstore?.listDistrict?.find(
                                                                          (
                                                                              item: any
                                                                          ) =>
                                                                              item.id ===
                                                                              storebranchstore
                                                                                  .staticLocation
                                                                                  ?.districtId
                                                                      )?.id
                                                                    : storebranchstore?.listDistrict?.find(
                                                                          (
                                                                              item: any
                                                                          ) =>
                                                                              item.id ===
                                                                              parseInt(
                                                                                  storebranchstore
                                                                                      .initialItem[0]
                                                                                      ?.districtId
                                                                              )
                                                                      )?.id
                                                            }
                                                            getLabel={(
                                                                item: any
                                                            ) => {
                                                                return item.name;
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="item d-flex align-items-center justify-content-between"
                                            style={{
                                                gap: "18px",
                                            }}
                                        >
                                            <div
                                                className="itemLocation"
                                                style={{
                                                    width: "50%",
                                                }}
                                            >
                                                <div className="item-form">
                                                    <label className="label-form">
                                                        Phường/Xã <span>*</span>
                                                    </label>

                                                    <Form.Item
                                                        name="wardId"
                                                        rules={[
                                                            {
                                                                validator: (
                                                                    _,
                                                                    value
                                                                ) => {
                                                                    if (
                                                                        !value ||
                                                                        !storebranchstore.listWard.find(
                                                                            (
                                                                                item
                                                                            ) =>
                                                                                item.id ===
                                                                                parseInt(
                                                                                    value
                                                                                )
                                                                        )
                                                                    ) {
                                                                        return Promise.reject(
                                                                            "Vui lòng chọn một phường/xã hợp lệ"
                                                                        );
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <SelectCustom
                                                            list={
                                                                storebranchstore.listWard
                                                            }
                                                            placeholder="Chọn Phường/Xã"
                                                            labelFirst="Chọn Phường/Xã"
                                                            onChange={(e) => {
                                                                isModal
                                                                    ? (storebranchstore.staticLocation.wardId =
                                                                          e.toString())
                                                                    : (storebranchstore.initialItem[0].wardId =
                                                                          e.toString());
                                                            }}
                                                            selected={() =>
                                                                isModal
                                                                    ? storebranchstore?.listWard?.find(
                                                                          (
                                                                              item: any
                                                                          ) =>
                                                                              item.id ===
                                                                              parseInt(
                                                                                  storebranchstore
                                                                                      .staticLocation
                                                                                      ?.wardId
                                                                              )
                                                                      )?.id
                                                                    : storebranchstore?.listWard?.find(
                                                                          (
                                                                              item: any
                                                                          ) =>
                                                                              item.id ===
                                                                              parseInt(
                                                                                  storebranchstore
                                                                                      .initialItem[0]
                                                                                      ?.wardId
                                                                              )
                                                                      )?.id
                                                            }
                                                            getLabel={(
                                                                item: any
                                                            ) => {
                                                                return item.name;
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div
                                                className="item-form"
                                                style={{
                                                    width: "50%",
                                                }}
                                            >
                                                <div className="item">
                                                    <label className="label-form">
                                                        Địa chỉ <span>*</span>
                                                    </label>

                                                    <Form.Item
                                                        name="address"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                max: 100,
                                                                message:
                                                                    "Không được để trống địa chỉ",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Nhập địa chỉ cửa hàng"
                                                            type="text"
                                                            value={
                                                                isModal
                                                                    ? storebranchstore
                                                                          ?.staticLocation
                                                                          ?.address
                                                                    : storebranchstore
                                                                          .initialItem[0]
                                                                          ?.address
                                                            }
                                                            onChange={(e) => {
                                                                isModal
                                                                    ? (storebranchstore.staticLocation.address =
                                                                          e.target.value)
                                                                    : (storebranchstore.initialItem[0].address =
                                                                          e.target.value);
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item item-checked">
                                            <Form.Item
                                                name="defaultLocation"
                                                style={{
                                                    display: "flex",
                                                    gap: "12px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    id="locationRadio"
                                                    name="locationRadio"
                                                    value="locationRadio"
                                                    defaultChecked={
                                                        isModal === false
                                                            ? storebranchstore
                                                                  .initialItem[0]
                                                                  ?.is_default ===
                                                              1
                                                                ? true
                                                                : false
                                                            : false
                                                    }
                                                    onChange={(e) => {
                                                        if (isModal) {
                                                            if (
                                                                e.target.checked
                                                            ) {
                                                                storebranchstore.staticLocation.is_default = 1;
                                                            } else {
                                                                storebranchstore.staticLocation.is_default = 0;
                                                            }
                                                        } else {
                                                            if (
                                                                e.target.checked
                                                            ) {
                                                                storebranchstore.initialItem[0].is_default = 1;
                                                            } else {
                                                                storebranchstore.initialItem[0].is_default = 0;
                                                            }
                                                        }
                                                    }}
                                                />
                                                <label htmlFor="locationRadio">
                                                    Địa điểm mặc định
                                                </label>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col span={12}>
                                <div
                                    className="title"
                                    style={{
                                        position: "sticky",
                                        top: "0",
                                        left: "0",
                                    }}
                                >
                                    <p
                                        className="mb-0"
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Nhân viên
                                    </p>
                                    <span>Thêm nhân viên ở đây</span>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div
                                    className="storebranchForm"
                                    style={{
                                        padding: "0",
                                    }}
                                >
                                    <div className="storebranchForm--body">
                                        <div className="item-form item-selected">
                                            <label
                                                className="label-form"
                                                style={{
                                                    paddingBottom: "0.5rem",
                                                }}
                                            >
                                                Nhân viên cửa hàng
                                            </label>

                                            <Form.Item
                                                name="members"
                                                rules={[
                                                    {
                                                        validator: (
                                                            _,
                                                            value
                                                        ) => {
                                                            if (
                                                                !value ||
                                                                (Array.isArray(
                                                                    value
                                                                ) &&
                                                                    value.length ===
                                                                        0)
                                                            ) {
                                                                return Promise.reject(
                                                                    "Vui lòng chọn ít nhất một nhân viên"
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <SelectMultipleAnt
                                                    list={
                                                        storebranchstore.listStaff
                                                    }
                                                    defaultValue={
                                                        isModal
                                                            ? storebranchstore
                                                                  .staticLocation
                                                                  ?.members
                                                            : storebranchstore
                                                                  .listStaff
                                                                  .length === 0
                                                            ? null
                                                            : storebranchstore
                                                                  .initialItem[0]
                                                                  ?.members
                                                    }
                                                    labelFirst="--Chọn nhân viên--"
                                                    onChange={(value: any) => {
                                                        isModal
                                                            ? (storebranchstore.staticLocation.members =
                                                                  value)
                                                            : (storebranchstore.initialItem[0].members =
                                                                  value);
                                                    }}
                                                    placeholder="-- Chọn nhân viên --"
                                                    getLabel={(item: any) =>
                                                        item.name
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="storebranchForm--btn d-flex justify-content-end mt-3">
                                        <p
                                            className="addNewStaff"
                                            onClick={() => {
                                                storebranchstore.showModalAddNewStaff();
                                            }}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                        >
                                            Thêm mới nhân viên
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col span={12}>
                                <div
                                    className="title"
                                    style={{
                                        position: "sticky",
                                        top: "0",
                                        left: "0",
                                    }}
                                >
                                    <p
                                        className="mb-0"
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Hình ảnh
                                    </p>
                                    <span>Tải hình ảnh của cửa hàng</span>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div
                                    className="storebranchForm"
                                    style={{
                                        padding: "0",
                                    }}
                                >
                                    <div className="storebranchForm--body">
                                        <div className="item">
                                            <UploadFile
                                                text="Hình ảnh"
                                                description="Hình ảnh cửa hàng"
                                                file={
                                                    isModal
                                                        ? storebranchstore
                                                              .staticLocation
                                                              .thumbnail
                                                        : storebranchstore
                                                              .initialItem[0]
                                                              ?.thumbnail
                                                }
                                                onIconRemove={
                                                    storebranchstore.handleCloseIcon
                                                }
                                                onFileChange={(e) => {
                                                    handleFile(
                                                        e,
                                                        storebranchstore.changeFileImage
                                                    );
                                                }}
                                                onChooseImg={(url: string)=>{
                                                    // isModal
                                                    // ? storebranchstore
                                                    //       .staticLocation
                                                    //       .thumbnail = url 
                                                    // : storebranchstore
                                                    //       .initialItem[0]
                                                    //       ?.thumbnail = url
                                                    if(isModal){
                                                        storebranchstore.staticLocation.thumbnail = url
                                                    }else{
                                                        storebranchstore.initialItem && (storebranchstore.initialItem[0].thumbnail = url)

                                                    }
                                                }}
                                                code={numberByDateNow()}
                                                onResetState={()=>{  
                                                    storebranchstore.staticLocation.thumbnail = "";
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col
                                span={24}
                                className="d-flex justify-content-end"
                            >
                                <button
                                    className="button-create"
                                    type="submit"
                                    disabled={storebranchstore.isLoadingBtn}
                                >
                                    {storebranchstore.isLoadingBtn
                                        ? "Đang tải..."
                                        : "Cập nhật"}
                                </button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default observer(StoreBranchModal);
