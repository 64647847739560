import {getRequest, postRequest} from "../../../common/helpers/RequestHelper"

class PaymentService{
    // apiLocation = "api/v1/pos/ecommerce/order/save_order"
    apiCheckOut = "api/v1/pos/fnb/checkout"
    apiGenerateQr = "api/v1/payment/pos/generateQr"
    public fetchSaveOrderFnb(params:any): Promise<any>{
        return postRequest(this.apiCheckOut,params);
    }
    public fetchGenerateQr(params:any): Promise<any>{
        return getRequest(this.apiGenerateQr,params);
    }
}

export const paymentService = new PaymentService();