import { getRequest, putRequest } from "../../common/helpers/RequestHelper";

class OrderService {
    apiSubdirectory = "api/v1/orders";
    apiSubdirectoryUpdateOrder = "api/v1/orders/update_status";

    async fetchAllOrder(paramsOrder: any) {
        const queryParams = [
            paramsOrder.startDate ? `startDate=${paramsOrder.startDate}` : "",
            paramsOrder.endDate ? `endDate=${paramsOrder.endDate}` : "",
            paramsOrder.order_by ? `order_by=${paramsOrder.order_by}` : "",
            paramsOrder.order_status
                ? `order_status=${paramsOrder.order_status}`
                : "",
            paramsOrder.order_time
                ? `order_time=${paramsOrder.order_time}`
                : "",
            paramsOrder.order_type
                ? `order_type=${paramsOrder.order_type}`
                : "",
            paramsOrder.source_type ? `source_type=${paramsOrder.source_type}` : "",
            paramsOrder.page ? `page=${paramsOrder.page}` : "",
            paramsOrder.size ? `size=${paramsOrder.size}` : "",
            paramsOrder.payment_method
                ? `payment_method=${paramsOrder.payment_method}`
                : "",
            paramsOrder.payment_status
                ? `payment_status=${paramsOrder.payment_status}`
                : "",
            paramsOrder.search ? `search=${paramsOrder.search}` : "",
            paramsOrder.store_id ? `store_id=${paramsOrder.store_id}` : "",
            paramsOrder.total_type
                ? `total_type=${paramsOrder.total_type}`
                : "",
        ]
            .filter(Boolean)
            .join("&");

        return await getRequest(`${this.apiSubdirectory}?${queryParams}`);
    }

    async fetchGetDetail(id: string) {
        return await getRequest(`${this.apiSubdirectory}/${id}`);
    }

    async fetchPutOrder(params: any) {
        return await putRequest(
            `${this.apiSubdirectoryUpdateOrder}/${params.id}?order_status=${params.order_status}?payment_status=${params.payment_status}`,
            params
        );
    }
}

export const orderService = new OrderService();
