import {makeAutoObservable} from "mobx";
import {paymentService} from "../../paymentPos/PaymentService";
import {HttpStatusCode} from "axios";
import {toastUtils} from "../../../../common/utils/Toastutils";
import {customerDebtService} from "./CustomerDebtService";
import {number} from "yup";
interface StaticParam {
    page: number | 0;
    per_page: number;
    keyword: string | undefined;
}
class CustomerDebtStore{
    isSave: boolean = false
    isLoading: boolean = false
    postId: any
    listCustomerDebt: any[] = []
    metadata: any = {
        total: "",
        page: 0,
        total_page: "",
        size: "",
    };
    staticParam: StaticParam = {
        page: 0,
        per_page: 20,
        keyword: "",
    };
    initializeParams() {
        const query = new URLSearchParams(window.location.search); // Using window.location instead of useLocation()
        this.staticParam.page = query.get("page")
            ? Number(query.get("page")) - 1
            : 0;
        this.staticParam.keyword = query.get("keyword")
            ? String(query.get("keyword"))
            : "";
    }
    constructor() {
        this.initializeParams();
        makeAutoObservable(this)
    }
    async fetchListCustomerDebt(run?:boolean){
        if(this.isSave === false){
            this.isSave = true
            if(this.listCustomerDebt.length < 1 || run){
                this.isLoading = true
            }
            const params = {
                pos_id: this.postId,
                keyword: this.staticParam.keyword,
                page: this.staticParam.page + 1,
                per_page: this.staticParam.per_page
            }
            const result = await customerDebtService.fetchListCustomerDebt(params);
            this.isLoading = false
            this.isSave = false
            if (result.status === HttpStatusCode.Ok) {
                this.listCustomerDebt = result.body.data
                this.metadata = result.body.metadata
                // console.log(customerDebtStore.listCustomerDebt.length)
            } else{
                if(result.body.message[0] == 'the_selected_pos_id_is_invalid'){
                    toastUtils.warning('Máy Pos không tồn tại', '');
                }else{
                    toastUtils.error('Đã có lỗi xảy ra!', '');
                }

            }
        }
    }
}
export const customerDebtStore = new CustomerDebtStore()