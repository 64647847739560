import {observer} from 'mobx-react'
import React from 'react';
import {Button, Modal} from "antd";
import "./Confirm.scss";
import {useNavigate} from "react-router-dom";
import { combineTableStore } from '../../CombineTableStore';

const Confirm = () => {

    return (
        <Modal
            title={
                <div className="header d-flex align-items-center justify-content-between">
                    Xác nhận gộp bàn
                </div>
            }
            open={combineTableStore.isOpenConfirm}
            onCancel={() => (combineTableStore.isOpenConfirm = combineTableStore.isLoadingBtn)}
            footer={[
                <Button className="close-btn" onClick={()=>{combineTableStore.isOpenConfirm = combineTableStore.isLoadingBtn}}>
                    Quay lại
                </Button>,
                <Button type="primary" className="create-btn" disabled={combineTableStore.isLoadingBtn} onClick={async ()=>{
                    await combineTableStore.combineTables();
                }}>
                   {combineTableStore.isLoadingBtn ? "Vui lòng đợi..." : "Gộp Bàn"}  
                </Button>
            ]}
            closeIcon={
                <img
                    src="/assets/icon/icon_closeWhite.svg"
                    width={25}
                    height={25}
                    alt="Close"
                    // onClick={() => confirmStore.isOpenModal = false}
                />
            }
            className="confirm-modal"
            wrapClassName="ConfirmModalCombineTable"
        >
            <div className="line"></div>
            <div className="images-container">
                <img src="/assets/images/notice.svg" alt="Permission"/>
            </div>
            <div className="modal-text">
                Không thể hoàn tác gộp bàn. Bạn vẫn muốn gộp bàn?
            </div>
        </Modal>

    );
}

export default observer(Confirm);