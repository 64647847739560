import {getRequest, postRequest} from "../../../../../../common/helpers/RequestHelper";


class TransferTableService {
    apiListTable = "api/v1/pos/fnb/get_all_dining_table_by_filtering";
    apiSwitchTable = "api/v1/pos/fnb/switch_dining_table"


    public fetchListTable(ParamListTable: any): Promise<any> {
        const queryParams = [
            ParamListTable.current_table_id ? `current_table_id=${ParamListTable.current_table_id}` : "",
            ParamListTable.pos_id ? `pos_id=${ParamListTable.pos_id}` : "",
            ParamListTable.location_id ? `location_id=${ParamListTable.location_id}` : "",
            ParamListTable.status_id ? `status_id=${ParamListTable.status_id}` : "",
        ]
            .filter(Boolean)
            .join("&");
        return getRequest(`${this.apiListTable}?${queryParams}`);
    }

    public switchTable(ParamSwitchTable: any): Promise<any> {
        return postRequest(`${this.apiSwitchTable}`, ParamSwitchTable);
    }

}

export const transferTableService = new TransferTableService();