import { brandService } from "./BrandService";
import { makeAutoObservable } from "mobx";
import { toastUtils } from "../../../common/utils/Toastutils";
import { HttpStatusCode } from "axios";

interface StaticParamBrand {
    offset: number | null;
    order_by: string | null;
    page: number;
    per_page: number;
    search: string;
    status: string;
}

class BrandStore {
    isLoading = false;
    isLoadingModal = false;
    isLoadingBtn = false;
    isModal = false;
    handlerName: string = "";
    waitingText: string = "";
    metadata: any = {
        total: 0,
        page: 0,
        total_page: 0,
        size: 0,
    };
    constructor() {
        this.initializeParams();
        makeAutoObservable(this);
    }
    openModal: boolean = false;
    openDeletingModal = false;
    dropdownRef: any = null;
    isCreating: boolean = false;

    onFilterActive: boolean = false;
    onFilterInactive: boolean = false;

    filteredDataListBrand: any[] = [];

    handleFilterAction = () => {
        this.filteredDataListBrand =
            this.dataListBrand.length > 0
                ? this.dataListBrand
                      .filter((item) =>
                          brandStore.onFilterActive
                              ? item.status == 1
                              : item.status == 0
                      )
                      .map((item) => item)
                : [];
    };

    checkFilterActive() {
        if (this.onFilterActive === false) {
            this.onFilterActive = true;
            if (this.onFilterInactive === true) {
                this.onFilterInactive = false;
            }
            this.staticParamBrand.status = "1";
        } else if (this.onFilterActive === true) {
            this.onFilterActive = false;
            if (this.onFilterInactive === false) {
                this.staticParamBrand.status = "";
            } else {
                this.staticDataBrand.status = "0";
            }
        }
        this.fetchListBrands();
    }

    checkFilterInactive() {
        if (this.onFilterInactive === false) {
            this.onFilterInactive = true;
            if (this.onFilterActive === true) {
                this.onFilterActive = false;
            }
            this.staticParamBrand.status = "0";
        } else if (this.onFilterInactive === true) {
            this.onFilterInactive = false;
            if (this.onFilterActive === false) {
                this.staticParamBrand.status = "";
            } else {
                this.staticDataBrand.status = "1";
            }
        }
        this.fetchListBrands();
    }

    initializeParams() {
        const query = new URLSearchParams(window.location.search); // Using window.location instead of useLocation()
        this.staticParamBrand.page = query.get("page")
            ? Number(query.get("page")) - 1
            : 0;
        this.staticParamBrand.search = query.get("search")
            ? String(query.get("search"))
            : "";
    }

    staticDataBrand: {
        id: number;
        name: string;
        slug: string;
        image: any;
        description: string;
        featured: string;
        status: string;
        deleted_at: string;
        created_at: string;
        updated_at: string;
    } = {
        id: 0,
        name: "",
        slug: "",
        image: "",
        description: "",
        featured: "1",
        status: "1",
        deleted_at: "",
        created_at: "",
        updated_at: "",
    };
    staticParamBrand: StaticParamBrand = {
        offset: 0,
        order_by: "id",
        page: 0,
        per_page: 10,
        search: "",
        status: "",
    };
    initialValues: {
        id: number;
        name: string;
        status: string;
        featured: string;
        description: string;
        image: string;
    } = {
        id: 1,
        name: "",
        status: "",
        featured: "",
        description: "",
        image: "",
    };

    dataListBrand: any[] = [];
    // async fetchListBrandsAll() {
    //     this.isLoading = true;
    //     this.openModal = false;
    //     this.openDeletingModal = false;
    //     const result = await brandService.fetchBrandsAll();
    //     if (result.status === HttpStatusCode.Ok) {
    //         this.dataListBrand = result.body.data;
    //     } else {
    //         toastUtils.error("Đã có lỗi xảy ra!", "");
    //     }
    //     this.isLoading = false;
    //     this.openModal = false;
    //     this.handleClearContent();
    // }
    async fetchListBrands(run?: boolean) {
        this.openModal = false;
        this.openDeletingModal = false;
        let { offset, page, per_page, search, status } = this.staticParamBrand;
        const paramForBrands = {
            offset: offset,
            page: page + 1,
            per_page: per_page,
            search: search,
            status: status,
        };

        if (this.dataListBrand.length < 1 || run) {
            this.isLoading = true;
        }
        const result = await brandService.fetchBrands(paramForBrands);
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            if (
                result.body.data.length !== this.dataListBrand.length ||
                JSON.stringify(result.body.data) !==
                    JSON.stringify(this.dataListBrand)
            ) {
                this.dataListBrand = result.body.data;
                this.metadata = result.body.metadata;
            }
        } else {
            toastUtils.error("Đã có lỗi xảy ra!", "");
        }
        this.handleClearContent();
    }
    // async getSpecificBrand() {
    //     let { id } = this.staticDataBrand;
    //     const specificBrand = {
    //         id: id,
    //     };
    //     this.isLoadingModal = true;
    //     const result = await brandService.fetchSpecificBrand(specificBrand);
    //     if (result.status === HttpStatusCode.Ok) {
    //         this.staticDataBrand.name = result.body.name;
    //         this.staticDataBrand.description = result.body.description;
    //         this.staticDataBrand.image = result.body.image;
    //         this.staticDataBrand.status = result.body.status;
    //     } else {
    //         toastUtils.error(result.body.message, "");
    //     }
    //     this.isLoadingModal = false;
    // }
    async fetchUpdateBrand() {
        this.isLoadingBtn = true;
        let { id, name, description, status, image, featured } =
            this.initialValues;
        const modifiedBrandInfo = {
            id: id,
            name: name,
            description: description,
            status: status,
            image: image,
            featured: featured,
        };
        const result = await brandService.fetchModifyBrand(modifiedBrandInfo);
        this.isLoadingBtn = false;
        if (result.status === HttpStatusCode.Ok) {
            const updateDataLabelProduct = this.dataListBrand.map((item: any) =>
                item.id === this.initialValues.id
                    ? {
                          ...item,
                          name: this.initialValues.name,
                          status: this.initialValues.status,
                          featured: this.initialValues.featured,
                          description: this.initialValues.description,
                          image: this.initialValues.image,
                      }
                    : item
            );
            this.dataListBrand = updateDataLabelProduct;
            toastUtils.success("Sửa thành công!", "");
            this.handleClearContent();
            this.openModal = false;
        } else if (result.status === HttpStatusCode.BadRequest) {
            if(result.body?.message?.description === "the_description_must_not_be_greater_than_500_characters"){
                toastUtils.warning("Mô tả không được lớn hơn 500 ký tự !", "");
                return;
            }
            if(result.body?.message?.brand === "the_brand_name_already_exists"){
                toastUtils.warning("Thương hiệu đã tồn tại !", "");
                return;
            }
        } else {
            toastUtils.error(`Đã có lỗi xảy ra: ${result.body.message}`, "");
        }
    }
    async fetchAddBrand(): Promise<any> {
        this.isLoadingBtn = true;
        let { name, description, image, status, featured } =
            this.staticDataBrand;
        const addedBrandInfo = {
            name: name,
            description: description,
            image: image,
            status: status,
            featured: featured,
        };
        const result = await brandService.fetchCreateNewBrand(addedBrandInfo);
        this.isLoadingBtn = false;
        if (result.status === 200) {
            toastUtils.success("Thêm thành công!", "");
            this.staticParamBrand.page = 0;
            this.dataListBrand.unshift(result.body.data);
            if (this.dataListBrand.length > 10) {
                this.dataListBrand.pop();
            }
            this.handleClearContent();
            this.openModal = false;
        } else if (result.status === HttpStatusCode.BadRequest) {
            if(result.body?.message?.description === "the_description_must_not_be_greater_than_500_characters"){
                toastUtils.warning("Mô tả không được lớn hơn 500 ký tự !", "");
                return;
            }
            if(result.body?.message?.brand === "the_brand_name_already_exists"){
                toastUtils.warning("Thương hiệu đã tồn tại !", "");
                return;
            }
        } else {
            toastUtils.error("Đã có lỗi xảy ra!", "");
        }
    }
    async fetchDeleteBrand() {
        let { id } = this.staticDataBrand;
        const deleteBrandInfo = {
            id: id,
        };
        const result = await brandService.fetchDelBrand(deleteBrandInfo);
        if (result.status === 200) {
            toastUtils.success("Xoá thành công!", "");
            this.dataListBrand = this.dataListBrand.filter(
                (item: any) => item.id !== this.staticDataBrand.id
            );

            if (this.dataListBrand.length === 0) {
                this.staticParamBrand.page = 0;
                this.fetchListBrands();
            }
        } else {
            toastUtils.error("Đã có lỗi xảy ra!", "");
        }
        this.openModal = false;
    }
    handleClearContent() {
        this.staticDataBrand.image = "";
        this.staticDataBrand.name = "";
        this.staticDataBrand.description = "";
        this.staticDataBrand.status = "1";
        this.waitingText = "";
    }
    handleClosePreview = () => {
        this.staticDataBrand.image = "";
    };

    changeFile = (pathFile: string) => {
        this.staticDataBrand.image = pathFile;
    };

    changeFileIcon = (pathFile: string) => {
        this.staticDataBrand.image = pathFile;
        this.initialValues.image = pathFile;
    };
    handleCloseIcon = () => {
        this.staticDataBrand.image = "";
        this.initialValues.image = "";
    };
}

export const brandStore = new BrandStore();
