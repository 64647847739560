import { makeAutoObservable } from "mobx";
import { dashboardService } from "./DashboardService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../common/utils/Toastutils";
import dayjs from "dayjs";
interface DataItem {}

const today = new Date();
const date = today.getDate();

class DashboardStore {
    isOpenModalDate: boolean = false;
    selectedStartDate: string | null = null; // Ngày bắt đầu được chọn
    selectedEndDate: string | null = null; // Ngày kết thúc được chọn
    isLoading: boolean = false;
    isLoadingRevenue: boolean = false;
    listBanner: string[] = [];
    dataListStore: any[] = [];
    textDay: string = "7 ngày trước";
    textStore: string = "Tất cả chi nhánh";
    typeTimeSalesChart: string = "last_7_days";
    typeSalesChart: string = "date";
    typeCustomer: string = "date";
    typeTopSellers: string = "total";
    isChooseDate : boolean = false
    summary: any = {
        total_order: "",
        total_revenue: "",
        pending_count: "",
        cancel_count: "",
        completed_count: "",
        refund_count: "",
    };
    dataCustomer: any[] = [];
    dataRevenue: any[] = [];
    dataTopSellers: any[] = [];
    dataListNotifications: any[] = [];
    metadataNotifications: any = {
        size: "",
        page: "",
        total_page: "",
        total: "",
    };
    staticDataSalesChart: {
        startDate: string;
        endDate: string;
        store: number | undefined;
    } = {
        startDate: "",
        endDate: "",
        store: 0,
    };

    staticDataNotifications: {
        page: string;
        size: string;
        status: string | null;
    } = {
        page: "1",
        size: "20",
        status: null,
    };
    //////

    setSelectedDates(startDate: string, endDate: string) {
        this.selectedStartDate = startDate;
        this.selectedEndDate = endDate;
    }

    initializeDates(typeTimeSalesChart: string, customDates?: { startDate: string, endDate: string }) {
        const today = new Date();
        let endDateString: string, startDate: string;

        if (typeTimeSalesChart === "choice_day" && customDates) {
            startDate = customDates.startDate;
            endDateString = customDates.endDate;
            // startDate = this.selectedStartDate as string ;
            // endDateString = this.selectedEndDate as string;
        } else if (typeTimeSalesChart === "today") {
            endDateString = today.toISOString().split("T")[0];
            startDate = endDateString;
        } else if (typeTimeSalesChart === "yesterday") {
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
            startDate = yesterday.toISOString().split("T")[0];
            endDateString = startDate;
        } else if (typeTimeSalesChart === "last_7_days") {
            endDateString = today.toISOString().split("T")[0];
            const startDateObj = new Date(today);
            startDateObj.setDate(today.getDate() - 6);
            startDate = startDateObj.toISOString().split("T")[0];
        } else if (typeTimeSalesChart === "this_month") {
            const startDateObj = new Date(today.getFullYear(), today.getMonth(), 1);
            startDate = startDateObj.toISOString().split("T")[0];
            endDateString = today.toISOString().split("T")[0];
        } else if (typeTimeSalesChart === "last_month") {
            const startDateObj = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            const endDateObj = new Date(today.getFullYear(), today.getMonth(), 0);
            startDate = startDateObj.toISOString().split("T")[0];
            endDateString = endDateObj.toISOString().split("T")[0];
        } else {
            startDate = "";
            endDateString = "";
        }

        this.staticDataSalesChart.startDate = startDate;
        this.staticDataSalesChart.endDate = endDateString;
    }

    // Gửi request fetchDataRevenue sau khi chọn ngày
    fetchRevenueWithCustomDates() {
        if (this.selectedStartDate && this.selectedEndDate) {
            this.staticDataSalesChart.startDate = this.selectedStartDate;
            this.staticDataSalesChart.endDate = this.selectedEndDate;
            this.textDay = `${dayjs(this.selectedStartDate).format('DD/MM/YYYY')} - ${dayjs(this.selectedEndDate).format('DD/MM/YYYY')}`;
            this.fetchDataRevenue();
            this.fetchDataCustomer();
            this.fetchDataTopSellers();
        }
    }

    async getBanner() {}
    async fetchDataStore() {
        this.isLoading = true;
        try {
            const result = await dashboardService.fetchDataStore();
            dashboardStore.dataListStore = result.body.data ?? [];
        } catch (error) {
            console.error("Error fetching revenue data:", error); // Xử lý lỗi nếu có
        } finally {
            this.isLoading = false;
        }
    }
    async fetchDataRevenue() {
        let { startDate, endDate, store } = this.staticDataSalesChart;
        const paramsRevenue = {
            startDate: startDate,
            endDate: endDate,
            store: store,
            type: this.typeSalesChart,
        };

        this.isLoadingRevenue = true;
        const result = await dashboardService.fetchDataRevenue(paramsRevenue);
        if (result.status === HttpStatusCode.Ok) {
            this.isLoadingRevenue = false;
            this.summary = result.body.summary;
            this.dataRevenue = result.body.data;
        } else {
            toastUtils.error(result.body.message, "");
        }
    }

    async fetchDataCustomer() {
        if (!this.staticDataSalesChart.startDate || !this.staticDataSalesChart.endDate) {
            this.initializeDates(this.typeTimeSalesChart);
        }
        const { startDate, endDate, store } = this.staticDataSalesChart;
        const paramsRevenue = {
            startDate: startDate,
            endDate: endDate,
            store: store,
            type: this.typeCustomer,
        };
        const resultCustomer = await dashboardService.fetchDataCustomer(paramsRevenue);
        if (resultCustomer.status === HttpStatusCode.Ok) {
            this.dataCustomer = resultCustomer.body.data;
        } else {
            toastUtils.error(resultCustomer.body.message, "");
        }
    }

    async fetchDataTopSellers() {
        if (!this.staticDataSalesChart.startDate || !this.staticDataSalesChart.endDate) {
            this.initializeDates(this.typeTimeSalesChart);
        }
        const { startDate, endDate, store } = this.staticDataSalesChart;
        const paramsRevenue = {
            startDate: startDate,
            endDate: endDate,
            store: store,
            type: this.typeTopSellers,
        };
        const resultTopSellers = await dashboardService.fetchDataTopSellers(paramsRevenue);
        if (resultTopSellers.status === HttpStatusCode.Ok) {
            this.dataTopSellers = resultTopSellers.body;
        } else {
            toastUtils.error(resultTopSellers.body.message, "");
        }
    }

    async fetchDataNotifications() {
        if(this.dataListNotifications.length < 1){
            let { page, size, status } = this.staticDataNotifications;
            let paramsNotifications = {
                page: page,
                size: size,
                status: status,
            };
            this.dataListNotifications = [
                ...this.dataListNotifications,
                { isLoading: true, title: "" },
                { isLoading: true, title: "" },
                { isLoading: true, title: "" },
            ];
            const resultNotifications = await dashboardService.fetchDataNotifications(paramsNotifications);
            if (resultNotifications.status === HttpStatusCode.Ok) {
                this.dataListNotifications = [
                    ...this.dataListNotifications,
                    ...resultNotifications.body.data.map((notification: any) =>
                        notification.status === 0
                            ? { ...notification, viewed: false }
                            : { ...notification, viewed: true }
                    ),
                ].filter((item) => !item.isLoading);
                this.metadataNotifications = resultNotifications.body.metadata;
            } else {
                toastUtils.error(resultNotifications.body.message, "");
            }
        }
    }
    async fetchDataNotificationsMore() {
        let { page, size, status } = this.staticDataNotifications;
        let paramsNotifications = {
            page: page,
            size: size,
            status: status,
        };
        this.dataListNotifications = [
            ...this.dataListNotifications,
            { isLoading: true, title: "" },
            { isLoading: true, title: "" },
            { isLoading: true, title: "" },
        ];
        if(this.staticDataNotifications.page === '1'){
        }
        const resultNotifications = await dashboardService.fetchDataNotifications(paramsNotifications);
        if (resultNotifications.status === HttpStatusCode.Ok) {
            this.dataListNotifications = [
                ...this.dataListNotifications,
                ...resultNotifications.body.data.map((notification: any) =>
                    notification.status === 0
                        ? { ...notification, viewed: false }
                        : { ...notification, viewed: true }
                ),
            ].filter((item) => !item.isLoading);
            this.metadataNotifications = resultNotifications.body.metadata;
        } else {
            toastUtils.error(resultNotifications.body.message, "");
        }
    }
    async fetchUpdateStatusNotification(id: number | undefined) {
        const resultNotifications =
            await dashboardService.fetchUpdateStatusNotification(id);
        if (resultNotifications.status === HttpStatusCode.Ok) {
        } else {
            toastUtils.error(resultNotifications.body.message, "");
        }
    }
    async fetchUpdateStatusAllNotification() {
        const resultNotifications =
            await dashboardService.fetchUpdateStatusAllNotification();
        if (resultNotifications.status === HttpStatusCode.Ok) {
        } else {
            toastUtils.error(resultNotifications.body.message, "");
        }
    }
    constructor() {
        makeAutoObservable(this);
    }
}
export const dashboardStore = new DashboardStore();
