import React, { useEffect } from "react";
import "./styles/PaymentUpdateProduct.scss";
import HeaderProductInPos from "../productInPos/components/headerProductInPos/HeaderProductInPos";
import { useNavigate, useParams } from "react-router-dom";
import { productInPostStore } from "../productInPos/ProductInPostStore";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { utils } from "../../../common/utils/Utils";
import { Skeleton } from "antd";
import { productInPosCartStore } from "../productInPos/ProductInPosCartStore";
import StorageService from "../../../common/service/StorageService";
const PaymentUpdateProductPage = () => {
  const navigate = useNavigate();
  const { diningTableId } = useParams();
  const { posId } = useParams();
  productInPostStore.idCurrentTable = diningTableId;
  useEffect(() => {
    productInPostStore.getDataInfoTable(()=>{

        const localStorageKey = `cartPos${posId}-${diningTableId}`;
        let currentCart = StorageService.getObjectStore(localStorageKey);

        const cartList = productInPostStore.dataInfoTable?.cart_list || [];
        const newCartItem = cartList.map((item: any) => ({
            dining_table_session: item.dining_table_session,
            discount: item.discount,
            note: item.note || "",
            price: item.price,
            productName: item.product_name,
            product_id: item.product_id,
            quantity: item.qty,
            row_id: String(item.rowId),
            sku: item.product_sku,
            tmp_order_status: String(item.tmp_order_status),
            unit: item?.options?.unit,
            variant: {
                id: item?.options?.variant?.id,
                name: item?.options?.variant?.attribute_compare || "",
            },
            idData: item?.id,
            edited: false
        }));

        if(currentCart){
            currentCart.items = newCartItem
        }

        StorageService.setObjectStore(localStorageKey, currentCart);
    });
  }, [diningTableId]);


  const total =
    productInPostStore.dataInfoTable?.cart_list?.length > 0 &&
    productInPosCartStore.CaculatorTotalNew(
      productInPostStore.dataInfoTable?.cart_list.filter((item: any)=> item.tmp_order_status === 1)
    )

  return (
    <div className="PaymentUpdateProductPage">
      <HeaderProductInPos
        search={false}
        backPage={
          <button
            className="backToPage"
            onClick={
              () => navigate(-1)
              // navigate(/pos/${posId}/tang-phong/${floorId}/${roomId})
            }
          >
            <img src="/assets/icon/prev.svg" alt="back" />
            Quay lại
          </button>
        }
      />

      <div className="PaymentUpdateProductPage-body">
        <h1 className="title-page">Danh sách món đã order</h1>
        <div className="box-body">
          <div className="left-content">
            {productInPostStore.isLoadingDetailTable
              ? Array.from({ length: 3 }).map(
                  (
                    _,
                    index // Render 5 items skeleton
                  ) => (
                    <div className="item skeleton-item" key={index}>
                      <div className="item-details">
                        <div className="item-name">
                          <Skeleton.Input
                            style={{
                              width: "60%",
                              height: "20px",
                              marginBottom: "8px",
                            }}
                            active
                          />
                        </div>
                        <div className="item-desc">
                          <Skeleton.Input
                            style={{
                              width: "30%",
                              height: "20px",
                              marginBottom: "8px",
                            }}
                            active
                          />
                        </div>
                      </div>
                      <div className="item-price">
                        <Skeleton.Input style={{ width: "20%" }} active />
                      </div>
                    </div>
                  )
                )
              : productInPostStore.dataInfoTable?.cart_list?.length > 0 &&
                productInPostStore.dataInfoTable?.cart_list?.filter((item: any)=> item.tmp_order_status === 1).map(
                  (item: any) => (
                    <div
                      className={`item`}
                      key={item.id}
                      // onClick={() => separationStore.toggleSelectItem(index)}
                    >
                      <div className="item-details">
                        <div className="item-name">
                          {item.product_name}{" "}
                          {item.options && item.options.attribute_compare
                            ? ` - ${item.options.attribute_compare}`
                            : ""}
                        </div>
                        <div className="item-desc">
                          {item.qty} {item.options.unit} x{" "}
                          {item.price
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          đ{item.options.unit ? ` /${item.options.unit}` : ""}
                        </div>
                        {item.discount > 0 ? (
                          <p style={{ fontSize: "14px" }}>
                            Chiết khấu {item.discount}% với một sản phẩm
                          </p>
                        ) : (
                          ""
                        )}

                        {/* Thêm đoạn mã xử lý note */}
                        {item.note && ( // Kiểm tra nếu item.note không null, undefined hoặc rỗng
                          <p
                            style={{ display: "flex", gap: "5px" }}
                            className="boxProduct-note"
                          >
                            <img
                              src="/assets/icon/noteProduct.svg"
                              alt="icon_note"
                            />
                            {item.note}
                          </p>
                        )}
                      </div>
                      <div className="item-price">
                        {(
                          item.qty *
                          item.price *
                          (1 - (item.discount || 0) / 100)
                        )
                          ?.toFixed(0) // Làm tròn
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " đ"}
                      </div>
                    </div>
                  )
                )}
          </div>
          <div className="right-content">
            <div className="box-total">
              {productInPostStore.isLoadingDetailTable ? (
                <Skeleton.Input className="total" active />
              ) : (
                <h1 className="total">{utils.formatCurrency(total)}</h1>
              )}
            </div>
            <div
              className="box-pay"
              onClick={() => {
                productInPosCartStore.toCheckoutPayment(() =>
                  navigate(
                    `/pos/${posId}/thanh-toan/${diningTableId}/${productInPosCartStore.dataListProInCart?.dining_table_session}`
                  )
                );
              }}
            >
              <button className="btn-pay">
                {productInPosCartStore.isLoadingBtn
                  ? "Vui Lòng Đợi..."
                  : "Thanh Toán"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(PaymentUpdateProductPage);
