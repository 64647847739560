import {HttpStatusCode} from "axios";
import {makeAutoObservable, toJS} from "mobx";
import {customerService} from "./CustomerService";
import {toastUtils} from "../../../common/utils/Toastutils";

export type TFormCustomer = {
    id?: string;
    name: string;
    phone: string;
    email?: string;
    province_id?: string | null;
    district_id?: string | null;
    ward_id?: string | null;
    address?: string;
    location_id: string;
};

type TypeModal = "view" | "add" | "edit";

class CustomerStore {
    listCustomer: any[] = [];
    listProvince: any[] = [];
    listDistrict: any[] = [];
    listWard: any[] = [];
    isLoading: boolean = false;
    loadingDataTable: boolean = true;
    searchTemp: string = "";
    isSave: boolean = false;
    allowSearch:boolean = false;
    isCreating: boolean = false;
    modalName: string = '';
    modalOpen: boolean = false;
    isHoldModal: boolean = false;
    listLocation: any[] = [];
    idCustomer: string | null = "";
    typeModal: TypeModal = "add";


    dataFormCustomer: TFormCustomer = {
        id: "",
        name: "",
        phone: "",
        email: "",
        province_id: "",
        district_id: "",
        ward_id: "",
        address: "",
        location_id: "",
    };

    clearFormCustomer() {
        this.dataFormCustomer = {
            name: "",
            phone: "",
            email: "",
            province_id: "",
            district_id: "",
            ward_id: "",
            address: "",
            location_id: "",
        };
    }


    staticParamCustomer: {
        location_id: string,
        page_number: number,
        per_page: number,
        phone: string,
    } = {
        location_id: "",
        page_number: 0,
        per_page: 20,
        phone: "",
    };

    metadata: { total: number, page: number, total_page: number, size: number } = {
        total: 0,
        page: 0,
        total_page: 0,
        size: 0
    }

    staticDataCustomer: {
        id: string,
        name: string,
        email: string,
        password: string,
        password_confirm: string,
        status: string,
    } = {
        id: "",
        name: "",
        email: "",
        password: "",
        password_confirm: "",
        status: "",
    }

    constructor() {
        makeAutoObservable(this);
        this.initStaticParamCustomer();
    }
    initStaticParamCustomer(){
        const queryParams = new URLSearchParams(window.location.search);
        this.staticParamCustomer.phone = queryParams.get("phone")
            ? String(queryParams.get("phone"))
            : "";
    }
    async getCustomerWithRequirement(run?: boolean) {
        if(this.listCustomer.length < 1 || run){
            this.loadingDataTable = true;
            this.isLoading = true;
        }
        let {
            location_id,
            page_number,
            per_page,
            phone,
        } = this.staticParamCustomer;
        const params = {
            location_id: location_id,
            page_number: page_number + 1,
            per_page: per_page,
            phone: phone,
        }
        const result = await customerService.fetchCustomerGettingAll(params);
        if (result.status === HttpStatusCode.Ok) {
            this.isLoading = false;
            this.loadingDataTable = false;
            this.listCustomer = result.body.data;
            this.metadata = result.body.metadata;
        } else {
            toastUtils.error('Đã có lỗi xảy ra!', '');
        }
    }
    async addCustomer(form: any): Promise<any> {
        this.isLoading = true;
        if (this.isSave === false) {
            this.isSave = true;
            let {
                name,
                phone,
                email,
                province_id,
                district_id,
                ward_id,
                address,
                location_id,
            } = this.dataFormCustomer;

            const data = {
                name: name,
                phone: phone,
                email: email,
                province_id: province_id,
                district_id: district_id,
                ward_id: ward_id,
                address: address,
                location_id: location_id,
            };
            const result = await customerService.fetchCustomerAdding(data);
            if (result.status === HttpStatusCode.Ok) {
                this.isLoading = false;
                this.isSave = false;
                toastUtils.success('Thêm khách hàng thành công!', '');
                form.resetFields();
                this.clearFormCustomer();
                this.listCustomer.unshift(result.body);
                if(this.listCustomer.length >= this.staticParamCustomer.per_page) {
                    this.listCustomer.pop()
                }
                this.modalOpen = false;
            } else {
                toastUtils.error('Đã có lỗi xảy ra!', '');
                this.isLoading = false;
            }
        }
    }

    async editCustomer() {
        const {
            name,
            phone,
            email,
            province_id,
            district_id,
            ward_id,
            address,
            location_id,
        } = this.dataFormCustomer;

        const data = {
            name: name,
            phone: phone,
            email: email,
            province_id: province_id,
            district_id: district_id,
            ward_id: ward_id,
            address: address,
            location_id: location_id,
        };
        this.isLoading = true;
        this.isHoldModal = true;
        const result = await customerService.fetchCustomerEditing(
            this.idCustomer as string,
            data
        );
        this.isLoading = false;
        this.isHoldModal = false;
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Cập nhật thành công", "");
            
            const updatedArrCustomer = this.listCustomer.map((item) =>
                item.id === this.dataFormCustomer.id
            ? {
                ...item,
                ...this.dataFormCustomer,
                city_id: this.dataFormCustomer.province_id,
                location_id: result.body.location_store_id,
                location_store_id: result.body.location_store_id
            }
            : item
        );

            this.listCustomer = updatedArrCustomer;
            this.dataFormCustomer = {} as any;
            this.clearFormCustomer();
            this.modalOpen = false;
        } else {
            if (
                result.body?.message[0] === "the_email_must_be_a_valid_email_address"
            ) {
                toastUtils.error("Email không hợp lệ", "2");
                return;
            }
            if (result.body?.message?.customer) {
                toastUtils.error("Số điện thoại đã tồn tại", "2");
                return;
            }
            toastUtils.error(result.body.message, "2");
        }
    }

    async deleteCustomer() {
        let {id} = this.staticDataCustomer;
        const result = await customerService.fetchCustomerDeleting(this.staticDataCustomer.id);
        if(result.status === HttpStatusCode.Ok){
            toastUtils.success('Xóa thành công!', '');
            this.listCustomer = this.listCustomer.filter((itemData:any) => itemData.id != id)
            if(this.listCustomer.length == 0) {
                this.getCustomerWithRequirement();
            }
        }
        else{
            toastUtils.error('Đã có lỗi xảy ra!', '');
        }
    }

    async getProvinces() {
        const result = await customerService.fetchProvices();
        if (result.status === HttpStatusCode.Ok) {
            this.listProvince = result.body;
        }
    }

    async getDistrict(province_id: string) {
        if (province_id) {
            const result = await customerService.fetchDistricts(province_id);
            if (result.status === HttpStatusCode.Ok) {
                this.listDistrict = result.body;
            }
        }
    }

    async getWard(district_id: string) {
        if (district_id) {
            const result = await customerService.fetchWards(district_id);
            if (result.status === HttpStatusCode.Ok) {
                this.listWard = result.body;
            }
        }
    }

    async getLocations() {
        const result = await customerService.fetchLocationGettingAll();
        if (result.status === HttpStatusCode.Ok) {
            this.listLocation = result.body.data;
        }
    }
}

export const customerStore = new CustomerStore();