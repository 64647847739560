import { Button, Form, Modal } from "antd";
import "./ModalNoteCartItem.scss";
import { productInPosCartStore } from "../../../ProductInPosCartStore";
import { observer } from "mobx-react";
import TextArea from "antd/es/input/TextArea";
import { useEffect } from "react";

const ModalNoteCartItems = () => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(productInPosCartStore.dataNote);
    }, [productInPosCartStore.dataNote.note, form]);

    const onFinish = () => {
        productInPosCartStore.noteItemCart(() => {
            form.resetFields();
        });
    };

    return (
        <>
            <Modal
                title={
                    <div className="header d-flex align-items-center justify-content-between">
                        Ghi chú món
                    </div>
                }
                open={productInPosCartStore.isOpenModalNoteCartItem}
                onCancel={() => 
                    productInPosCartStore.isOpenModalNoteCartItem = false
                }
                footer={null}
                closeIcon={
                    <img
                        src="/assets/icon/icon_closeWhite.svg"
                        width={25}
                        height={25}
                        alt="Close"
                    />
                }
                className="note-table-modal"
                wrapClassName="TransferTable"
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={productInPosCartStore.dataNote}
                >
                    <Form.Item
                        name="note"
                        rules={[
                            {
                                max: 150,
                                message: "Note quá dài, tối đa 150 kí tự",
                            },
                            {
                                validator: (_, value) => {
                                    if (value && !value.trim()) {
                                        return Promise.reject(
                                            new Error(
                                                "Note không được để trống"
                                            )
                                        );
                                    }
                                    const specialCharPattern = /[#!@$%^&*]/;
                                    if (specialCharPattern.test(value)) {
                                        return Promise.reject(
                                            new Error(
                                                "Note không được chứa ký tự đặc biệt như: #!@$%^&*"
                                            )
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <TextArea
                            className="text-area"
                            rows={4}
                            placeholder="Vui lòng nhập"
                            maxLength={150}
                            style={{
                                border: "1px solid #eee",
                            }}
                            onChange={(e: any) =>
                                (productInPosCartStore.dataNote.note =
                                    e.target.value)
                            }
                        />
                    </Form.Item>

                    <div className="btnCreateModal">
                        <button className="cancelModal" type="reset"
                            onClick={() => productInPosCartStore.isOpenModalNoteCartItem = false}
                        >
                            Đóng
                        </button>
                        <button
                            className="submitForm"
                            type="submit"
                            disabled={productInPosCartStore.isLoadingBtn}
                        >
                            {productInPosCartStore.isLoadingBtn
                                ? "Vui lòng đợi..."
                                : "Lưu"}
                        </button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default observer(ModalNoteCartItems);
