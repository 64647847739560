import React, { useEffect, useState } from "react";
import "../styles/Customer.scss"
import { Form, Input, Modal } from "antd";
import { observer } from "mobx-react";
import StorageService from "../../../../common/service/StorageService";
import SelectCustom from "../../../../shared/components/form/SelectCustom";
import {customerStore, TFormCustomer} from "../CustomerStore";
import { toJS } from "mobx";


const CustomerModal = () => {
    const [form] = Form.useForm();
    const [dataProvince, setDataProvince] = useState<any>([]);
    const dataProviceLC = StorageService.getArrayFromLS("dataProvince")
    useEffect(() => {
        if (dataProviceLC.length <= 0) {
            customerStore.getProvinces();
        } else {
            setDataProvince(dataProviceLC.map((item: any) => ({ id: item.id, name: item.name })));
        }
    }, [dataProviceLC.length]);

    useEffect(() => {
        if (customerStore.listProvince.length > 0) {
            StorageService.saveArayLS("dataProvince", customerStore.listProvince);
            setDataProvince(customerStore.listProvince.map((item: any) => ({ id: item.id, name: item.name })));
        }
    }, [customerStore.listProvince]);


    const dataDistricts = Object.values(customerStore.listDistrict).length > 0 ? Object.values(customerStore.listDistrict).map(district => ({
        id: district.id,
        name: district.name
    })): [];

    const dataWards = Object.values(customerStore.listWard).length > 0 ? Object.values(customerStore.listWard).map(ward => ({
        id: ward.id,
        name: ward.name
    })): [];

    useEffect(()=>{
        if(customerStore.typeModal === "edit" || customerStore.typeModal === "view"){
            customerStore.getDistrict(customerStore.dataFormCustomer.province_id as string) // chưa có id city
            customerStore.getWard(customerStore.dataFormCustomer.district_id as string)
        }
    },[customerStore.dataFormCustomer.district_id,customerStore.dataFormCustomer.province_id])

    useEffect(()=>{
        form.setFieldsValue({
            name: customerStore.dataFormCustomer.name,
            phone: customerStore.dataFormCustomer.phone,
            email: customerStore.dataFormCustomer.email,
            province_id: customerStore.dataFormCustomer.province_id,
            district_id: customerStore.dataFormCustomer.district_id,
            ward_id: customerStore.dataFormCustomer.ward_id,
            address: customerStore.dataFormCustomer.address,
            location_store_id: customerStore.dataFormCustomer.location_id,
            location_id: customerStore.dataFormCustomer.location_id,
        })
    },[form,customerStore.dataFormCustomer])

    useEffect(() => {
        customerStore.getLocations()
    }, []);

    return (
        <Modal
            title={`${
                customerStore.typeModal === "add"
                    ? "Thêm khách hàng"
                    : "Chi tiết khách hàng"
            }`}
            open={customerStore.modalOpen}
            onCancel={() => {

                if((customerStore.typeModal === "edit" && customerStore.isHoldModal) || customerStore.isSave){
                    return;
                }
                if (customerStore.typeModal === "edit" || customerStore.typeModal === "view") {
                    customerStore.dataFormCustomer = {} as any;
                    customerStore.listDistrict = [];
                    customerStore.listWard = [];
                    customerStore.listWard = [];
                }
                customerStore.typeModal = "add";
                // để giữ modal khi chưa add/update chưa thành công
                if (customerStore.isHoldModal) {
                    customerStore.modalOpen = true;
                } else {
                    customerStore.modalOpen = false;
                }
            }}
            footer={[
                customerStore.typeModal !== "view" ?
                (
                <button
                    key="ok"
                    className={`custom-ok-button ${
                        customerStore.isLoading ? "disabled-modal" : ""
                    }`}
                    type="submit"
                    onClick={() => {
                        form.submit();
                    }}
                >
                    {customerStore.typeModal === "add" as string &&
                    !customerStore.isLoading
                        ? "Thêm khách hàng"
                        : customerStore.typeModal === "edit" as string &&
                        !customerStore.isLoading
                            ? "Lưu"
                            : "Vui Lòng Chờ...."}
                </button> ): "",
            ]}
            wrapClassName={`CustomerModal`}
        >
            <Form
                form={form}
                onFinish={(values: any) => {
                    customerStore.dataFormCustomer.name = values.name;
                    customerStore.dataFormCustomer.address = values.address;
                    customerStore.dataFormCustomer.email = values.email;
                    customerStore.dataFormCustomer.phone = values.phone;
                    customerStore.dataFormCustomer.location_id = values.location_id
                    customerStore.typeModal === "add"
                        ? customerStore.addCustomer(form)
                        : customerStore.editCustomer();
                }}
                className={`formCustomerModal ${customerStore.typeModal === "view" ? "disabled-modal" : ""}`}
            >
                <Form.Item<TFormCustomer>
                    className="item-form"
                    label="Họ và tên"
                    name="name"
                    rules={[
                        {required: true, message: "Vui lòng nhập vào trường này"},
                        {
                            max: 150,
                            message:
                                "Tên khách hàng quá dài, tối đa 150 kí tự",
                        },
                        {
                            validator: (_, value) => {
                                if (value && !value.trim()) {
                                    return Promise.reject(
                                        new Error(
                                            "Tên khách hàng không được chỉ có khoảng trắng"
                                        )
                                    );
                                }
                                const specialCharPattern = /[#!@$%^&*]/;
                                if (specialCharPattern.test(value)) {
                                    return Promise.reject(
                                        new Error(
                                            "Tên khách hàng không được chứa ký tự đặc biệt như: #!@$%^&*"
                                        )
                                    );
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input placeholder="Vui lòng nhập"/>
                </Form.Item>
                <div className="divider-col2">
                    <Form.Item<TFormCustomer>
                        className="item-form"
                        label="Số điện thoại"
                        name="phone"
                        rules={[
                            {
                                pattern: /^[0-9]+$/,
                                message: 'Vui lòng chỉ nhập số!',
                            },
                            {
                                required: true,
                                message: 'Vui lòng nhập số!',
                            },
                            {
                                min: 10,
                                message: 'Số điện thoại phải có ít nhất 10 số!',
                            },
                            {required: true, message: "Vui lòng nhập vào trường này"},
                        ]}
                    >
                        <Input placeholder="Vui lòng nhập"/>
                    </Form.Item>
                    <Form.Item<TFormCustomer>
                        className="item-form"
                        label="Email"
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'Định dạng email không hợp lệ!',
                            },
                        ]}
                    >
                        <Input placeholder="Vui lòng nhập"/>
                    </Form.Item>
                </div>

                <div className="divider-col2">
                    <Form.Item<TFormCustomer>
                        className="item-form"
                        label="Tỉnh/Thành phố"
                        name="province_id"
                    >
                        <SelectCustom
                            list={dataProvince && dataProvince}
                            placeholder="Vui lòng chọn"
                            showSearch
                            onChange={(value: any) => {
                                customerStore.dataFormCustomer.province_id = value;
                                customerStore.getDistrict(
                                    customerStore.dataFormCustomer.province_id as string
                                );
                                customerStore.dataFormCustomer.district_id = null;
                                customerStore.dataFormCustomer.ward_id = null;
                                customerStore.listDistrict = []
                                customerStore.listWard = []
                            }}
                            selected={() => {
                                // return customerStore.dataFormCustomer.province_id || null;
                                return dataProvince.find((item: any) => item.id === customerStore.dataFormCustomer.province_id)?.id;
                            }}
                            getLabel={(item: any) => item.name}
                        ></SelectCustom>
                    </Form.Item>

                    <Form.Item<TFormCustomer>
                        className="item-form"
                        label="Quận/Huyện"
                        name="district_id"
                    >
                        <SelectCustom
                            list={dataDistricts}
                            placeholder="Vui lòng chọn"
                            labelFirst="Vui lòng chọn"
                            showSearch
                            onChange={(value: any) => {
                                customerStore.dataFormCustomer.district_id = value;
                                customerStore.getWard(
                                    customerStore.dataFormCustomer.district_id as string
                                );
                                customerStore.dataFormCustomer.ward_id = null;
                                customerStore.listWard = []
                            }}
                            selected={() => {
                                return dataDistricts.find((item: any) => item.id === customerStore.dataFormCustomer.district_id)?.id;

                            }}
                            getLabel={(item: any) => item.name}
                        ></SelectCustom>
                    </Form.Item>
                </div>
                <div className="divider-col2">
                    <Form.Item<TFormCustomer>
                        className="item-form"
                        label="Phường/Xã"
                        name="ward_id"
                    >
                        <SelectCustom
                            list={dataWards}
                            placeholder="Vui lòng chọn"
                            showSearch
                            onChange={(value: any) => {
                                customerStore.dataFormCustomer.ward_id = value;
                            }}
                            selected={() => {
                                return dataWards.find((item: any) => item.id === customerStore.dataFormCustomer.ward_id)?.id
                            }}
                            getLabel={(item: any) => item.name}
                        ></SelectCustom>
                    </Form.Item>
                    <Form.Item<TFormCustomer>
                        className="item-form"
                        label="Địa chỉ cụ thể"
                        name="address"
                    >
                        <Input placeholder="Vui lòng nhập"/>
                    </Form.Item>
                </div>
                <div className="divider-col2">
                        <Form.Item<TFormCustomer>
                            className="item-form"
                            label="Địa điểm"
                            name="location_id"
                            rules={[{required: true, message: "Vui lòng chon địa điểm"}]}
                        >
                            <SelectCustom
                                list={customerStore.listLocation.map((item) => ({
                                    id: item.id,
                                    name: item.name,
                                }))}
                                placeholder="Vui lòng chọn"
                                showSearch
                                onChange={(value: any) => {
                                    customerStore.dataFormCustomer.location_id = value;
                                }}
                                selected={() => {
                                    return customerStore.listLocation.find(
                                        (item) => item.id === customerStore.dataFormCustomer.location_id
                                    )?.id;
                                }}
                                getLabel={(item: any) => item.name}
                            ></SelectCustom>
                        </Form.Item>
                </div>
            </Form>
        </Modal>
    );
};

export default observer(CustomerModal);

