import {HttpStatusCode} from "axios";
import {salePointService} from "./SalePointService";
import {toastUtils} from "../../common/utils/Toastutils";
import {makeAutoObservable, toJS} from "mobx";


class SalePointStore {
    listPos: any[] = [];
    listLocation: any[] = [];
    loadingDataAll: boolean = true;
    isModalMoneyControl: boolean = false;
    isModalCurrencyType: boolean = false;
    isModalCash: boolean = false;
    isLoading = false;
    listMoney: { bank_amount: number; cash_amount: number; total: number } | null = null;
    posId: string = "";
    isOpen: boolean = false;
    isLoadingBtn: boolean = false;
    isFirst : boolean = false // đánh dấu lần đầu mở modal đóng máy tính tiền để format tiền

    constructor() {
        makeAutoObservable(this);
    }

    setPosId(posId: string) {
        this.posId = posId;
    }


    //Cash
    setIsModalCash = (isOpen: boolean) => {
        this.isModalCash = isOpen;
    }
    showModalCash = () => {
        this.setIsModalCash(true);
    }

    handleCashClose = () => {
        this.setIsModalCash(this.isLoadingBtn);
    }

    // Money Control
    setIsModalOpen = (isOpen: boolean) => {
        this.isModalMoneyControl = isOpen;
    };
    showModal = () => {
        this.setIsModalOpen(true);
    };
    handleClose = () => {
        this.setIsModalOpen(this.isLoadingBtn);
    };

    // Currency Type
    setIsModalCurrencyOpen = (isOpen: boolean) => {
        this.isModalCurrencyType = isOpen;
    };
    showModalCurrency = () => {
        this.setIsModalCurrencyOpen(true);
    };
    handleCurrencyOK = () => {
        this.setIsModalCurrencyOpen(false);

    };
    handleCurrencyClose = () => {
        this.setIsModalCurrencyOpen(false);
    };

    staticDataCloseMoney: {
        price_pos_cash: string,
        price_pos_bank: string,
        note_pos_close: ({ name: string; quantity: string } | string)[];
    } = {
        price_pos_cash: '',
        price_pos_bank: '',
        note_pos_close: [],
    }

    staticDataMoney: {
        price_pos: string;
        meta_note_start: { name: string; quantity: string }[];
        note_start: string;
    } = {
        price_pos: '',
        meta_note_start: [],
        note_start: '',
    }

    staticDataPos:{
        id: string,
        name: string,
        store_id: string,
        location_id: number,
        meta_start_amount: string
    } = {
        id: '',
        name: '',
        store_id: '',
        location_id: 1,
        meta_start_amount: ''
    }

    metadata: { total: number, page: number, total_page: number, size: number } = {
        total: 0,
        page: 0,
        total_page: 0,
        size: 0
    }

    async getLocation(run?:boolean){
        if (this.listLocation.length < 1 || run) {
            const result = await salePointService.fetchLocation();
            if (result.status === HttpStatusCode.Ok) {
                this.listLocation = result.body.data;
            } else {
                toastUtils.error('Đã có lỗi xảy ra!', '');
            }
        }
    }

    async getMachinePosByUser(run?: boolean) {
        if (this.listPos.length < 1 || run) {
            this.isLoading = true;
        }

        const { store_id } = this.staticDataPos;
        // const store_id = 1;
        const result = await salePointService.fetchMachinePosByUser(store_id); // Truyền store_id vào request

        if (result.status === HttpStatusCode.Ok) {
            this.listPos = result.body.data;
            this.metadata = result.body.metadata;
            this.loadingDataAll = false;

        } else {
            // toastUtils.error('Đã có lỗi xảy ra!', '');
        }
        this.isLoading = false;
    }

    async getTotalMoneyPos() {
        const id  = this.posId;
        const result = await salePointService.fetchTotalMoneyPos(id);

        if (result.status === HttpStatusCode.Ok) {
            this.listMoney = result.body;
        } else {
            toastUtils.error('Đã có lỗi xảy ra!', '');
        }
    }


    async addCash(navigate: (route: string) => void) {
            this.isLoadingBtn = true;
            let {price_pos, meta_note_start, note_start} = this.staticDataMoney;
            const addCash = {
                pos_id: this.staticDataPos.id,
                price_pos: price_pos,
                meta_note_start: meta_note_start,
                note_start: note_start
            }
            const result = await salePointService.fetchCashAdding(addCash);
            this.isLoadingBtn = false;
            if (result.status === HttpStatusCode.Ok) {
                const updateDataTagCash = this.listPos.map(
                    (item: any) =>
                        item.id === result.body.id
                            ? {
                                ...item,
                                is_working: result.body.is_working,
                                meta_end_amount: result.body.meta_end_amount,
                                meta_start_amount: result.body.meta_start_amount,
                                pos_session_id: result.body.pos_session_id,
                                start_working: result.body.start_working,
                                user: result.body.user
                            }
                            : item
                );
                this.listPos = updateDataTagCash;
                navigate(`/pos/${this.staticDataPos.id}/tang-phong`);
                toastUtils.success('Mở phiên thành công !', '');
                this.handleClose();
                this.staticDataMoney = {
                    price_pos: '',
                    meta_note_start: [],
                    note_start: ''
                };
            } else {
                toastUtils.error('Đã có lỗi xảy ra!', '');
            }
    }


    async closeCash(navigate: (route: string) => void, callback?: any) {
            this.isLoadingBtn = true;
            let {price_pos_cash, price_pos_bank, note_pos_close} = this.staticDataCloseMoney;
            const closeCash = {
                pos_id: this.posId,
                price_pos_cash: price_pos_cash,
                price_pos_bank: price_pos_bank,
                note_pos_close: note_pos_close
            }
            const result = await salePointService.fetchCashClosing(closeCash);
            this.isLoadingBtn = false;
            if (result.status === HttpStatusCode.Ok) {
                toastUtils.success('Đóng máy tính tiền thành công!', '');
                navigate("/danh-sach-pos")
                this.handleCashClose();
                this.getMachinePosByUser();
                callback && callback()
                this.staticDataCloseMoney = {
                    price_pos_cash: '',
                    price_pos_bank: '',
                    note_pos_close: [],
                };
            } else {
                toastUtils.error('Đã có lỗi xảy ra!', '');
            }
    }

}

export const salePointStore = new SalePointStore();